/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import axios from "axios";
import { json } from "react-router-dom";


//CONSTANTES

const dataInicialCFlGP = {
    arrayCFlGP : [],
    loadingCFlGP : '',
};

const dataInicialC = {
    arrayC : [],
    loadingC : '',
};
const dataInicialCProd = {
    arrayProd : [],
    loadingProd : true,
};

const dataInicialF = {
    arrayF : [],
    loadingF : '',
    categoria: '',
    familia: '',
};
const dataInicialFProd = {
    arrayFProd : [],
    loadingFProd : true,
};

const dataInicialL = {
    arrayL : [],
    loadingL : '',
};
const dataInicialLProd = {
    arrayLProd : [],
    loadingLProd : true,
};

const dataInicialGP = {
    arrayGP : [],
    loadingGP : '',
};



//TYPES

const OBTENER_CFlGP_EXITO = 'OBTENER_CFlGP_EXITO'
const OBTENER_CFlGP_REQUEST = 'OBTENER_CFlGP_REQUEST'

const OBTENER_CATEGORIAS_EXITO = 'OBTENER_CATEGORIAS_EXITO'
const OBTENER_CATEGORIAS_REQUEST = 'OBTENER_CATEGORIAS_REQUEST'
const OBTENER_CATEGORIASPRODUCTOS_EXITO = 'OBTENER_CATEGORIASPRODUCTOS_EXITO'
const OBTENER_CATEGORIASPRODUCTOS_REQUEST = 'OBTENER_CATEGORIASPRODUCTOS_REQUEST'

const OBTENER_FAMILIAS_EXITO = 'OBTENER_FAMILIAS_EXITO'
const OBTENER_FAMILIAS_REQUEST = 'OBTENER_FAMILIAS_REQUEST'
const OBTENER_FAMILIASPRODUCTOS_EXITO = 'OBTENER_FAMILIASPRODUCTOS_EXITO'
const OBTENER_FAMILIASPRODUCTOS_REQUEST = 'OBTENER_FAMILIASPRODUCTOS_REQUEST'

const OBTENER_LINEAS_EXITO = 'OBTENER_LINEAS_EXITO'
const OBTENER_LINEAS_REQUEST = 'OBTENER_LINEAS_REQUEST'
const OBTENER_LINEASPRODUCTOS_EXITO = 'OBTENER_LINEASPRODUCTOS_EXITO'
const OBTENER_LINEASPRODUCTOS_REQUEST = 'OBTENER_LINEASPRODUCTOS_REQUEST'

const OBTENER_GP_EXITO = 'OBTENER_GP_EXITO'
const OBTENER_GP_REQUEST = 'OBTENER_GP_REQUEST'




//REDUCERS

export function CFlGPReducer(state = dataInicialCFlGP, action){
    switch (action.type) {
        case OBTENER_CFlGP_REQUEST:
            return { loadingCFlGP: true, arrayCFlGP:[] };
        case OBTENER_CFlGP_EXITO:
            return {...state, arrayCFlGP: action.payload, loadingCFlGP: false};       
        default:
            return state
    }
}

export function categoriasStoreReducer(state = dataInicialC, action){
    switch (action.type) {
        case OBTENER_CATEGORIAS_REQUEST:
            return { loadingC: true, arrayC:[] };
        case OBTENER_CATEGORIAS_EXITO:
            return {...state, arrayC: action.payload, loadingC: false};       
        default:
            return state
    }
}
export function categoriasStoreProdReducer(state = dataInicialCProd, action){
    switch (action.type) {
        case OBTENER_CATEGORIASPRODUCTOS_REQUEST:
            return { loadingProd: true, arrayProd:[] };
        case OBTENER_CATEGORIASPRODUCTOS_EXITO:
            return {...state, arrayProd: action.payload, loadingProd: false};       
        default:
            return state
    }
}


export function familiasStoreReducer(state = dataInicialF, action){
    switch (action.type) {
        case OBTENER_FAMILIAS_REQUEST:
            return { loadingF: true, arrayF:[] };
        case OBTENER_FAMILIAS_EXITO:
            return {...state, arrayF: action.payload, loadingF: false};       
        default:
            return state
    }
}
export function familiasStoreProdReducer(state = dataInicialFProd, action){
    switch (action.type) {
        case OBTENER_FAMILIASPRODUCTOS_REQUEST:
            return { loadingFProd: true, arrayFProd:[] };
        case OBTENER_FAMILIASPRODUCTOS_EXITO:
            return {...state, arrayFProd: action.payload, loadingFProd: false};       
        default:
            return state
    }
}


export function LineasStoreReducer(state = dataInicialL, action){
    switch (action.type) {
        case OBTENER_LINEAS_REQUEST:
            return { loadingL: true, arrayL:[] };
        case OBTENER_LINEAS_EXITO:
            return {...state, arrayL: action.payload, loadingL: false};       
        default:
            return state
    }
}
export function LineasStoreProdReducer(state = dataInicialLProd, action){
    switch (action.type) {
        case OBTENER_LINEASPRODUCTOS_REQUEST:
            return { loadingLProd: true, arrayLProd:[] };
        case OBTENER_LINEASPRODUCTOS_EXITO:
            return {...state, arrayLProd: action.payload, loadingLProd: false};       
        default:
            return state
    }
}

export function GPStoreReducer(state = dataInicialGP, action){
    switch (action.type) {
        case OBTENER_GP_REQUEST:
            return { loadingGP: true, arrayGP:[] };
        case OBTENER_GP_EXITO:
            return {...state, arrayGP: action.payload, loadingGP: false};       
        default:
            return state
    }
}





//ACCIONES

export const CFlGPAccion = (categoria) => async (dispatch, getState) => {
    
    if (sessionStorage.getItem('undataCFlGP')) {
        dispatch({
            type: OBTENER_CFlGP_EXITO,
            payload: JSON.parse(sessionStorage.getItem('undataCFlGP'))
        })
    }

    try {
        dispatch({type:OBTENER_CFlGP_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/cflgp')
        dispatch({
            type: OBTENER_CFlGP_EXITO,
            payload: res.data
        })

        sessionStorage.setItem('undataCFlGP', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}

export const categoriasStoreAccion = (categoria) => async (dispatch, getState) => {
    
    if (sessionStorage.getItem('categoriasStore')) {
        dispatch({
            type: OBTENER_CATEGORIAS_EXITO,
            payload: JSON.parse(sessionStorage.getItem('categoriasStore'))
        })
    }

    try {
        dispatch({type:OBTENER_CATEGORIAS_REQUEST})
        dispatch({type:OBTENER_CATEGORIASPRODUCTOS_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+`/bs/${categoria}`)
        const resProd = await axios.get(process.env.REACT_APP_UNSPLASH_URL+`/bsprod/${categoria}`)
        dispatch({
            type: OBTENER_CATEGORIAS_EXITO,
            payload: res.data
        })
        dispatch({
            type: OBTENER_CATEGORIASPRODUCTOS_EXITO,
            payload: resProd.data
        })

        sessionStorage.setItem('categoriasStore', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}

export const familiasStoreAccion = (categoria, familia) => async (dispatch, getState) => {

    //const categoria = getState().storeFamilias.categorias;
    //const familia = getState().storeFamilias.familias;
    
    if (sessionStorage.getItem('familiasStore')) {
        dispatch({
            type: OBTENER_FAMILIAS_EXITO,
            payload: JSON.parse(sessionStorage.getItem('familiasStore'))
        })
    }

    try {
        dispatch({type:OBTENER_FAMILIAS_REQUEST})
        dispatch({type:OBTENER_FAMILIASPRODUCTOS_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+`/bs/${categoria}/${familia}`)
        const resProd = await axios.get(process.env.REACT_APP_UNSPLASH_URL+`/bsprod/${categoria}/${familia}`)
        dispatch({
            type: OBTENER_FAMILIAS_EXITO,
            payload: res.data
        })
        dispatch({
            type: OBTENER_FAMILIASPRODUCTOS_EXITO,
            payload: resProd.data
        })

        sessionStorage.setItem('familiasStore', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}

export const lineasStoreAccion = (categoria, familia, linea) => async (dispatch, getState) => {

    //const categoria = getState().storeFamilias.categorias;
    //const familia = getState().storeFamilias.familias;
    
    if (sessionStorage.getItem('lineasStore')) {
        dispatch({
            type: OBTENER_LINEAS_EXITO,
            payload: JSON.parse(sessionStorage.getItem('lineasStore'))
        })
    }

    try {
        dispatch({type:OBTENER_LINEAS_REQUEST})
        dispatch({type:OBTENER_LINEASPRODUCTOS_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+`/bs/${categoria}/${familia}/${linea}`)
        const resProd = await axios.get(process.env.REACT_APP_UNSPLASH_URL+`/bsprod/${categoria}/${familia}/${linea}`)
        dispatch({
            type: OBTENER_LINEAS_EXITO,
            payload: res.data
        })
        dispatch({
            type: OBTENER_LINEASPRODUCTOS_EXITO,
            payload: resProd.data
        })

        sessionStorage.setItem('lineasStore', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}

export const gpStoreAccion = (categoria, familia, linea, gp) => async (dispatch, getState) => {

    //const categoria = getState().storeFamilias.categorias;
    //const familia = getState().storeFamilias.familias;
    
    if (sessionStorage.getItem('gpStore')) {
        dispatch({
            type: OBTENER_GP_EXITO,
            payload: JSON.parse(sessionStorage.getItem('gpStore'))
        })
    }

    try {
        dispatch({type:OBTENER_GP_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+`/bs/${categoria}/${familia}/${linea}/${gp}`)
        dispatch({
            type: OBTENER_GP_EXITO,
            payload: res.data
        })

        sessionStorage.setItem('gpStore', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}