/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useRef, useState } from 'react'

import { Ripple } from 'primereact/ripple';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {useDispatch, useSelector} from 'react-redux'

import banner from '../../../assets/img/banner1JS.png'
import banner1 from '../../../assets/img/banner2JS.jpg'

import img41 from '../../../assets/img/product-list-4-1.png'
import img42 from '../../../assets/img/product-list-4-2.png'
import img43 from '../../../assets/img/product-list-4-3.png'
import img44 from '../../../assets/img/product-list-4-4.png'
import img45 from '../../../assets/img/product-list-4-5.png'
import img46 from '../../../assets/img/product-list-4-5.png'
import img71 from '../../../assets/img/Call1.jpg'
import img72 from '../../../assets/img/call2.png'
import { Image } from 'primereact/image';


import { Carousel } from 'primereact/carousel';
import './iniciovisitorsStyle.css'


import { carouselIndexEcoAccion, EEPIndexEcoAccion, promocionIndexEcoAccion } from '../../../Redux/patos/EcoDuck';
import Cargando from '../comps/Cargando';
import { Link } from 'react-router-dom';


import { UserContext } from '../../../context/UserProvider';
const InicioVisitors = () => {

    const dispatch = useDispatch();
    const ecoCarousel = useSelector(store => store.ecoCarousel)
    const [ecoEEPs, setEcoEEPs] = useState()
    const [page, setPage] = useState(0)

    const {userBer, userFire} = useContext(UserContext);
    const ecoEEP = useSelector(store => store.ecoEEP)
    const ecoPromo = useSelector(store => store.ecoPromo)

    useEffect(() => {
        const generarData = () =>{   
            dispatch(carouselIndexEcoAccion())
            dispatch(EEPIndexEcoAccion())
            dispatch(promocionIndexEcoAccion())
            
        }
        generarData()
    },[dispatch])

    useEffect(()=>{

        //console.log()
        setEcoEEPs(ecoEEP)

    },[ecoEEP.loadingEEP, ecoEEP])


    const responsiveOptionsIndex = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const productTemplate = (product) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mb-3">
                        <img src={`${product.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.nombre} className="product-image" />
                    </div>
                    
                    <div>
                        <h4 className="mb-1 h-3rem">{product.nombre}</h4>
                        <h6 className="mt-0 mb-3 text-xl">BOB. {product.precio}</h6>
                        
                        <div className="car-buttons mt-5">
                            <Button icon="pi pi-search" className="p-button p-button-rounded mr-2" />
                            <Button icon="pi pi-star-fill" className="p-button-success p-button-rounded mr-2" />
                            <Button icon="pi pi-cog" className="p-button-help p-button-rounded" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const productTemplate1 = (product) => {
        //console.log(product)
        return (
            
            <div className="product-item p-2">
                <div className="product-item-content">
                    <div className="shadow-2 p-4 surface-card border-round">
                        <div className="relative mb-3">
                            {
                                product.nombreGrupoProductos === null ? (
                                    <span className="no-underline surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>{product.nombreGrupoProductos}</span>
                                ):(
                                    <Link>
                                        <span className="no-underline surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>{product.nombreGrupoProductos}</span>
                                    </Link>
                                )
                            }
                            <img src={`${product.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.nombre} className="product-image" />
                        </div>
                        <div className="flex justify-content-between align-items-center mb-3">
                            <span className="text-900 font-medium text-xl h-5rem">{product.nombre}</span>
                            
                        </div>
                        <div className="flex justify-content-between align-items-center mb-3">
                            <span className="text-primary text-xl font-medium">BOB. {product.precio}</span>
                            <span>
                                <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                <span className="font-medium">{product.rating}</span>
                            </span>
                        </div>
                        <div>
                                              
                            <div className="car-buttons mt-5">
                                <Link to={`/tienda/productodetalle/${product.producto_id}`} className='no-underline'><Button  icon="pi pi-search" className="p-button p-button-rounded mr-2 surface-900 hover:surface-700 " /></Link>
                                <Button icon="pi pi-star-fill" className="p-button-success p-button-rounded mr-2 surface-900 hover:surface-700" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }

    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
      }

    const pageHandle = (e) =>{
        console.log(e)
        setPage(e.page)
    }
    //console.log(ecoPromo)

    //https://es.pornhub.com/model/victoria_saint
    //https://es.pornhub.com/model/myshinygirl
    //https://es.pornhub.com/model/azul_whale
    //https://es.pornhub.com/model/helloelly/videos
    //https://es.pornhub.com/model/lis-evans
    //https://es.pornhub.com/model/julie-jess
  return (
    <>
        {
            ecoCarousel.loadingCarousel ? (
            
            <div className='flex justify-content-center align-content-center'>
                <Cargando />
            </div>
            )
            :
            (
                ecoCarousel.arrayCarousel.length > 0 ? (
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay
                        navigation
                        pagination={{ clickable: true }}
                        /*onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}*/
                        className="z-0 w-screen h-35rem md:h-25rem lg:h-30rem mb-4" //hdef
                    >
                        
                        
                        {
                        ecoCarousel.loadingCarousel ? (
                                ''
                        )
                        :
                        (
                            ecoCarousel.arrayCarousel.map((car, index) =>(
                                        <SwiperSlide key={index} className=' overflow-hidden'>
                                            {/*<a  target="_blank" rel="noreferrer">
                                                <Image onClick={()=>console.log('click')} imageClassName='hidden md:inline-flex lg:inline-flex h-full w-full absolute stiloTrue bg-left' src={car.imagen} alt={car.link} /> 
                                                <Image imageClassName='inline-flex md:hidden lg:hidden h-full w-full absolute stiloTrue bg-left' src={car.imgresponsive} alt={car.link} /> 
                                            </a>*/}
                                            
                                            <div className="hidden surface-section h-full w-screen stiloTrue bg-center md:flex lg:flex align-items-end " style={{background: `url(${car.imagen})`}}>
                                                <div className="flex align-content-end px-3 mx-3 lg:px-6 lg:mx-6">
                                                    <a href={car.link === null ? null :`${car.link}`} target="_blank" rel="noreferrer" className="no-underline py-2 w-13rem text-center  mb-4 text-xl text-white font-medium border-2 cursor-pointer border-transparent border-round surface-900">Ver Ahora!</a>
                                                </div>
                                            </div>

                                            <div className="flex surface-section h-full w-screen stiloTrue bg-center md:hidden lg:hidden align-items-end " style={{background: `url(${car.imgresponsive})`}}>
                                                <div className="flex align-content-end px-3 mx-3 lg:px-6 lg:mx-6">
                                                    <a href={car.link === null ? null :`${car.link}`} target="_blank" rel="noreferrer" className="no-underline py-2 w-13rem text-center  mb-4 text-xl text-white font-medium border-2 cursor-pointer border-transparent border-round surface-900">Ver Ahora!</a>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    

                            ))
                        )
                        }

                    </Swiper>
                ):(
                    <div className='h-30rem flex justify-content-center align-self-center align-content-center'>
                        <Cargando />
                    </div>
                )
                
            )
        }


        {/*<div className="surface-section flex flex-column lg:flex-row px-4 py-6 md:px-6 lg:px-8 align-items-center">
            <img alt='imgDel' src={img71} className="h-25rem border-round hidden w-25rem lg:block" />
            <div style={{background: "#041c41"}} className="bg-no-repeat bg-cover w-full lg:border-noround-left border-round-xl px-4 py-4 sm:px-7 ">
                <img alt='imgDel2' src={img71} className="w-full sm:w-15rem border-round block lg:hidden mx-auto mb-5" />
                <div className="text-indigo-50 text-4xl font-semibold mb-3">Novedades Cada Mes!</div>
                <p className="text-indigo-100 mt-0 mb-5 line-height-3">Los productos que mas necestias a un solo click.
                Productos importados, de la mejor calidad directo a tu casa!.
                </p>
                <Button type="button" icon="pi pi-wallet" label="Empieza a comprar ahora!" className="p-button-warning px-5 font-bold white-space-nowrap"></Button>
            </div>
        </div>*/}

        <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div className="flex flex-wrap relative overflow-hidden" style={{borderRadius:"1rem", background: "radial-gradient(100% 1126.49% at 100% 0%, rgba(56, 139, 150, 0.8) 0%, rgba(33, 33, 33, 0) 100%), #000000"}}>
                <div className="px-6 py-8">
                    <span className="uppercase text-cyan-500 font-bold">SE EL PRIMERO EN TENERLO</span>
                    <div className="text-white text-medium text-5xl mt-3 mb-5">Novedades de los mejores productos cada mes!</div>
                    <Button icon="pi pi-wallet" label="Empieza a comprar ahora!"  className=" p-button-warning px-5 font-bold white-space-nowrap cursor-pointer " style={{borderRadius: "35px"}}></Button>
                </div>
                    
                <img src={img71} alt="ImageLogo" className="static lg:absolute block mx-auto" style={{height: "400px", top: "-5rem", right: "0rem"}} />
            </div>
        </div>


      <Divider align="center" className='py-2  text-2xl'>
      </Divider>
        {
            ecoPromo.loadingPromo ? (
                <div className='px-8 py-8'>
                    <ProgressBar mode="indeterminate" color={'black'} style={{ height: '6px' }}></ProgressBar>
                </div>
            ):(
                <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                    {
                        ecoPromo.arrayPromo.map((promo, index)=>(
                            <div key={index} className='mb-6' >
                                <div className=''>
                                    <div className="text-900 font-medium text-5xl mb-4">{promo.nombrePromocion}</div>
                                    <p className="mt-0 p-0 mb-5 text-600 text-2xl">{promo.descripcionPromo} - Hasta el {promo.fechaFin}</p>
                                        <div className="grid grid-nogutter w-full">
                                            {
                                                promo.grupos_eco.map((grupo, indexg)=>{
                                                    return(
                                                        <>
                                                            {
                                                                (indexg+1) % 2 === 0 ?(

                                                                <>
                                                                    
                                                                    {
                                                                        grupo.productos_promocion ? (
                                                                            grupo.productos_promocion.map((pp, indexpp)=>(
                                                                                <div key={indexpp} className="col-12 lg:col-3">
                                                                                    <div className="grid grid-nogutter lg:flex-column">
                                                                                        <div className="col-12 p-1 h-25rem" >
                                                                                            <div className="flex flex-column justify-content-between h-full w-full border-round bg-no-repeat bg-cover bg-center "  style={{background: pp.imagenPP ? `url(${pp.imagenPP})`: `url(${pp.imagenPrincipal})` }}>
                                                                                                <div className="p-4 align-self-start">
                                                                                                    <div className="text-white text-2xl font-bold mb-2">{pp.nombre} - {pp.codigo}</div>
                                                                                                   
                                                                                                </div>
                                                                                                <div className="p-4 align-self-end">
                                                                                                    <a  className="text-2xl cursor-pointer text-white flex align-items-center hover:text-black-alpha-80 transition-duration-150">Comprar Ahora!<i className="pi pi-fw pi-arrow-right text-2xl ml-2"></i></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ):('')
                                                                    }

                                                                    <div  className="col-12 lg:col-6 p-1">
                                                                        <div className="flex flex-column justify-content-between h-full w-full border-round bg-no-repeat bg-cover bg-center   " style={{ background: ` url(${grupo.imagenGrupoPromo})` }}>
                                                                            <div className="p-4 align-self-start">
                                                                                <div className="text-white text-2xl font-bold mb-2">{grupo.nombreGrupo}</div>
                                                                                <div className="text-white text-2xl font-bold mb-2">{grupo.descuentoText}</div>
                                                                                <span className="text-white text-lg line-height-3">{grupo.descripcionGrupoPromo}</span>
                                                                            </div>
                                                                            <div className="p-4 align-self-end">
                                                                                <a className="text-2xl cursor-pointer text-white flex align-items-center hover:text-black-alpha-80 transition-duration-150">Comprar Ahora!<i className="pi pi-fw pi-arrow-right text-2xl ml-2"></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                ):(

                                                                <>
                                                                    <div  className="col-12 md:col-6 lg:col-6 p-1 ">
                                                                        <div className="flex flex-column justify-content-between h-full w-full border-round bg-no-repeat bg-cover bg-center   " style={{ background: `url(${grupo.imagenGrupoPromo})`}}>
                                                                            <div className="p-4 align-self-start">
                                                                                <div className="text-white text-2xl font-bold mb-2">{grupo.nombreGrupo}</div>
                                                                                <div className="text-white text-2xl font-bold mb-2">{grupo.descuentoText}</div>
                                                                                <span className="text-white text-lg line-height-3">{grupo.descripcionGrupoPromo}</span>
                                                                        
                                                                            </div>
                                                                            <div className="p-4 align-self-end">
                                                                                <a className="text-2xl cursor-pointer text-white flex align-items-center hover:text-black-alpha-80 transition-duration-150">Comprar Ahora!<i className="pi pi-fw pi-arrow-right text-2xl ml-2"></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        grupo.productos_promocion ? (
                                                                            grupo.productos_promocion.map((pp, indexpp)=>(
                                                                                <div key={indexpp} className="col-12 md:col-3 lg:col-3 ">
                                                                                    <div className="grid grid-nogutter lg:flex-column">
                                                                                        <div className="col-12 p-1 h-25rem" >
                                                                                            <div className="flex flex-column justify-content-between h-full w-full border-round bg-no-repeat bg-cover bg-center "  style={{background: pp.imagenPP ? `url(${pp.imagenPP})`: `url(${pp.imagenPrincipal})` }}>
                                                                                                <div className="p-4 align-self-start">
                                                                                                    <div className="text-white text-2xl font-bold mb-2">{pp.nombre} - {pp.codigo}</div>
                                                                                                    
                                                                                                </div>
                                                                                                <div className="p-4 align-self-end">
                                                                                                    <a  className="text-2xl cursor-pointer text-white flex align-items-center hover:text-black-alpha-80 transition-duration-150">Comprar Ahora!<i className="pi pi-fw pi-arrow-right text-2xl ml-2"></i></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ):('')
                                                                    }
                                                                </>
                                                                ) 
                                                            }
                                                            
                                                        </>
                                                    )
                                                    })
                                            }
                                        </div>
                                </div>
                                <div className="surface-900  text-0  px-4 py-2 md:px-6 lg:px-8 border-round-sm bg-cover bg-center">
                                    <div className="flex flex-column md:flex-row md:justify-content-between xl:justify-content-evenly">
                                        <Link className='no-underline text-0'>
                                            <span className="inline-flex align-items-center mb-3 md:mb-0">
                                                <i className="pi pi-directions  text-base xl:text-2xl mr-3"></i>
                                                <span className="text-base xl:text-2xl font-medium">Ver los Productos en Oferta</span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            )
        }



      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div className="surface-900 text-0 p-4 border-round-sm">
            <div className="flex flex-column md:flex-row md:justify-content-between xl:justify-content-evenly">
                <span className="inline-flex align-items-center mb-3 md:mb-0">
                    <i className="pi pi-shield  text-base xl:text-2xl mr-3"></i>
                    <span className="text-base xl:text-2xl font-medium">Pago Seguro</span>
                </span>
                <span className="inline-flex align-items-center mb-3 md:mb-0">
                    <i className="pi pi-shopping-cart text-base xl:text-2xl mr-3"></i>
                    <span className="text-base xl:text-2xl font-medium">Entrega Rápida</span>
                </span>

                <span className="inline-flex align-items-center">
                    <i className="pi pi-star text-base xl:text-2xl mr-3"></i>
                    <span className="text-base xl:text-2xl font-medium">2 Años Garantía</span>
                </span>
            </div>
        </div>
      </div>

        {
            ecoEEP? (

                ecoEEP.loadingEEP ? (
                    <div className='flex justify-content-center align-content-center'>
                        <Cargando />
                    </div>
                ):(
                    ecoEEPs ? (
                        
                                
                                    ecoEEPs.arrayEEP.map((eep, index) => (
                                        /*<div key={index} className="surface-section px-4 py-6 md:px-6 lg:px-8">
                                            <div className="text-900 font-medium text-5xl mb-4">{eep[0].nombre_ee}</div>
                                            <p className="mt-0 p-0 mb-5 text-600 text-2xl">{eep[0].descripcion_ee}</p>
                                            <div className="carousel-demo">
                                                <div className="card">
                                                    <Carousel value={eep['prod']} 
                                                    page={0}  
                                                    numVisible={3} numScroll={1} 
                                                    circular={'false'}
                                                    responsiveOptions={responsiveOptionsIndex}
                                                    className="custom-carousel" 
                                                    autoplayInterval={getRandomInt(3000, 4000)}
                                                    //autoplayInterval={2000} 
                                                        itemTemplate={productTemplate1}  />
                                                </div>
                                            </div>
                                        </div>*/
                                            <div key={index} className="surface-section px-4 py-6 md:px-6 lg:px-8">
                                                <div className="text-900 font-medium text-5xl mb-4">{eep[0].nombre_ee}</div>
                                                <p className="mt-0 p-0 mb-5 text-600 text-2xl">{eep[0].descripcion_ee}</p>

                                                <Swiper
                                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                                    spaceBetween={0}
                                                    slidesPerView={3}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: getRandomInt(3000, 4000),
                                                        disableOnInteraction : false
                                                      }}
                                                    navigation
                                                    pagination={{ clickable: true }}
                                                    /*onSwiper={(swiper) => console.log(swiper)}
                                                    onSlideChange={() => console.log('slide change')}*/
                                                    className="z-0 w-full h-full" //hdef
                                                    breakpoints={{
                                                        360:{
 
                                                            slidesPerView: 1,
                                                            spaceBetween: 20
                                                        },
                                                        480:{
 
                                                            slidesPerView: 1,
                                                            spaceBetween: 20
                                                        },
                                                        // when window width is >= 640px
                                                        640: {
                                                          
                                                            slidesPerView: 1,
                                                            spaceBetween: 20
                                                        },
                                                        // when window width is >= 768px
                                                        768: {
                                                          
                                                          slidesPerView: 2,
                                                        },
                                                        1024: {
                                                            
                                                            slidesPerView: 3,
                                                        },
                                                      }}
                                                >
                                                    {
                                                        eep['prod'].map((product, index)=>(

                                                            <SwiperSlide key={index} className='h-full px-6 py-3'>
                                                                <div className="shadow-2 h-full p-3 text-center surface-card border-round">
                                                                    <div className="relative mb-3">
                                                                        {
                                                                            product.nombreGrupoProductos === null ? (
                                                                                <span className="no-underline surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>{product.nombreGrupoProductos}</span>
                                                                            ):(
                                                                                <Link>
                                                                                    <span className="no-underline surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>{product.nombreGrupoProductos}</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        <img src={`${product.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.nombre} className="" style={{ width: '75%'}}/>
                                                                    </div>
                                                                    <div className="flex justify-content-center align-items-center mb-3 px-5">
                                                                        <span className="text-900 font-medium text-xl h-4rem">{product.nombre}</span>
                                                                        
                                                                    </div>
                                                                    <div className="flex justify-content-between align-items-center mb-2 px-5">
                                                                        <span className="text-primary text-xl font-medium">BOB. {product.precio}</span>
                                                                        <span className=''>
                                                                            <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                                                            <span className="font-medium">{product.rating}</span>
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                                        
                                                                        <div className="car-buttons mt-4">
                                                                            <Link to={`/tienda/productodetalle/${product.producto_id}`} className='no-underline'><Button  icon="pi pi-search" className="p-button p-button-rounded mr-2 surface-900 hover:surface-700 " /></Link>
                                                                            <Button icon="pi pi-star-fill" className="p-button-success p-button-rounded mr-2 surface-900 hover:surface-700" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                
                                                            </SwiperSlide>

                                                        ))
                                                    }
                                                
                                                </Swiper>
                                            </div>
                                    ))
                                
                        
                    ):('')
                    
                )

            ):(
                ''
            )
            
        }

      {/* <div className="surface-section px-4 py-6 md:px-6 lg:px-8">
        <div className="text-900 font-medium text-5xl mb-4">SELECCIÓN DEL EDITOR</div>
        <p className="mt-0 p-0 mb-5 text-600 text-2xl">Pensada para vos!</p>
        <div className="grid -mt-3 -ml-3 -mr-3">

            <div className="col-12 md:col-6 lg:col-4">
                <div className="p-2">
                    <div className="shadow-2 p-4 surface-card border-round">
                        <div className="relative mb-3">
                            <span className="surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>Category</span>
                            <img src={img41} alt='Logito' className="w-full" />
                        </div>
                        <div className="flex justify-content-between align-items-center mb-3">
                            <span className="text-900 font-medium text-xl">Product Name</span>
                            <span>
                                <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                <span className="font-medium">5.0</span>
                            </span>
                        </div>
                        <p className="mt-0 mb-3 text-600 line-height-3">
                            Enim nec dui nunc mattis enim ut tellus. Tincidunt arcu.
                        </p>
                        <span className="text-primary text-xl font-medium">$140.00</span>
                    </div>
                </div>
            </div>

          <div className="col-12 md:col-6 lg:col-4">
              <div className="p-2">
                  <div className="shadow-2 p-4 surface-card border-round">
                      <div className="relative mb-3">
                          <span className="surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>Category</span>
                          <img src={img42} alt='Logito' className="w-full" />
                      </div>
                      <div className="flex justify-content-between align-items-center mb-3">
                          <span className="text-900 font-medium text-xl">Product Name</span>
                          <span>
                              <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                              <span className="font-medium">5.0</span>
                          </span>
                      </div>
                      <p className="mt-0 mb-3 text-600 line-height-3">
                          Enim nec dui nunc mattis enim ut tellus. Tincidunt arcu.
                      </p>
                      <span className="text-primary text-xl font-medium">$82.00</span>
                  </div>
              </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
              <div className="p-2">
                  <div className="shadow-2 p-4 surface-card border-round">
                      <div className="relative mb-3">
                          <span className="surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>Category</span>
                          <img src={img43} alt='Logito' className="w-full" />
                      </div>
                      <div className="flex justify-content-between align-items-center mb-3">
                          <span className="text-900 font-medium text-xl">Product Name</span>
                          <span>
                              <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                              <span className="font-medium">5.0</span>
                          </span>
                      </div>
                      <p className="mt-0 mb-3 text-600 line-height-3">
                          Enim nec dui nunc mattis enim ut tellus. Tincidunt arcu.
                      </p>
                      <span className="text-primary text-xl font-medium">$54.00</span>
                  </div>
              </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
              <div className="p-2">
                  <div className="shadow-2 p-4 surface-card border-round">
                      <div className="relative mb-3">
                          <span className="surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}} >Category</span>
                          <img src={img44} alt='Logito' className="w-full" />
                      </div>
                      <div className="flex justify-content-between align-items-center mb-3">
                          <span className="text-900 font-medium text-xl">Product Name</span>
                          <span>
                              <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                              <span className="font-medium">5.0</span>
                          </span>
                      </div>
                      <p className="mt-0 mb-3 text-600 line-height-3">
                          Enim nec dui nunc mattis enim ut tellus. Tincidunt arcu.
                      </p>
                      <span className="text-primary text-xl font-medium">$72.00</span>
                  </div>
              </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
              <div className="p-2">
                  <div className="shadow-2 p-4 surface-card border-round">
                      <div className="relative mb-3">
                          <span className="surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>Category</span>
                          <img src={img45} alt='Logito' className="w-full" />
                      </div>
                      <div className="flex justify-content-between align-items-center mb-3">
                          <span className="text-900 font-medium text-xl">Product Name</span>
                          <span>
                              <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                              <span className="font-medium">5.0</span>
                          </span>
                      </div>
                      <p className="mt-0 mb-3 text-600 line-height-3">
                          Enim nec dui nunc mattis enim ut tellus. Tincidunt arcu.
                      </p>
                      <span className="text-primary text-xl font-medium">$99.00</span>
                  </div>
              </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
              <div className="p-2">
                  <div className="shadow-2 p-4 surface-card border-round">
                      <div className="relative mb-3">
                          <span className="surface-card text-900 shadow-2 px-3 py-2 absolute border-round-3xl" style={{left: '1rem', top: '1rem'}}>Ropa?</span>
                          <img src={img46} alt='Logito' className="w-full" />
                      </div>
                      <div className="flex justify-content-between align-items-center mb-3">
                          <span className="text-900 font-medium text-xl">Product Name</span>
                          <span>
                              <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                              <span className="font-medium">5.0</span>
                          </span>
                      </div>
                      <p className="mt-0 mb-3 text-600 line-height-3">
                          Enim nec dui nunc mattis enim ut tellus. Tincidunt arcu.
                      </p>
                      <span className="text-primary text-xl font-medium">$89.00</span>
                  </div>
              </div>
          </div>
        </div>
      </div> */}
    
    </>
  )
}

export default InicioVisitors