import { createContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom'


import { auth } from "../Firebase/firebase";

import axios from "axios";

export const UserContext = createContext();

const UserProvider = ({children}) => {

  const navigate = useNavigate();

    const [user, setUser] = useState('');
    const [userBer, setUserBer] = useState('');
    const [userFire, setUserFire] = useState('');
    const [userLoading, setUserLoading] = useState(true);

    useEffect(() => {
      setUserLoading(true)
      const Consulta = async (config, userFire ) =>{
            
        const res = await axios(config).catch((error)=>{
          localStorage.removeItem('_vita');
          localStorage.removeItem('_dolce');
          
          navigate('/login');
          setUserLoading(false)
        })
        //console.log(res.data.data.tokenable)
        try {
          
          if (res.status) {    
            
            if (userFire && (res.status === 200)) {    
              //console.log(userFire)          
              const { uid, email, photoURL, displayName } = userFire;
              setUserFire({ uid, email, photoURL, displayName });
              //console.log(res)
              setUserBer(res.data.data.tokenable)
              setUserLoading(false)
            } else {
                setUser(null);
            }
          }else{
            signOutUser()
            localStorage.removeItem('_vita');
            localStorage.removeItem('_dolce');
            navigate('/login');
            setUserLoading(false)
          }

        } catch (error) {
          signOutUser()
            localStorage.removeItem('_vita');
            localStorage.removeItem('_dolce');
        }
          
      }
      const unsuscribe = onAuthStateChanged(auth, (userFire) => {

          let token = ''
          try {
            token = atob(localStorage.getItem('_vita'));
            //console.log(token)
          } catch (error) {
              localStorage.removeItem('_vita');
              localStorage.removeItem('_dolce');
              navigate('/login');
              signOutUser()
              setUserLoading(false)
          }
          const tok = 'Bearer ' + token;
          var config = {
            method: 'post',
            url: (process.env.REACT_APP_UNSPLASH_URL+'/tokenvisitor'),
            headers: { 
              "Accept": 'application/json',
              "Content-Type": 'application/json',
              'Authorization': tok,
            },
            data : token
          };

          

          if (token === 'ée') {
            setUserLoading(false)
          }else{
            Consulta(config, userFire)
          }

      });
      return () => unsuscribe();
      // eslint-disable-next-line
    }, []);
    
    const registerUser = (email, password) =>
    createUserWithEmailAndPassword(auth, email, password);

    const loginUser = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

    const loginGoogle = () =>{
        const googleProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleProvider);
    }
    
    const signOutUser = () => {
      signOut(auth);
      localStorage.removeItem('_vita');
      localStorage.removeItem('_dolce');
    };

   

  return (
    <UserContext.Provider value={{userLoading, user, setUser, registerUser, loginUser, loginGoogle, userBer, setUserBer, userFire, signOutUser, setUserFire}}>
        {children}
    </UserContext.Provider>
  )
}

export default UserProvider