/* eslint-disable no-unused-vars */
import { useContext, useRef, useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import * as Yup from 'yup';
import { classNames } from 'primereact/utils';

import './Stylo.css';
import { UserContext } from "../../../context/UserProvider";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Cargando from "../comps/Cargando";

const LoginVisitors = () => {


    const toast = useRef(null);
    const navegate = useNavigate();


    const {userLoading, loginUser, loginGoogle, setUserBer, setUserFire, userBer, userFire } = useContext(UserContext);
    const [cargando, setCargando] = useState(false);
    const [cargandoP, setCargandoP] = useState(false);

  const formik = useFormik({
    initialValues:{

        email: '',
        password: '',

    },
    validationSchema: Yup.object({

        email: Yup.string()
            .min(3, 'Mínimo tres caracteres.')
            .email('Ingrese un Email válido')
            .required('El campo es Requerido'),
        password: Yup.string()
            .min(6, 'Mínimo seis caracteres.')
            .required('Ingrese su Contraseña.'),

    }),
    onSubmit:async (data)=>{
      setCargando(true)
        if (data.password && data.email) {
            
            try {
                const userConfirme = await loginUser(data.email, data.password);
                //console.log(userConfirme);
                localStorage.setItem('_dolce', btoa(userConfirme.user.accessToken))
                setUserFire(userConfirme.user);
                //navegate("/");
                if (userConfirme.user) {
                    //navegate("/");
                    loginBersati(data)
                    
                }else{
                    console.log(userConfirme)
                    setCargando(false)
                }
            } catch (error) {
                setCargando(false)
                formik.values.password = '';
                formik.values.confirm = '';
                toast.current.show({severity:'error', summary: 'Error al iniciar sesión.', detail: error.code, life: 3000});
                console.log(error.code);
            }
        }else{
            toast.current.show({severity:'error', summary: 'Error en los campos.', detail:'Los campos no deben ir vacios.', life: 3000});
            formik.values.password = '';
            formik.values.confirm = '';
        }    
              
    }
  })


  const handleLoginGoogle = async() => {

    setCargando(true)

    try {
      const respon = await loginGoogle();
      //console.log(respon);

      if (respon.user) {
          
          setUserFire(respon.user);
          localStorage.setItem('_dolce', btoa(respon.user.accessToken))
  

          loginWithGoogle(respon.user)
      }else{
          toast.current.show({severity:'error', summary: 'Error en el Registro', detail: 'Usuario no Creado, pruebe otro tipo de registro..', life: 3000});
      }  
    } catch (error) {
        console.log(error);
        setCargando(false)
    }
  }
  
  const loginWithGoogle = async (data) =>{

    const response = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/loginGoogle', data);
    //console.log(response);
    try {
      if (response.status === 200) {
            //console.log(response)
          setUserBer(response.data.user)
          localStorage.setItem('_vita', btoa(response.data.token));
          navegate("/");
          formik.resetForm();
          setCargando(false)


      }else if (response.status === 202) {
          formik.resetForm();
          toast.current.show({severity:'error', summary: 'Error al Iniciar Sesión', detail: 'Contraseña o Email incorrectos', life: 3000});
          setCargando(false)
          localStorage.removeItem('_dolce');
      }

      setCargando(false)
      
    } catch (error) {
        setCargando(false)
        toast.current.show({severity:'error', summary: 'Error al Iniciar Sesión', detail: 'Pruebe otro método de incio de sesión.', life: 3000});
    }
    
    
    //navegate("/");
    setCargando(false)
  }

  const loginBersati = async (data) =>{
        
    const response = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/login', data)

    //console.log(response)
    try {
        if (response.status === 200) {
            setUserBer(response.data.user)
            localStorage.setItem('_vita', btoa(response.data.token));
            navegate("/");
            formik.resetForm();
            setCargando(false)


        }else if (response.status === 202) {
            formik.resetForm();
            toast.current.show({severity:'error', summary: 'Error al Iniciar Sesión', detail: 'Contraseña o Email incorrectos', life: 3000});
            setCargando(false)
        }

        setCargando(false)
        
    } catch (error) {
        setCargando(false)
        toast.current.show({severity:'error', summary: 'Error al Iniciar Sesión', detail: 'Pruebe otro método de incio de sesión.', life: 3000});
    }
    
    
}

    useEffect(() =>{
        if (userBer && userFire) {
            navegate('/');
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userBer, userFire])

  return (
    <>
      <Toast ref={toast} />
      {
        userLoading ? (
            <div className='flex align-items-center justify-content-center '>
                <Cargando />
            </div>
        ):(
            <div className="flex justify-content-center surface-white px-4 py-8 md:px-6 lg:px-8">
                <div className="lg:w-4 shadow-2">
                    <div className="w-full p-4 lg:p-7 ">
                        <div className="flex align-items-center justify-content-between mb-6">
                            <span className="text-2xl font-medium text-900">INICIO DE SESIÓN EN BERSATI</span>
                        </div>
                            
                        <div className="flex justify-content-between">
                            <Button loading={cargando} className="mr-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
                                <i className="pi pi-facebook text-indigo-400 ml-1 mr-1"></i>
                                <span>Inicia Sesión con Facebook</span>
                            </Button>
                            <Button loading={cargando} onClick={handleLoginGoogle}  className="ml-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
                                <i className="pi pi-google text-pink-400 ml-1 mr-1"></i>
                                <span>Inicia Sesión con Google</span>
                            </Button>
                        </div>
                        <Divider align="center" className="my-4">
                            <span className="text-600 font-normal text-sm">O</span>
                        </Divider>
                        <form onSubmit={formik.handleSubmit} className="">
                            <div className="field">
                                <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                                <InputText  id="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className={classNames('w-full p-3',{ 'p-invalid':  formik.errors.email && formik.touched.email })}/>
                                { formik.errors.email && formik.touched.email ? (
                                        <p className='p-error p-0 m-0'>{formik.errors.email}</p>
                                    ) : null }
                            </div>
                            <div className="field">
                                <label htmlFor="password" className="block text-900 font-medium mb-2">Contraseña</label>
                                <InputText  id="password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" className={classNames('w-full p-3',{ 'p-invalid':  formik.errors.password && formik.touched.password })} />
                                { formik.errors.password && formik.touched.password ? (
                                        <p className='p-error p-0 m-0'>{formik.errors.password}</p>
                                    ) : null }
                            </div>
                        
                            <Button type="submit" loading={cargando} label="Iniciar Sesión" className="w-full surface-900 mt-4 py-3 font-medium"></Button>
                        </form>  
                    </div>
                </div>
            </div>    
        )
      }
  </>
  )
}

export default LoginVisitors