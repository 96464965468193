/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import axios from "axios";
import { json } from "react-router-dom";
//CONSTANTES

const dataInicial = {
    array : [],
    loading : '',
};

const dataInicialL = {
    arrayL : [],
    loadingL : '',
};

const dataInicialC = {
    arrayC : [],
    loadingC : '',
};

const dataInicialGP = {
    arrayGP : [],
    loadingGP : '',
};

//TYPES
const OBTENER_NAVBAR_EXITO = 'OBTENER_NAVBAR_EXITO'
const OBTENER_NAVBAR_REQUEST = 'OBTENER_NAVBAR_REQUEST'

const OBTENER_NAVBARL_EXITO = 'OBTENER_NAVBARL_EXITO'
const OBTENER_NAVBARL_REQUEST = 'OBTENER_NAVBARL_REQUEST'

const OBTENER_NAVBARC_EXITO = 'OBTENER_NAVBARC_EXITO'
const OBTENER_NAVBARC_REQUEST = 'OBTENER_NAVBARC_REQUEST'

const OBTENER_NAVBARGP_EXITO = 'OBTENER_NAVBARGP_EXITO'
const OBTENER_NAVBARGP_REQUEST = 'OBTENER_NAVBARGP_REQUEST'


//REDUCERS

export function navbarReducer(state = dataInicial, action){
    switch (action.type) {
        case OBTENER_NAVBAR_REQUEST:
            return { loading: true, array:[] };
        case OBTENER_NAVBAR_EXITO:
            return {...state, array: action.payload, loading: false};       
        default:
            return state
    }
}

export function navbarReducerL(state = dataInicialL, action){
    switch (action.type) {
        case OBTENER_NAVBARL_REQUEST:
            return { loadingL: true, arrayL:[] };
        case OBTENER_NAVBARL_EXITO:
            return {...state, arrayL: action.payload, loadingL: false};          
        default:
            return state
    }
}

export function navbarReducerC(state = dataInicialC, action){
    switch (action.type) {
        case OBTENER_NAVBARC_REQUEST:
            return { loadingC: true, arrayC:[] };
        case OBTENER_NAVBARC_EXITO:
            return {...state, arrayC: action.payload, loadingC: false};          
        default:
            return state
    }
}

export function navbarReducerGP(state = dataInicialGP, action){
    switch (action.type) {
        case OBTENER_NAVBARGP_REQUEST:
            return { loadingGP: true, arrayGP:[] };
        case OBTENER_NAVBARGP_EXITO:
            return {...state, arrayGP: action.payload, loadingGP: false};          
        default:
            return state
    }
}



//ACCIONES

export const obtenerNavbarAccion = () => async (dispatch, getState) => {
    
    if (sessionStorage.getItem('familias')) {
        dispatch({
            type: OBTENER_NAVBAR_EXITO,
            payload: JSON.parse(sessionStorage.getItem('familias'))
        })
    }

    try {
        dispatch({type:OBTENER_NAVBAR_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/familias')
        dispatch({
            type: OBTENER_NAVBAR_EXITO,
            payload: res.data
        })

        sessionStorage.setItem('familias', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}

export const obtenerNavbarAccionL = () => async (dispatch, getState) => {

    if (sessionStorage.getItem('lineas')) {
        dispatch({
            type: OBTENER_NAVBARL_EXITO,
            payload: JSON.parse(sessionStorage.getItem('lineas'))
        })
    }

    try {
        dispatch({type:OBTENER_NAVBARL_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/lineas')
        dispatch({
            type: OBTENER_NAVBARL_EXITO,
            payload: res.data
        })

        sessionStorage.setItem('lineas', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}

export const obtenerNavbarAccionC = () => async (dispatch, getState) => {

    

    try {
        dispatch({type:OBTENER_NAVBARC_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/categorias')
        dispatch({
            type: OBTENER_NAVBARC_EXITO,
            payload: res.data
        })

        sessionStorage.setItem('categorias', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}

export const obtenerNavbarAccionGP = () => async (dispatch, getState) => {

    if (sessionStorage.getItem('grupoproductos')) {
        dispatch({
            type: OBTENER_NAVBARGP_EXITO,
            payload: JSON.parse(sessionStorage.getItem('grupoproductos'))
        })
    }

    try {
        dispatch({type:OBTENER_NAVBARGP_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/grupoproductos')
        dispatch({
            type: OBTENER_NAVBARGP_EXITO,
            payload: res.data
        })

        sessionStorage.setItem('grupoproductos', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}