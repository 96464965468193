/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {useContext, useEffect, useState, useRef} from 'react'
import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { Rating } from 'primereact/rating';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import './DataViewDemo.css';



import {obtenerProductosIndexAccion} from '../../../../../Redux/patos/storeIndexDuck'

import productoQW from '../../../../../assets/img/productoQW.png'

import './store.css'
import { string } from 'yup';
import { UserContext } from '../../../../../context/UserProvider';
import axios from 'axios';


const StoreIndex = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);
  const [products, setProducts] = useState(null);
  const [layout, setLayout] = useState('grid');
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortField, setSortField] = useState(null);
  const sortOptions = [
      {label: 'Price High to Low', value: '!price'},
      {label: 'Price Low to High', value: 'price'},
  ];
  
  const {userBer, userFire, signOutUser} = useContext(UserContext);
  const [displayPosition, setDisplayPosition] = useState(false);

  const toastBC = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    const generarData = () =>{

        dispatch(obtenerProductosIndexAccion())
        
    }
    generarData()
  },[dispatch])

  const productosIndex = useSelector(store => store.productosListaIndex)

  

  const defaultState = {
    item: "",
    estado: ""
  };
 

  const [rows, setRows] = useState([defaultState]);
 
  const [visible, setVisible] = useState(null);


  useEffect(()=>{
    /*let verid = []
    
    for (let index = 0; index < productosIndex.array.length; index++) {
      let ituems = {
        estado : false
      }
      verid.push(ituems)
    }
    setVisible(verid)*/
    setProducts(productosIndex.array)
  }, [productosIndex])


  const eventoChange = ($id, $estado) =>{
    const defaultState = {
      item: $id,
      estado: $estado
    };
    setRows(defaultState)
  }

  const eventoChange1 = ($id1,$estado1) =>{
     
    let ituems = {
      estado : $estado1
    }
    visible.splice($id1, 1, ituems)
    setVisible([...visible])
    
  }

 // console.log(visible)

  const onSortChange = (event) => {
    const value = event.value;

    if (value.indexOf('!') === 0) {
        setSortOrder(-1);
        setSortField(value.substring(1, value.length));
        setSortKey(value);
    }
    else {
        setSortOrder(1);
        setSortField(value);
        setSortKey(value);
    }
  }

  const showConfirm = () => {
    toastBC.current.show({style:{width: '30rem'}, severity:'info', closable: true, sticky:true, content: (
      <div className="flex border-1">
          <div className="flex flex-column p-3">
              <div className="flex align-items-center">
                  <span className="flex align-items-center text-white justify-content-center text-red-800 mr-3 bg-red-700 border-circle" style={{width:'45px', height:'25px'}}>
                      <i className="pi pi-info text-lg"></i>
                  </span>
                  <span className="font-medium text-2xl text-900">Inicia Sesión o Registrate</span>
              </div>
              <p className="my-3 p-0 line-height-3 text-700">Inicia Sesión para añadir al Carrito</p>
          </div>
          <div className="flex flex-column border-left-1">
              <div className="flex-auto flex align-items-center justify-content-center px-3">
                  <Button label="Registrarse" className="p-button-text text-900 text-2xl font-medium" ></Button>
              </div>
              <div className="flex-auto border-top-1 flex align-items-center justify-content-center px-3">
                  <Button label="Iniciar Sesión" className="p-button-text text-900 text-2xl font-medium" ></Button>
              </div>
          </div>
      </div>
    ) });
  } 

  const VerificarToken = () =>{
    let token = ''
      try {
        token = atob(localStorage.getItem('_vita'));
        //console.log(token)
        return token;
      } catch (error) {
          localStorage.removeItem('_vita');
          localStorage.removeItem('_dolce');
          navigate('/login');
          signOutUser()
        return 401;
      }
    
  }

  const SaveAlCarrito = async (data, token) =>{

    const tok = 'Bearer ' + token;
    const config = {
        method: 'post',
        url: process.env.REACT_APP_UNSPLASH_URL+'/carritoadd',
        headers:{
            'Content-Type':'application/json',
            'Authorization': tok,
        },
        data: data,
    }

    const res = await axios(config)

    return res;
  }
  const añadirAlCarrito =  async (id) => {

    if (userBer && userFire) {
        const tokenV =  VerificarToken();
        if (tokenV === 401) {
            
        }else{
            const data = {
                'producto_id' : id,
                'cantidad' : 1,
                'usuario_id' : userBer.id
            }

            const responFinal = await SaveAlCarrito(data, tokenV)

           if (responFinal.data) {
                if (responFinal.data.dis === 1) {
                    toast.current.show({severity:'error', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                }else if(responFinal.data.dis === 0){
                    toast.current.show({severity:'success', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                }
           }
        }
        
    }else{
      onDisplay()
    }
  }

  //console.log(products)
  const renderListItem = (data) => {
    return (
        <div className="col-12" >
            <div className="product-list-item">
                <img src={`${data.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} className='w-6rem' />
                <div className="product-list-detail">
                    <div className="product-name text-xl h-2rem">{data.nombre}</div>
                    <div className="product-description text-sm">{data.descripcionCorta}</div>
                    <Rating value={data.rating} readOnly cancel={false}></Rating>
                    <i className="pi pi-tag product-category-icon"></i><span className="product-category">{data.nombreCategoria}</span>
                </div>
                <div className="product-list-action">
                    <span className="product-price text-lg">BOB. {data.precio}</span>
                    <Button onClick={()=>añadirAlCarrito(data.id)} icon="pi pi-shopping-cart" className='surface-900' label="Añadir al Carrito" ></Button>
                </div>
            </div>
        </div>
    );
  }
  const renderGridItem = (data) => {
    return (
        <div className="col-12 md:col-4 text-sm py-2 px-0" >
            <div className="product-grid-item card  p-3" style={{minHeight: '450px'}}>
                <div className="product-grid-item-top">
                    <div>
                        <span className="product-category"> 
                          <Badge value={data.nombreCategoria} className="mr-1 text-xs surface-600"></Badge> 
                          <Badge value={data.nombreFamilia} className="text-xs surface-600"></Badge> 
                        </span>
                    </div>
                </div>
                <div className='product-grid-item-top mt-1'>
                  <span className="product-category">
                      <Badge value={data.nombreLinea} className="mr-1  text-xs surface-600"></Badge>
                      <Badge value={data.nombreGrupoProductos} className="mr-1  text-xs surface-600"></Badge>
                  </span>
                </div>
                <div className="product-grid-item-content">
                  <div className='card'>

                    <Image width="468" src={`${data.imagenPrincipal}`}  alt={data.mombre} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                  </div>
                
                    <div className="product-name text-xl h-6rem md:h-4rem">{data.nombre}</div>
                    <div className="product-description text-sm">{data.descripcionCorta}</div>
                    <Rating value={data.rating} className='my-4' readOnly cancel={false}></Rating>
                </div>
                
                <div className="product-grid-item-bottom">
                    <span className="product-price text-lg">BOB. {data.precio}</span>
                    <Button onClick={()=>añadirAlCarrito(data.id)} icon="pi pi-shopping-cart" className='surface-900' label="Añadir al Carrito" ></Button>
                </div>
            </div>
        </div>
    );
}

const itemTemplate = (product, layout) => {
  if (!product) {
      return;
  }

  if (layout === 'list')
      return renderListItem(product);
  else if (layout === 'grid')
      return renderGridItem(product);
}

const renderHeader = () => {
  return (
      <div className="grid grid-nogutter">
          {/*<div className="col-6" style={{textAlign: 'left'}}>
              <Dropdown options={sortOptions} value={sortKey} optionLabel="label" placeholder="Sort By Price" onChange={onSortChange}/>
  </div>*/}
          <div className="col-6" style={{textAlign: 'left'}}>
              <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
          </div>
      </div>
  );
}

const header = renderHeader();


const onDisplay = () => {
  setDisplayPosition(true)
}

const onHide = () => {
  setDisplayPosition(false)
}

const renderFooter = () => {
  return (
      <div>
          <Link to={'/register'} className='no-underline'>   
              <Button label="Regístrate" className="p-button-text text-900 hover:surface-hover" />
          </Link>
          <Link to={'/login'} className='no-underline'>
              <Button label="Iniciar Sesión" className='surface-900' autoFocus />
          </Link>
      </div>
  );
}

  return (
    <>
   
    <div className='p-1 z-5'>
      <Toast ref={toast} position="center-center-right" className='z-5' />
      <h1 className='p-2'>Productos de Bersati</h1>
      {/*<div className='grid'>
        {
          productosIndex.array.map((prod, index)=>(
                
                <div key={prod.id} className='col-12 md:col-4 lg:col-3 flex align-items-center justify-content-center'>
                    {
                      visible[index] ? ( 
                        <div className="shadow-2 minAlto border-round surface-card p-4 max-w-20rem" onMouseEnter={() => eventoChange(index,true)} onMouseLeave={() => eventoChange(index,false)} >
                          <div className="relative flex align-items-center justify-content-center">
                              <img alt='imganesa' src={productoQW} className="w-15rem"/>
                              {
                                rows.item === index &&  rows.estado ? (
                                  <Button onClick={() => eventoChange1(index, true)}  className="flex justify-content-center fadein absolute p-component border-none bg-black-alpha-40 hover:bg-black-alpha-50 px-3 py-2 text-white font-medium text-base border-round cursor-pointer transition-colors transition-duration-300 w-13rem"
                                      style={{bottom: "1rem"}} click="visibleDialog = true">Ver Detalles</Button>
                                )
                                :
                                (
                                  ""
                                )
                              }
                          </div>
                          <div className="flex align-items-center justify-content-between text-lg font-medium mt-3 text-900">
                            <div className='grid'>
                              <div className='col-8 p-0 flex justify-content-start mt-2'>
                                <span >{prod.nombre}</span>

                              </div>
                              <div className='col-4 p-0 flex justify-content-end mt-2'>
                                <span>$10.00</span>
                              </div>
                            </div>      
                          </div>
                        </div>
                      )
                      :
                      (
                        ""
                      )
                    } 
                    {
                      visible[index] ? (
                        
                        <Dialog  visible={visible[index].estado} header={false} onHide={() => eventoChange1(index, false)} style={{width: '700px'}} showHeader={false} contentStyle={{padding:'2rem 2rem 1rem 2rem'}}>
                          <div className="grid relative">
                            <div className="col-12 lg:col-6 text-center">
                                <img alt='imgProd' src={productoQW} className="w-8rem sm:w-15rem lg:w-20rem" />
                            </div>
                            <div className="col-12 lg:col-6 py-0 lg:pl-5">
                              <div className="flex justify-content-end">
                                <Button className="absolute lg:static p-button-text p-button-plain p-button-rounded" icon="pi pi-times pi-2xl" onClick={() => eventoChange1(index, false)} style={{right:'0rem', top:'0rem'}}></Button>
                              </div>

                              <div className="flex align-items-center justify-content-between mb-3">
                                <span className="text-xl font-medium text-900">{prod.nombre}</span>
                              </div>

                              <div className="flex align-items-center justify-content-between mb-3">
                                <div className="text-xl text-900">$123</div>
                                <div className="flex align-items-center">
                                    <span className="mr-3 flex">
                                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                        <i className="pi pi-star-fill text-yellow-500"></i>
                                    </span>
                                </div>
                              </div>
                              <p className="p-0 mt-0 mb-3 line-height-3 text-700">
                                {
                                  prod.descripcion
                                }
                              </p>

                              <br />
                              <Divider />
                              <Button icon="pi pi-shopping-cart" className="w-full" label="Añadir al Carrito"></Button>
                            </div>
                          </div>
                        </Dialog>
                      )
                      :
                      (
                        ""
                      )
                    }
                  
                </div>  
          ))
        }

          
      </div>*/}

      <div className="dataview-demo">
          <div className="card">
              <DataView value={products} layout={layout} header={header}
                      itemTemplate={itemTemplate} paginator paginatorPosition={'both'}  rows={15}
                      sortOrder={sortOrder} sortField={sortField} />
          </div>
      </div>

    </div>
      <Dialog header="Regístrate o Inicia Sesión" visible={displayPosition} position={'right'} modal={true} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => onHide()}
          draggable={false} resizable={false}>
          <p className="m-0">Que esperas para poder disfrutar de toda la tienda de Bersati.</p>
          <br></br>
          <p className="m-0 text-800">Si no tienes una cuenta <span className='text-900 font-semibold'>Regístrate</span>, si ya tienes una cuenta <span className='text-900 font-semibold'>Inicia Sesión</span></p>
      </Dialog>
    </>
  )
}

export default StoreIndex