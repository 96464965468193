/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import { ProgressSpinner } from 'primereact/progressspinner';

const InicioAdmin = () => {

  //const userLogin = useSelector((store) => store.userLogin);
  //const {userInfo} = userLogin;
  

    const activo = useSelector(store => store.userLogin.userInfo)
    //console.log(activo)
  return (
    <>

      <div className='flex flex-column md:flex-row flex-wrap h-full w-full justify-content-center align-content-center'>
        <h1 className='flex align-items-center align-self-center justify-content-center gap-0'>Los detalles llegan pronto...  </h1>
        <ProgressSpinner className='m-0 align-self-center' style={{width: '50px', height: '50px'}} strokeWidth="2"/>
      </div>

    </>
  )
}

export default InicioAdmin