/* eslint-disable no-unused-vars */
import React,{ useEffect, useState, useContext, useRef } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShop } from '@fortawesome/free-solid-svg-icons';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast';

import { UserContext } from '../../../../../context/UserProvider';
import axios from 'axios';

//import { faShop } from '@fortawesome/free-solid-svg-icons';
import prod1 from '../../../../../assets/img/producto/product-overview-3-1.png'

import { itemDetailsProdAccion } from '../../../../../Redux/patos/ItemDetails';
import Cargando from '../../../comps/Cargando';


const ProductoDetalle = () => {

    const {userBer, userFire, signOutUser} = useContext(UserContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    //console.log(id)
    const cargarImagen = require.context("../../../../../assets/img/producto/", true);

    const [displayPosition, setDisplayPosition] = useState(false);

    useEffect(() => {
        const generarData = (id) =>{   
            dispatch(itemDetailsProdAccion(id))
        }
        generarData(id)
    },[dispatch, id])


    const {arrayIDP, loadingIDP} = useSelector(store => store.ecoIDP)
    const toast = useRef(null);
    
    const [imagenes, setImagenes] = useState([]);
    const [imgeSel, setImgSel] = useState(null)

    useEffect(()=>{
        //console.log('Entra...')

        if (arrayIDP.status === 200) {
        
            let data = [
                arrayIDP.data[0].img1,
                arrayIDP.data[0].img2,
                arrayIDP.data[0].img3,
                arrayIDP.data[0].img4
            ]

            setImagenes(data)
            setImgSel(data[0])
        }
    },[arrayIDP])

    
    

   /* if (arrayIDP.status === 200) {
        console.log(arrayIDP.data[0].img1)
    }else if(arrayIDP.status === 202){
        console.log('ERROR.....')
    }*/


    //console.log(arrayIDP)

    const VerificarToken = () =>{
        let token = ''
          try {
            token = atob(localStorage.getItem('_vita'));
            //console.log(token)
            return token;
          } catch (error) {
              localStorage.removeItem('_vita');
              localStorage.removeItem('_dolce');
              navigate('/login');
              signOutUser()
            return 401;
          }
        
      }

      const SaveAlCarrito = async (data, token) =>{

        const tok = 'Bearer ' + token;
        const config = {
            method: 'post',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritoadd',
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            },
            data: data,
        }

        const res = await axios(config)

        return res;
      }
    const añadirAlCarrito =  async (id) => {
        
        if (userBer && userFire) {
            const tokenV =  VerificarToken();
            //console.log(tokenV)
            if (tokenV === 401) {
                
            }else{
                const data = {
                    'producto_id' : id,
                    'cantidad' : 1,
                    'usuario_id' : userBer.id
                }

                const responFinal = await SaveAlCarrito(data, tokenV)
                //console.log(responFinal.data.mensaje)
               if (responFinal.data) {
                    if (responFinal.data.dis === 1) {
                        toast.current.show({severity:'error', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }else if(responFinal.data.dis === 0){
                        toast.current.show({severity:'success', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }
               }
            }
            
        }else{
            //console.log('Inicia Sesión')
            onDisplay()
        }
    }


    const onDisplay = () => {
        setDisplayPosition(true)
    }
    
    const onHide = () => {
        setDisplayPosition(false)
    }

    const renderFooter = () => {
        return (
            <div>
                <Link to={'/register'} className='no-underline'>   
                    <Button label="Regístrate" className="p-button-text text-900 hover:surface-hover" />
                </Link>
                <Link to={'/login'} className='no-underline'>
                    <Button label="Iniciar Sesión" className='surface-900' autoFocus />
                </Link>
            </div>
        );
    }

    
  return (
    <>
        {
            loadingIDP ? (
                <div className='flex justify-content-center align-content-center'>
                    <Cargando />
                </div>
            ):(
                arrayIDP.status === 200 ? (
                    arrayIDP.data[0].producto_promo.length > 0 ? (
                        <div className="grid my-4 p-4">
                            <Toast ref={toast} position="center-right" className='z-5' />
                            <div className="col-12 lg:col-6">
                                <div className="flex">
                                    <div className="flex flex-column w-2 justify-content-between">
                                        {
                                            imagenes.map((img, index) =>
                                                <img key={index} alt='imageLateral'  src={`${img}`} onClick={() => setImgSel(img)} className={classNames({ 'border-primary': imgeSel === img },`w-full cursor-pointer border-2 border-round border-transparent transition-colors transition-duration-150`)} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                                            )
                                        }
                                    </div>
                                    <div className="pl-3 w-10">
                                        <img alt='imageCentral' src={`${imgeSel}`} className="w-full" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 lg:col-6 py-3 lg:pl-6">
                                <div className='grid'>
                                    <div className='col-10'>
                                        <div className="flex align-items-center text-4xl font-medium text-900 mb-4 "> <span className='flex align-content-center'>{arrayIDP.data[0].nombre} <Badge className='ml-2' value="OFERTA" severity="success"></Badge></span></div>
                                        
                                    </div>
                                    <div className='flex col-2 justify-content-end'>
                                        <i className="flex align-items-center mb-4 pi text-3xl font-medium  cursor-pointer pi-heart text-900 mt-2" ></i>
                                    </div>
                                </div>

                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div>
                                        <span className="text-600 font-medium text-4xl block line-through">BOB. {arrayIDP.data[0].precio}</span>
                                        <span className="text-900 font-medium text-4xl block">Precio OFERTA: BOB. {(arrayIDP.data[0].precio - (arrayIDP.data[0].precio * (arrayIDP.data[0].producto_promo[0].descuentoNumber / 100))).toFixed(2)}</span>
                                    </div>
                                    <div className="flex align-items-center justify-content-end">
                                        <span className=" mr-3 font-bold text-900 font-medium">{arrayIDP.data[0].rating}</span>
                                        <span className=''>
                                            <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                        </span>
                                        
                                    </div>
                                </div>

                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div className="flex align-items-center">
                                        <span className="font-bold text-900 font-medium text-base block">Código: <span className='text-500 text-base '>{arrayIDP.data[0].codigo}</span></span>
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div className="flex align-items-center">
                                        <span className="font-bold text-900 font-medium text-base block">Marca: <span className='text-500 text-base '>{arrayIDP.data[0].marca}</span></span>
                                    </div>
                                </div> 

                                <div className="font-bold text-900 mb-3">Tipo de Entrega</div>
                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div className="flex align-items-center">
                                        <span className="text-900 font-medium text-xs md:text-sm block"><FontAwesomeIcon icon={faTruck} className='text-xl md:text-2xl md:ml-3'/><span className='text-600 text-xs md:text-sm '> Disponible para envío a domicilio.</span></span>
                                    </div>
                                    
                                </div> 
                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div className="flex align-items-center">
                                        <span className="text-900 font-medium text-xs md:text-sm block"><FontAwesomeIcon icon={faShop} className='text-xl md:text-2xl md:ml-3'/> <span className='text-600 text-xs md:text-sm '> Disponible para recojo en tienda.</span></span>
                                    </div>
                                    
                                </div> 

                                <div className="font-bold text-900 mb-3"><Link to={'/tienda/'+arrayIDP.data[0].bCategoria} className='no-underline cursor-pointer text-900'>#{arrayIDP.data[0].nombreCategoria}</Link> <Link to={'/tienda/'+arrayIDP.data[0].bCategoria+'/'+arrayIDP.data[0].bFamilia} className='no-underline cursor-pointer text-900'>#{arrayIDP.data[0].nombreFamilia}</Link> <Link to={'/tienda/'+arrayIDP.data[0].bCategoria+'/'+arrayIDP.data[0].bFamilia+'/'+arrayIDP.data[0].bLinea} className='no-underline cursor-pointer text-900'>#{arrayIDP.data[0].nombreLinea}</Link> <Link to={'/tienda/'+arrayIDP.data[0].bCategoria+'/'+arrayIDP.data[0].bFamilia+'/'+arrayIDP.data[0].bLinea+'/'+arrayIDP.data[0].bGP} className='no-underline cursor-pointer text-900'>#{arrayIDP.data[0].nombreGrupoProductos}</Link> </div>

                                <p className="p-0 mt-0 mb-5 line-height-3 text-700">
                                    {arrayIDP.data[0].descripcionCorta}
                                </p>

                                <div className="font-bold text-900 mb-3">Cantidad</div>
                                <div className="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between">
                                    <InputNumber showButtons="true" buttonLayout="horizontal"  min="0" inputClassName="w-3rem text-center"  value={'1'}
                                        decrementButtonClassName="p-button-text" incrementButtonClassName="p-button-text" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></InputNumber>
                                    <div className="flex align-items-center flex-1 mt-3 sm:mt-0 ml-0 sm:ml-5">
                                        <Button onClick={()=>añadirAlCarrito(arrayIDP.data[0].id)}  label="Añadir al Carrito" className="flex-1 mr-5 surface-900"></Button>   
                                    </div>
                                </div>
                            </div>

                            <TabView>
                                <TabPanel header='Descripción'>
                                    <div className="text-900 font-medium text-3xl mb-4 mt-2">Descripción del Producto</div>
                                    <div className='grid grid-nogutter'>
                                        <div className="col-12">
                                            <div dangerouslySetInnerHTML={{__html: arrayIDP.data[0].descripcionLarga}} ></div>                                        
                                        </div>
                                    </div>

                                </TabPanel>
                                <TabPanel header='Características'>
                                    <div className="text-900 font-medium text-3xl mb-4 mt-2">Características del Producto</div>
                                    <div className='grid'>
                                        <div className='col-12'>
                                            <div className="card">
                                                <DataTable value={arrayIDP.data[1]} showGridlines responsiveLayout="scroll">
                                                    <Column field='nombreCaracteristica' header="Caracteristica"></Column>
                                                    <Column field='detalle' header="Detalle"></Column>
                                                    
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>

                                </TabPanel>
                            </TabView>

                            <Dialog header="Regístrate o Inicia Sesión" visible={displayPosition} position={'right'} modal={true} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => onHide()}
                                draggable={false} resizable={false}>
                                <p className="m-0">Que esperas para poder disfrutar de toda la tienda de Bersati.</p>
                                <br></br>
                                <p className="m-0 text-800">Si no tienes una cuenta <span className='text-900 font-semibold'>Regístrate</span>, si ya tienes una cuenta <span className='text-900 font-semibold'>Inicia Sesión</span></p>
                            </Dialog>

                        </div>
                    ):(

                        <div className="grid my-4 p-4">
                            <div className="col-12 lg:col-6">
                                <div className="flex">
                                    <div className="flex flex-column w-2 justify-content-between">
                                        {
                                            imagenes.map((img, index) =>
                                                <img key={index} alt='imageLateral'  src={`${img}`} onClick={() => setImgSel(img)} className={classNames({ 'border-primary': imgeSel === img },`w-full cursor-pointer border-2 border-round border-transparent transition-colors transition-duration-150`)} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                                            )
                                        }
                                    </div>
                                    <div className="pl-3 w-10">
                                        <img alt='imageCentral' src={`${imgeSel}`} className="w-full" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 lg:col-6 py-3 lg:pl-6">
                                <div className='grid'>
                                    <div className='col-10'>
                                        <div className="flex align-items-center text-4xl font-medium text-900 mb-4">{arrayIDP.data[0].nombre}</div>
                                    </div>
                                    <div className='flex col-2 justify-content-end'>
                                        <i className="flex align-items-center mb-4 pi text-3xl font-medium  cursor-pointer pi-heart text-900 mt-2" ></i>
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-between mb-5">
                                    <span className="text-900 font-medium text-4xl block">BOB. {arrayIDP.data[0].precio}</span>
                                    <div className="flex align-items-center justify-content-end ">
                                        <span className="mr-3 font-bold text-900 font-medium">{arrayIDP.data[0].rating}</span>
                                        <span >
                                            <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                                        </span>
                                        
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div className="flex align-items-center">
                                        <span className="font-bold text-900 font-medium text-base block">Código: <span className='text-500 text-base '>{arrayIDP.data[0].codigo}</span></span>
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div className="flex align-items-center">
                                        <span className="font-bold text-900 font-medium text-base block">Marca: <span className='text-500 text-base '>{arrayIDP.data[0].marca}</span></span>
                                    </div>
                                </div>      

                                <div className="font-bold text-900 mb-3">Tipo de Entrega</div>
                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div className="flex align-items-center">
                                        <span className="text-900 font-medium text-xs md:text-sm block"><FontAwesomeIcon icon={faTruck} className='text-xl md:text-2xl md:ml-3'/><span className='text-600 text-xs md:text-sm '> Disponible para envío a domicilio.</span></span>
                                    </div>
                                    
                                </div> 
                                <div className="flex align-items-center justify-content-between mb-5">
                                    <div className="flex align-items-center">
                                        <span className="text-900 font-medium text-xs md:text-sm block"><FontAwesomeIcon icon={faShop} className='text-xl md:text-2xl md:ml-3'/> <span className='text-600 text-xs md:text-sm '> Disponible para recojo en tienda.</span></span>
                                    </div>
                                    
                                </div> 

                                <div className="font-bold text-900 mb-3"><Link to={'/tienda/'+arrayIDP.data[0].bCategoria} className='no-underline cursor-pointer text-900'>#{arrayIDP.data[0].nombreCategoria}</Link> <Link to={'/tienda/'+arrayIDP.data[0].bCategoria+'/'+arrayIDP.data[0].bFamilia} className='no-underline cursor-pointer text-900'>#{arrayIDP.data[0].nombreFamilia}</Link> <Link to={'/tienda/'+arrayIDP.data[0].bCategoria+'/'+arrayIDP.data[0].bFamilia+'/'+arrayIDP.data[0].bLinea} className='no-underline cursor-pointer text-900'>#{arrayIDP.data[0].nombreLinea}</Link> <Link to={'/tienda/'+arrayIDP.data[0].bCategoria+'/'+arrayIDP.data[0].bFamilia+'/'+arrayIDP.data[0].bLinea+'/'+arrayIDP.data[0].bGP} className='no-underline cursor-pointer text-900'>#{arrayIDP.data[0].nombreGrupoProductos}</Link> </div>

                                <p className="p-0 mt-0 mb-5 line-height-3 text-700">
                                    {arrayIDP.data[0].descripcionCorta}
                                </p>

                                <div className="font-bold text-900 mb-3">Cantidad</div>
                                <div className="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between">
                                    <InputNumber showButtons="true" buttonLayout="horizontal"  min="0" inputClassName="w-3rem text-center"  value={'1'}
                                        decrementButtonClassName="p-button-text" incrementButtonClassName="p-button-text" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></InputNumber>
                                    <div className="flex align-items-center flex-1 mt-3 sm:mt-0 ml-0 sm:ml-5">
                                        <Button onClick={()=>añadirAlCarrito(arrayIDP.data[0].id)}  label="Añadir al Carrito" className="flex-1 mr-5 surface-900"></Button>   
                                    </div>
                                </div>

                            </div>

                        
                            <TabView>
                                <TabPanel header='Descripción'>
                                    <div className="text-900 font-medium text-3xl mb-4 mt-2">Descripción del Producto</div>
                                    <div className='grid grid-nogutter'>
                                        <div className="col-12">
                                            <div dangerouslySetInnerHTML={{__html: arrayIDP.data[0].descripcionLarga}} ></div>                                        
                                        </div>
                                    </div>

                                </TabPanel>
                                <TabPanel header='Características'>
                                    <div className="text-900 font-medium text-3xl mb-4 mt-2">Características del Producto</div>
                                    <div className='grid'>
                                        <div className='col-12'>
                                            <div className="card">
                                                <DataTable value={arrayIDP.data[1]} showGridlines responsiveLayout="scroll">
                                                    <Column field='nombreCaracteristica' header="Caracteristica"></Column>
                                                    <Column field='detalle' header="Detalle"></Column>
                                                    
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>

                                </TabPanel>
                            </TabView>

                            <Dialog header="Regístrate o Inicia Sesión" visible={displayPosition} position={'right'} modal={true} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => onHide()}
                                draggable={false} resizable={false}>
                                <p className="m-0">Que esperas para poder disfrutar de toda la tienda de Bersati.</p>
                                <br></br>
                                <p className="m-0 text-800">Si no tienes una cuenta <span className='text-900 font-semibold'>Regístrate</span>, si ya tienes una cuenta <span className='text-900 font-semibold'>Inicia Sesión</span></p>
                            </Dialog>

                        </div>
                    )
                    ):(        
                        <h1>No item found</h1>
                    )
            )
        }
    </>
  )
}

export default ProductoDetalle