/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import axios from "axios";

import './EditStyle.css'

const EditProductoText = () => {

    const [carac, setCarac] = useState(null);
    const [carac1, setCarac1] = useState(null);
    const [carac2, setCarac2] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loadingB, setLoadingB] = useState(false);
    const toast = useRef(null);

    const [productDialog, setProductDialog] = useState(false);
    const [productDialogNew, setProductDialogNew] = useState(false);

    const [val, setVal] = useState([]);
    const [error, setError] = useState(0);

    const [filters1, setFilters1] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        

    });
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'nombre': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'codigo': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      });

    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');


    const formik = useFormik({
        initialValues: {
          id: '',
          cp: '',
          accion:'',
          error: 0

      },onSubmit: (data) => {
            formik.values.error = 0
            formik.values.cp = val;
            for (let index = 0; index < val.length; index++) {
                if (val[index].nombreCaracteristica === '' || val[index].detalle === '') {
                    formik.values.error = 1
                    
                    break;
                }
            }
            if (formik.values.error > 0) {
                toast.current.show([
                    { severity: 'warn', summary: 'Atención!', detail: 'Uno o varios campos vacios.', life: 3000 },
                    { severity: 'error', summary: 'Acción Rechazada!', detail: 'Características no guardadas.', sticky: true }]);
            }else{
                if (formik.values.accion === 0) {
                    
                    guardarCaracteristicasNew(data)
                    setLoadingB(false)
                    hideDialogNew()

                }else if(formik.values.accion === 1){

                    guardarEditCaracteristicas(data)
                    setLoadingB(false)
                    hideDialog()

                }else{
                    toast.current.show( { severity: 'info', summary: '?', detail: 'No exsiste data para guardar!.', life: 3000 });
                }
            }
        }
      })
      const generarData = async () =>{
                
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/caracteristicas')

        setCarac(res.data)
    }
    

    useEffect(() => {
        setLoading(true)
            const generarData = async () =>{
                
                const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/caracteristicas')
    
                setCarac(res.data)
            }
            generarData()
    
          setLoading(false)
      }, []);

    const guardarEditCaracteristicas = async (data) => {
        setLoading(true)
        setLoadingB(true)

        const id = data.id;

        const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/save/guardarcaracteristicasedit/${id}`, data);

        //console.log(resSave)

        if (resSave.status === 200) {      
            toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Precio editado con éxito.', life: 3000 });
  
        }else {
            toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Precio NO editado.', life: 3000 });
        }

        generarData()
        setLoading(false)
        setExpandedRows([])
    }
    
    const guardarCaracteristicasNew = async (data) =>{
        
        setLoading(true)
        setLoadingB(true)
        
        const id = data.id;

        const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/save/guardarcaracteristicas/${id}`, data);

        console.log(resSave)

        if (resSave.status === 200) {      
            toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Precio editado con éxito.', life: 3000 });
  
        }else {
            toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Precio NO editado.', life: 3000 });
        }

        generarData()
        setLoading(false)
        setExpandedRows([])
        
    }

    const headerTemplate = (data) => {
      return (
          <React.Fragment>
                    <div className='flex align-content-center align-items-center' >
                    
                    <span className="ml-2 image-text  flex-grow-1 flex-shrink-1">{data.nombre} - {data.codigo}</span>
                        
                    </div>
                           
          </React.Fragment>
      );
    }



    const footerTemplate = (rowData) => {
        //console.log(rowData)
        return (
            <React.Fragment>
                
                <td colSpan="4" style={{ textAlign: 'right' }}>Total de Caracteristicas</td>
                <td>{calculateCustomerTotal(rowData.id)}</td>
                <td colSpan="4" style={{ textAlign: 'right' }}>Acciones</td>
                <td>
                    <div className='ml-2'>
                        {
                            rowData.nombreCaracteristica ?(
                                ''
                            )
                            :
                            (
                                <Button icon="pi pi-plus" placeholder="Left" tooltip="Nuevas Caracteristica" onClick={() => newProduct(rowData)} tooltipOptions={{position: 'bottom'}}  className="p-button-rounded p-button-success  p-button-sm p-1"  />
                            )
                        }
                        
                        <Button icon="pi pi-pencil" placeholder="Left" tooltip="Editar Caracteristica" onClick={() => editProduct(rowData)} tooltipOptions={{position: 'bottom'}}  className="p-button-rounded p-button-warning md:ml-1  p-button-sm p-1"  />
                    </div>
                </td>
                
            </React.Fragment>
        );
    }

    const newProduct = (rowData) =>{
        formik.values.id = rowData.id;
        formik.values.accion = 0;
        setProductDialogNew(true)
    }

    const editProduct = (rowData) => {
        setLoading1(true)
        setProductDialog(true)

        formik.values.id = rowData.id;
        formik.values.accion = 1;

        const generarData = async () =>{
            
            let varcitos = []
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+`/save/caracteristicas/${rowData.id}`)

            setCarac1(res.data)

            for (let index = 0; index < res.data.length; index++) {

                let itemus = []
                itemus = {
                    nombreCaracteristica : res.data[index].nombreCaracteristica,
                    detalle : res.data[index].detalle
                }
                
                varcitos.push(itemus)
                
            }

            setVal(varcitos)
            setLoading1(false)

        }
        generarData()



        
    }

    const calculateCustomerTotal = (id) => {
        let total = 0;

        if (carac) {
            for (let c of carac) {
                if (c.nombreCaracteristica === null) {
                    
                }else if (c.id === id) {
                    total++;
                }
            }
        }

        return total;
    }

    const hideDialog = () => {
        formik.resetForm()
        formik.values.accion = '';
        setCarac1(null)
        setVal([])
        setProductDialog(false);
      }

      const hideDialogNew = () => {
        formik.resetForm()
        formik.values.accion = '';
        //setCarac1(null)
        setVal([])
        setProductDialogNew(false);
      }

    const handleAdd= () =>{
        //console.log('entra')
        let varcitos = {
            nombreCaracteristica : '',
            detalle : ''
        }
        const abc = [...val,varcitos]
        
        setVal(abc)

    }

    const handleChange= (onChangeValue, i) =>{
        const inputData = [...val]
        inputData[i].nombreCaracteristica = onChangeValue.target.value;
        setVal(inputData)
    }

    const handleChange1= (onChangeValue, i) =>{
        const inputData = [...val]
        inputData[i].detalle = onChangeValue.target.value;
        setVal(inputData)
    }

   

    const handleDelete= (i) =>{
        const deleteVal = [...val]
        deleteVal.splice(i, 1)
        setVal(deleteVal)

        console.log(val)
    }

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['nombre'].value = value;
    
        setFilters2(_filters2);
        setGlobalFilterValue2(value);
      }

      const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['codigo'].value = value;
    
        setFilters2(_filters2);
        setGlobalFilterValue1(value);
      }

      

    const header = (
        <div className="table-header">
            
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Buscar por Nombre" />
            </span>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Buscar por Códgo"  className='mt-2 md:mt-0'/>
            </span>
        </div>
    );
      
    //console.log(val)
    //console.log(formik.values.id)

  return (
    <>
      <Toast ref={toast} />
      
      <div className='datatable-crud-demo w-full  h-full'>
        <div className="surface-section py-5 px-2 md:px-3 lg:px-5" style={{ height: 'calc(100vh - 88vh)' }}>
            <div className="border-bottom-1 surface-border">
                <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">LISTADO DE CARACTERÍSTICAS DE LOS PRODUCTOS</span>
            </div>
        </div>
        <div className="card  px-2 md:px-3 lg:px-5" style={{ height: 'calc(100vh - 23vh)' }}>
            <DataTable value={carac} rowGroupMode="subheader" showGridlines loading={loading} groupRowsBy="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                sortMode="single" sortField="nombre" sortOrder={1} responsiveLayout="scroll" scrollable size="small"
                expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} filters={filters2}
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Items" header={header} 
                rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate} >
                    
                <Column header="..." style={{ minWidth: '10rem' }}></Column>
                <Column field="nombreCaracteristica" header="Caracteristica" className='font-bold'></Column>
                <Column field="detalle" header="Detalle" ></Column>

            </DataTable>

            <Dialog visible={productDialog} style={{ width: '450px' }} header="Editar Características" modal className="p-fluid"  onHide={hideDialog}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    {
                        loading1 ? (
                            <div className='flex justify-content-center'>
                                <i className="pi pi-spin pi-spinner p-4" style={{'fontSize': '3em'}}></i>
                            </div>
                        )
                        :
                        (
                            carac1 ? (
                                carac1.length > 0 ? (
                                    <div className='field'>
                                        <Button label='Añadir' type="button" className='p-button-sm p-1 my-3' onClick={handleAdd} />
                                        <div className="field">
                                            
                                            <div className='grid w-full'>
                                                { 
                                                    val.map((data, i)=>(
                                                        <div key={i} className='col-12 '>
                                                            <div className='grid' >
                                                                <div  className='col-5'>
                                                                    <InputText value={data.nombreCaracteristica} onChange={e=>handleChange(e,i)} className='' />
                                                                </div>
    
                                                                <div  className='col-5'>
                                                                    <InputText value={data.detalle} onChange={e=>handleChange1(e,i)} className='ml-1 ' />
                                                                </div>
                                                                <div  className='col-2'>
                                                                    <Button type="button" label='X' className='p-button-sm m-1 p-1' onClick={() => handleDelete(i)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) ) 
                                                }
                                            </div>
                                            {
                                                val.length > 0 ? (
                                                    <div className='flex p-0 m-0 justify-content-end align-content-end mt-3 mb-0'>
                                                        {
                                                            loadingB ? 
                                                            (
                                                                <Button label="Guardando..." iconPos="right" loading />
                                                            )
                                                            :
                                                            (
                                                                <Button label='Guardar' type="submit" className='p-button-sm p-1 w-min p-button-success' />
                                                            )
                                                        }
                                                    </div>
                                                )
                                                :
                                                (
                                                    ""
                                                )
                                            }
                                        </div>
                                    </div>
                                   
                                       
    
                                ) 
                                : 
                                (
                                    ""
                                )
                            )
                            :
                            (
                                ""
                            ) 

                        )
                                         
                    }
                </form> 
            </Dialog>


            <Dialog visible={productDialogNew} style={{ width: '450px' }} header="Nuevas Características" modal className="p-fluid"  onHide={hideDialogNew}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className='field'>
                        <div className="field">
                            
                            <Button label='Añadir' type="button" className='p-button-sm p-1 my-3' onClick={handleAdd} />
                            
                            <div className='grid'>
                                { 
                                    productDialogNew ? (
                                        val.map((data, i)=>(
                                            <div key={i} className='col-12 '>
                                                <div className='grid' >
                                                    <div  className='col-5'>
                                                        <InputText value={data.nombreCaracteristica} onChange={e=>handleChange(e,i)} className='' />
                                                    </div>
    
                                                    <div  className='col-5'>
                                                        <InputText value={data.detalle} onChange={e=>handleChange1(e,i)} className='ml-1 ' />
                                                    </div>
                                                    <div  className='col-2'>
                                                        <Button type="button" label='X' className='p-button-sm mt-2 p-1 p-button-danger' onClick={() => handleDelete(i)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )) 
                                    )
                                    :
                                    (
                                        ""
                                    )
                                }
                            </div>

                                {
                                    val.length > 0 ? (
                                        <div className='flex p-0 m-0 justify-content-end align-content-end mt-3 mb-0'>
                                            {
                                                loadingB ? 
                                                (
                                                    <Button label="Guardando..." iconPos="right" loading />
                                                )
                                                :
                                                (
                                                    <Button label='Guardar' type="submit" className='p-button-sm p-1 w-min p-button-success' />
                                                )
                                            }
                                        </div>
                                    )
                                    :
                                    (
                                        ""
                                    )
                                }

                        </div>
                    </div>
                </form> 
            </Dialog>
        </div>
      </div>
    </>
  )
}

export default EditProductoText