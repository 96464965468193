/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import {useContext, useEffect, useRef, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import { StyleClass } from 'primereact/styleclass';
import { InputText } from 'primereact/inputtext';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { Tree } from 'primereact/tree';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Image } from 'primereact/image';

import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Badge } from 'primereact/badge';

import bersatiLogo from '../../../assets/img/Bersati1.png'
import bersatiLogo2 from '../../../assets/img/Bersati2.png'
import boBandera from '../../../assets/img/bo.svg'

import { obtenerNavbarAccion, obtenerNavbarAccionL, obtenerNavbarAccionC, obtenerNavbarAccionGP } from '../../../Redux/patos/navbarDuck'
import { verificarToken } from '../../../Redux/patos/userDuck'; 
//import { obtenerNavbarAccionC } from '../../../Redux/patos/storeCFLGP'
import { cartIndexFiveAccion } from '../../../Redux/patos/CartDuck';

import axios from "axios";

import { UserContext } from '../../../context/UserProvider';

import './Navbar.css'



const NavbarVisitors = () => {

    const {userLoading, userBer, userFire, signOutUser, setUserBer, setUserFire} = useContext(UserContext);
    //const imgLogo = userFire.photoURL;
    //revealRefs.current = [];
    //console.log(userBer)
    //console.log(imgLogo)
    const dispatch = useDispatch();
    
    const toggleBtnUser = useRef(null)
    const toggleBtnCart = useRef(null);
    const toggleBtn = useRef([]);
    const Btn1 = useRef(null);
    //console.log(itemEls)

    const location = useLocation();
    const navigate = useNavigate();
    
    const revealRefs = useRef([]);
    const revealRefs1 = useRef([]);
    const [refl, setrefl] = useState([]);
    const [refl1, setrefl1] = useState([]);
    const [visible, setVisible] = useState(false);

    const [banerRegis, setBanerRegis] = useState(true)

    useEffect(() => {
        const generarData = () =>{

            dispatch(obtenerNavbarAccionC())
            dispatch(obtenerNavbarAccion())
            dispatch(obtenerNavbarAccionL())
            dispatch(obtenerNavbarAccionGP())
            //dispatch(verificarToken(token))
        }
        generarData()
    },[dispatch])

    const navbarC = useSelector(store => store.navbarListaC)
    const navbar = useSelector(store => store.navbarLista)
    const navbarL = useSelector(store => store.navbarListaL)
    const navbarGP = useSelector(store => store.navbarListaGP)
    const cartFive = useSelector(store => store.cartFive)

    const {loadingToken, userToken} = useSelector((store) => store.userToken);


    const [menucito1, setMenucito1] = useState('');

    useEffect(() => {

        const generarMenucito = () =>{

            let menucito = [];

            if (navbarC.loadingC) {
                //console.log(navbarC.loadingC)
            }else{
                //console.log(navbarC.loadingC)
                try {

                    for (let index = 0; index < navbarC.arrayC.length; index++) {
                        let itemus = [];
                        for (let index1 = 0; index1 < navbar.array.length; index1++) {
                            if (navbar.array[index1].categoria_id === navbarC.arrayC[index].id) {

                                let itemus1 = [];
                                for (let index2 = 0; index2 < navbarL.arrayL.length; index2++) {
                                    if (navbarL.arrayL[index2].familias_id === navbar.array[index1].id) {
                                        
                                        let itemus2 = []
                                        for (let index3 = 0; index3 < navbarGP.arrayGP.length; index3++) {
                                            if (navbarGP.arrayGP[index3].lineas_id === navbarL.arrayL[index2].id) {

                                                let InToCuatro = {
                                                    key : navbarGP.arrayGP[index3].bGP,
                                                    label:<Link className='text-900 no-underline hover:text-400' to={`/tienda/${navbarC.arrayC[index].bCategoria}/${navbar.array[index1].bFamilia}/${navbarL.arrayL[index2].bLinea}/${navbarGP.arrayGP[index3].bGP}`}>{navbarGP.arrayGP[index3].nombreGrupoProductos}</Link>,
                                                    //icon:'pi pi-shopping-bag',
                                                }
                                                itemus2.push(InToCuatro)
                                            }
                                        }

                                        let InToTres = {
                                            key : navbarL.arrayL[index2].bLinea,
                                            label:<Link className='text-900 no-underline hover:text-400' to={`/tienda/${navbarC.arrayC[index].bCategoria}/${navbar.array[index1].bFamilia}/${navbarL.arrayL[index2].bLinea}`}>{navbarL.arrayL[index2].nombreLinea}</Link>,
                                            icon:'pi pi-shopping-bag',
                                            children: itemus2
                                        }
                                        itemus1.push(InToTres)
                                    }
                                }

                                let InToDos = {
                                    key : navbar.array[index1].bFamilia,
                                    label:<Link className='text-900 no-underline hover:text-400' to={`/tienda/${navbarC.arrayC[index].bCategoria}/${navbar.array[index1].bFamilia}`}>{navbar.array[index1].nombreFamilia}</Link>,
                                    icon:'pi pi-shopping-bag',
                                    children: itemus1
                                }
                                itemus.push(InToDos)   
                            } 
                        }
            
                        let inTo = {
                            key : index,
                            label:<Link className='text-900 no-underline hover:text-400' to={`/tienda/${navbarC.arrayC[index].bCategoria}`}>{navbarC.arrayC[index].nombreCategoria}</Link>,
                            //icon:'pi pi-fw pi-file',
                            
                            children: itemus
                        }
                        menucito.push(inTo)
                    }
                    const define = () =>{
                        setMenucito1(menucito)
                    }
        
                    define()
                    
                } catch (error) {
                    setMenucito1([])
                }
            }
        }

        generarMenucito();

    },[navbarC, navbar, navbarGP, navbarL]);

    /*console.log(userToken)
    if (userToken) {
        console.log(userToken.estado)
    }*/

    let menucito = []
    const addToRefs = (element, index) =>{

        if (element && !revealRefs.current.includes(element)) {
            let elemt = {
                current: element
            }
            
            //revealRefs.current[index] = element
            revealRefs.current.push(element)
            menucito.push(element)
            setrefl(menucito)

        }
        //console.log(refl)
        
    }

    /*let menucito1 = []
    const addToRefs1 = (element, index) =>{

        if (element && !revealRefs1.current.includes(element)) {
            let elemt = {
                current: element
            }
            
            //revealRefs.current[index] = element
            revealRefs1.current.push(element)
            menucito1.push(element)
            setrefl1(menucito1)

        }
        //console.log(refl)
        
    }*/

    const handleCerrarSesionVisitors = async () =>{
        try {
            let token = ''
            try {
                token = atob(localStorage.getItem('_vita'));
                //console.log(token)
            } catch (error) {
                localStorage.removeItem('_vita');
                localStorage.removeItem('_dolce');
                navigate('/login');
            }
            const tok = 'Bearer ' + token;

            var config = {
                method: 'post',
                url: process.env.REACT_APP_UNSPLASH_URL+'/logout',
                headers: { 
                  'Authorization': tok,
                }
              };
              await signOutUser();
            try {
                const res = await axios(config).catch((error)=>{
                    localStorage.removeItem('_vita');
                    localStorage.removeItem('_dolce');
                    
                    navigate('/login');
                })
                if (res.status) {
                    const estado = res.status
                    if (estado === 200) {
                        localStorage.removeItem('_vita');
                        localStorage.removeItem('_dolce');
                        setUserBer('')
                        setUserFire('')
                        navigate('/');
                    }else if(estado === 202){
                        localStorage.removeItem('_vita');
                        localStorage.removeItem('_dolce');
                        navigate('/login');
                    }
                }
            } catch (error) {
                localStorage.removeItem('_vita');
                localStorage.removeItem('_dolce');
                navigate('/login');
            }

            //const tok = 'Bearer ' + token;

        } catch (error) {
            console.log(error)
        }
    }

    const cabeza = <diV><h1>Hola</h1></diV>;
    

    const ary = 'box'
    
    const ary1 = '.box'


    const cerrarSesion = async () =>{
        let token = ''
        try {
            token = atob(localStorage.getItem('_uid'));
        } catch (error) {
            localStorage.removeItem('_uid');
            navigate('/admin/login');
        }
        
        const tok = 'Bearer ' + token;

        var config = {
            method: 'post',
            url: process.env.REACT_APP_UNSPLASH_URL+'/logout/',
            headers: { 
              'Authorization': tok
            }
          };
        
          //console.log(config)
          try {
            const res = await axios(config).catch((error)=>{
                localStorage.removeItem('_uid');
                navigate('/admin/login');
            })
            if (res.status) {
                const estado = res.status
                if (estado === 200) {
                    
                    localStorage.removeItem('_uid');
                    navigate('/');
                }else if(estado === 202){
                    localStorage.removeItem('_uid');
                    navigate('/admin/login');
                }
            }
          } catch (error) {
            localStorage.removeItem('_uid');
                navigate('/admin/login');
          }
       
        
        
        //console.log(res)
    }

    const VerificarTokenN = () =>{
        let token = ''
          try {
            token = atob(localStorage.getItem('_vita'));
            //console.log(token)
            return token;
          } catch (error) {
              localStorage.removeItem('_vita');
              localStorage.removeItem('_dolce');
              navigate('/login');
              signOutUser()
            return 401;
          }
        
    }

    /*useEffect(()=>{
        const VerificarTokenN = () =>{
            let token = ''
              try {
                token = atob(localStorage.getItem('_vita'));
                //console.log(token)
                return token;
              } catch (error) {
                  localStorage.removeItem('_vita');
                  localStorage.removeItem('_dolce');
                  navigate('/login');
                  signOutUser()
                return 401;
              }
            
        }
        const tokenV =  VerificarTokenN();
        const id = userBer.id;
        if (userBer && userFire) {
            const generarData = () =>{

                dispatch(cartIndexFiveAccion(tokenV, id))
            }
            generarData()
        }
    },[dispatch, userBer, userFire, signOutUser, navigate]) */

    const clisck = () =>{
        const tokenV =  VerificarTokenN();
        const id = userBer.id;
        if (userBer && userFire) {
            const generarData = () =>{

                dispatch(cartIndexFiveAccion(tokenV, id))
            }
            
            
            generarData()
        }
    }

    //console.log(cartFive)
    return (
        <>
        {location.pathname === '/' && (
            <div className='surface-section hidden lg:block z-0'>
                <div className="surface-900 px-3 lg:px-6 py-3 lg:py-3 flex sm:flex-row w-full justify-content-between align-items-center">
                    <span className="text-0">Regístrate para obtener un 15% de descuento en tu primer pedido.</span>
                    <a className="cursor-pointer h-full inline-flex align-items-center justify-content-end sm:mt-0 md:py-0">
                        <img src={boBandera} className="w-1" alt="Flag"></img>
                        <span className="text-0 px-1 lg:px-3">BOB.</span>
                    </a>
                </div>
            </div>
        )}
        

            
        <Sidebar visible={visible} header={cabeza} position="left" className="p-sidebar-sm" onHide={() => setVisible(false)}>
            <div className="overflow-y-auto flex-1">
                <ul className="list-none p-0 m-0" >
                    {
                         userBer && userFire ? (
                            <>
                                <li>
                                    <Link to={'/'} className="no-underline flex align-items-center cursor-pointer p-2 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors " style={{borderRadius: "12px"}}>
                                        <i className="pi pi-user mr-2 text-800"></i>
                                        <span className="font-medium">Usuario</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={handleCerrarSesionVisitors} className="no-underline flex align-items-center cursor-pointer p-2 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors " style={{borderRadius: "12px"}}>
                                        <i className="pi pi-sign-out mr-2 text-800"></i>
                                        <span className="font-medium">Cerrar Sesión</span>
                                    </Link>
                                </li>
                            </>
                         ):(
                            <>
                                <li>
                                    <Link to={'/login'} className="no-underline flex align-items-center cursor-pointer p-2 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors " style={{borderRadius: "12px"}}>
                                        <i className="pi pi-sign-in mr-2 text-800"></i>
                                        <span className="font-medium">Iniciar Sesión</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/register'} className="no-underline flex align-items-center cursor-pointer p-2 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors " style={{borderRadius: "12px"}}>
                                        <i className="pi pi-user-plus mr-2 text-800"></i>
                                        <span className="font-medium">Registrarse</span>
                                    </Link>
                                </li>
                            </>
                         )
                    }
                    
                    <li>
                        <Link to={'/paginacarrito'} className="no-underline flex align-items-center cursor-pointer p-2 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors " style={{borderRadius: "12px"}}>
                            <i className="pi pi-shopping-cart mr-2 text-800"></i>
                            <span className="font-medium">Mi Carrito</span>
                        </Link>
                    </li>
                    
                    <Divider />
                    <Tree className='min-h-full' value={menucito1} filter expandedKeys={'true'}  />

                </ul>
            </div>
        </Sidebar>
        <div className="z-4 surface-overlay justify-content-between px-4 lg:px-8 flex align-items-stretch relative border-bottom-1 surface-border h-6rem" >
            <div className="flex align-items-center justify-content-center">
                <Link to={'/'}><img src={bersatiLogo} alt="Logo" height="60" className="cursor-pointer hidden lg:inline mr-3 lg:mr-6"/></Link>
                <Link to={'/'}><img src={bersatiLogo2} alt="ImageLo" height="50" className="inline lg:hidden mr-3 lg:mr-6"/></Link>
                
            </div>
                
            <div className="hidden md:flex align-items-center justify-content-end flex-auto z-0">
                <div className="p-input-icon-left w-full md:w-6 lg:w-5 p-input-filled">
                    <i className="pi pi-search"></i>
                    <InputText type="text" className="w-full" placeholder="Buscar Producto"/>       
                </div>
            </div>
                
                
            <div className="flex z-5 ">
                <ul className="list-none p-0 m-0 flex">
                    {/*<li className="inline-flex relative z-2">
                        <StyleClass nodeRef={toggleBtnUser} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick="true">    
                            <a ref={toggleBtnUser} className="p-ripple text-900 font-medium inline-flex align-items-center cursor-pointer px-1 lg:px-3 mr-2 lg:mr-0 border-bottom-2 border-transparent hover:border-primary select-none">
                                <i className="pi pi-user text-xl"></i>
                                <span className="hidden">Mi Cuenta</span>
                                <Ripple />
                            </a>
                        </StyleClass>
                        <div className="hidden border-round surface-overlay p-3 shadow-2 absolute right-0 top-100 z-5 w-15rem origin-top">
                            <ul className="list-none p-0 m-0">
                                {
                                    loadingToken === true ? (
                                        <li>
                                            <p>Cargando....</p>
                                        </li>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                userToken ?(
                                                    
                                                    userToken.estado === 'true' ? (
                                                        <>
                                                        <li>
                                                            <Link to={'/protected/admin'} className="no-underline cursor-pointer text-700 hover:text-900 hover:surface-100 border-round flex align-items-center px-3 py-2">
                                                                <i className="pi pi-fw pi-book text-lg mr-2"></i>
                                                                <span>Administrador</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <a onClick={cerrarSesion} className="cursor-pointer text-700 hover:text-900 hover:surface-100 border-round flex align-items-center px-3 py-2">
                                                                <i className="pi pi-fw pi-sign-out text-lg mr-2"></i>
                                                                <span>Cerrar Sesión</span>
                                                            </a>
                                                        </li>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <li>
                                                            <a className="cursor-pointer text-700 hover:text-900 hover:surface-100 border-round flex align-items-center px-3 py-2">
                                                                <i className="pi pi-fw pi-box text-lg mr-2"></i>
                                                                <span>Iniciar Sesión</span>
                                                            </a>
                                                        </li>
                                                    )
                                                    
                                                    
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                            }
                                            
                                        </>
                                    )
                                }
                                
                            </ul>
                        </div>
                            </li> */}
                    <li className="relative z-2 float-right inline-flex"    >
                        <img className='hidden' src={userFire.photoURL} referrerPolicy='no-referrer' alt="" />
                        {   
                            userLoading ? (
                               <div className="inline-flex align-items-center justify-content-center border-bottom-2 border-transparent hover:border-800 text-700 ml-3">
                                    <ProgressSpinner style={{width: '30px', height: '30px'}} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s"/>
                               </div>
                            ):(
                                userBer && userFire ? (
                                   <>
                                        <StyleClass nodeRef={toggleBtnUser} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick="true">
                                            <a ref={toggleBtnUser} className="no-underline cursor-pointer inline-flex align-items-center justify-content-center border-bottom-2 border-transparent hover:border-800 text-700 ml-3">
                                                {
                                                    userFire.photoURL === null ? (
                                                        <Avatar icon="pi pi-user" className="inline-flex align-items-center align-content-center justify-content-center" size="large" shape="circle" />
                                                    ):(
                                                        
                                                        <Avatar image={userFire.photoURL} referrerPolicy='no-referrer'
                                                        onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                                        className="inline-flex align-items-center align-content-center justify-content-center" size="large" shape="circle" />
                                                    )
                                                }
                                            </a>
                                        </StyleClass>
                                        <div className="hidden border-round surface-overlay p-4 shadow-2 absolute right-0 top-100 z-1 w-20rem origin-top">
                                            <span className="text-900 font-medium mb-3 block">{userBer.name}</span>
                                            <div className="flex align-items-center surface-border ">
                                                {/*<img src="assets/images/blocks/ecommerce/shoppingcart/shopping-cart-2-2.png" alt='Logo?' className="w-4rem flex-shrink-0 shadow-1" />*/}
                                                <ul className="list-none p-0 m-0">
                                                    <li>
                                                        <a className="cursor-pointer text-700 hover:text-900 hover:surface-100 border-round flex align-items-center px-3 py-2">
                                                            <i className="pi pi-fw pi-cog text-lg mr-2"></i>
                                                            <span>Opciones de Usuario</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <Link to={'/paginacarrito'} className='no-underline cursor-pointer text-700 hover:text-900 hover:surface-100 border-round flex align-items-center px-3 py-2'>
                                                            
                                                                <i className="pi pi-fw pi-shopping-cart text-lg mr-2"></i>
                                                                <span>Mi Carrito</span>
                                                           
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to={'/miscompras'} className='no-underline cursor-pointer text-700 hover:text-900 hover:surface-100 border-round flex align-items-center px-3 py-2'>
                                                            
                                                                <i className="pi pi-fw pi-shopping-bag text-lg mr-2"></i>
                                                                <span>Mis Compras</span>
                                                       
                                                        </Link>
                                                    </li>
                                                    
                                                    <li>
                                                        <a onClick={handleCerrarSesionVisitors} className="cursor-pointer text-700 hover:text-900 hover:surface-100 border-round flex align-items-center px-3 py-2">
                                                            <i className="pi pi-fw pi-sign-out text-lg mr-2"></i>
                                                            <span>Cerrar Sesión</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/*<div className="flex pt-3">
                                                <Button  className="p-ripple p-button-outlined mr-2">View Cart <Ripple /> </Button>
                                                <Button  className="p-ripple ml-2">Purchase <Ripple /> </Button>
                                            </div>*/}
                                        </div>
                                   </>
                                   
                                ):(
                                    <Link to={'/login'} className="no-underline cursor-pointer inline-flex align-items-center justify-content-center border-bottom-2 border-transparent hover:border-800 text-700 ml-3">
                                        <i className="pi pi-user text-xl "> </i>
                                        <span  className=' ml-1 text-800 font-medium'>Iniciar Sesión</span>
                                    </Link>
                                )
                            )
                        }
                        
                    </li>
                    <li className="inline-flex justify-content-center relative px-4">
                        <StyleClass nodeRef={toggleBtnCart}  selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick="true">
                            <a ref={toggleBtnCart} onClick={clisck} className="p-ripple text-900 font-medium inline-flex justify-content-center  align-items-center cursor-pointer w-3rem border-bottom-2 border-transparent hover:border-800 select-none">
                                {
                                    
                                }
                                <i className="pi pi-shopping-cart text-xl p-overlay-badge"><Badge value={0} ></Badge></i>
                                <span className="hidden">Mi Carrito</span>
                                <Ripple />
                            </a>
                        </StyleClass>
                        {
                            userLoading ? (
                                <div className='hidden border-round surface-overlay p-4 shadow-2 absolute right-0 top-100 z-1 w-20rem origin-top'>
                                    <p>Cargando...</p>
                                </div>
                            ):(

                                userBer && userFire ? (   
                                    <div className="hidden border-round surface-overlay p-4 shadow-2 absolute right-0 top-100 z-1 w-20rem origin-top">
                                        {
                                            cartFive.loadingCartF || (cartFive.loadingCartF === null) ? (
                                                <p>Cargando...</p>
                                            ):(
                                                <>
                                                    {
                                                        cartFive.arrayCartF.length > 1 ? (
                                                            <>
                                                                <span className="text-900 font-semibold block mb-1">Mi Carrito ({cartFive.arrayCartF[1].length} Item(s))</span>
                                                            {
                                                                cartFive.arrayCartF[0].map((cartF,index)=>(
                                                                    cartF.producto_promo.length > 0 ? (
                                                                        <div key={index} className="flex align-items-center mt-3 border-bottom-1 surface-border pb-3">
                                                                            <img src={`${cartF.imagenPrincipal}`} alt={cartF.nombre} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-4rem flex-shrink-0 shadow-1" />
                                                                            <div className="flex flex-column pl-3">
                                                                                <Badge className='text-xs' value="OFERTA" severity="success"></Badge>
                                                                                <span className="text-900 font-medium mb-2">{cartF.nombre} </span>
                                                                                
                                                                                <span className="text-600 mb-2">{cartF.codigo}</span>
                                                                                {
                                                                                    cartF.producto_promo[0].descuentoNumber > 0 ? (
                                                                                        <span className="text-900 font-bold">BOB. <span className='line-through font-normal'>{cartF.precio}</span> <span className='font-bold'>{(cartF.precio - (cartF.precio * (cartF.producto_promo[0].descuentoNumber / 100))).toFixed(2)}</span> </span>
                                                                                    ):(
                                                                                        <span className="text-900 font-bold">BOB. <span>{cartF.precio}</span> </span>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    ):(

                                                                        <div key={index} className="flex align-items-center mt-2 border-bottom-1 surface-border pb-3">
                                                                            <img src={`${cartF.imagenPrincipal}`} alt={cartF.nombre} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-4rem flex-shrink-0 shadow-1" />
                                                                            <div className="flex flex-column pl-3">
                                                                                <span className="text-900 font-medium mb-2">{cartF.nombre}</span>
                                                                                <span className="text-600 mb-2">{cartF.codigo}</span>
                                                                                <span className="text-900 font-bold">BOB. {cartF.precio}</span>
                                                                            </div>
                                                                        </div>
                                                                    )
    
                                                                ))
                                                            }
                                                            <div className="flex pt-3">
                                                                <Link to={'/paginacarrito'} className='no-underline'>
                                                                    <Button  className="p-ripple p-button-outlined mr-2 text-900 hover:surface-hover">Ver Carrito <Ripple /> </Button>
                                                                </Link>
                                                                <Button  className="p-ripple ml-2 surface-900">Comprar <Ripple /> </Button>
                                                            </div>
                                                            </>
                                                        ):(
                                                            ""
                                                        )
                                                    }
                                                    
                                                </>
                                            )
                                        }
                                        
                                    </div>
                                
                                ):(
                                    <div className='hidden border-round surface-overlay p-4 shadow-2 absolute right-0 top-100 z-1 w-20rem origin-top'>
                                        <span className="text-900 font-medium block">Inicia Sesión para ver tu carrito.</span>
                                    </div>                               
                                )
                            )
                            
                        }
                        
                    </li>
                </ul>
            </div>
        </div>

        <div className="surface-overlay px-4 lg:px-8 flex align-items-stretch relative border-bottom-1 surface-border z-3 h-5rem">
            <div className="flex justify-content-center">
                <ul className="list-none p-0 m-0 flex">
                    <li className="flex ">
                        <a  className="cursor-pointer inline-flex align-items-center justify-content-center border-bottom-2 border-transparent hover:border-800 text-700 mr-3" onClick={(e) => setVisible(true)}>
                            <i className="pi pi-bars text-4xl "> </i>
                            <span className='ml-1 font-medium'>Menú</span>
                        </a>
                    </li>
                    {
                        navbar.loading ? (
                            <>
                                <li className="hidden md:flex lg:flex mt-4">
                                    <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                </li>
                                <li className="hidden md:flex lg:flex mt-4 ml-3">
                                    <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                </li>
                                <li className="hidden md:flex lg:flex mt-4 ml-3">
                                    <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                </li> 
                                <li className="hidden md:flex lg:flex mt-4 ml-3">
                                    <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                </li>
                                <li className="hidden md:flex lg:flex mt-4 ml-3">
                                    <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                </li>
                            </>
                            
                        )
                        :
                        (
                            navbarC.arrayC.map((navcate, index) => (

                                <li key={index} className=" hidden md:flex lg:flex">  

                                            <StyleClass nodeRef={refl[index]} selector={`#${navcate.bCategoria}`} enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick="true">
                                                
                                                <a key={index} ref={(element) => addToRefs(element, index)} className="p-ripple font-medium inline-flex align-items-center cursor-pointer border-bottom-2 border-transparent hover:border-800 px-3 text-700 select-none">
                                                    <span>{navcate.nombreCategoria}</span>
                                                    <Ripple />
                                                </a>
                                            </StyleClass>


                                    <div id={`${navcate.bCategoria}`} className={` surface-overlay shadow-2 hidden absolute w-full left-0 top-100 z-4`}>
                                        <div className="flex flex-wrap justify-content-between p-4 lg:p-6">
                                            {
                                                
                                                navbarC.loadingC ? (
                                                    <ul className="list-none px-3 lg:px-0 py-0 m-0 text-700 mb-5">
                                                        <li className="font-bold mb-5 text-xl cursor-pointer text-900" > Cargando... </li>
                                                    </ul>
                                                )
                                                :
                                                (
                                                

                                                    navbar.array.map((lista, index)=>(
                                                        lista.categoria_id === navcate.id ? 
                                                            <ul key={index} className="list-none px-3 lg:px-0 py-0 m-0 text-700 mb-5">
                                                                <li key={index} className="font-bold mb-5 text-xl cursor-pointer text-900" > <Link className='font-bold text-900 no-underline' to={`/tienda/${navcate.bCategoria}/${lista.bFamilia}`} >{lista.nombreFamilia}</Link> </li>

                                                                {
                                                                    navbar.loading ? (
                                                                        <li className="mb-3 cursor-pointer hover:text-400"> Cargando.... </li>
                                                                    )
                                                                    :
                                                                    (
                                                                        navbarL.arrayL.map((listaL, index) => (
                                                                            listaL.familias_id === lista.id ? 
                                                                                <li key={index} className="mb-3 cursor-pointer hover:text-400"> <Link className='text-700 hover:text-400 no-underline' to={`/tienda/iluminacion/${lista.bFamilia}/${listaL.bLinea}`} >{listaL.nombreLinea}</Link> </li>
                                                                            :
                                                                            ''
                                                                        ))
                                                                    )
                                                                }

                                                            </ul>
                                                        :
                                                        ""        
                                                    )) 
                                                    
                                                )
                                            }
                                        </div>
                                    </div>  
                                </li>
                                
                            ))
                        )
                    }
                    
                    

                    {/* navbar.loading ? (
                        <p>Cargando...</p>
                    )
                    :
                    (
                        navbar.array.map((nav, index) => (
                            //<p key={index} ref={(element) => itemEls.current.push(element)}>{item.hola}</p>
                            
                                
                                <li key={index} className="flex">
                                    <StyleClass nodeRef={revealRefs.current[index]} selector='@next' enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick="true">
                                        <a ref={addToRefs} className="p-ripple font-medium inline-flex align-items-center cursor-pointer border-bottom-2 border-transparent hover:border-primary px-3 text-700 select-none">
                                            <span>{nav.nombreFamilia}</span>
                                            <Ripple />
                                        </a>
                                        
                                    </StyleClass>
                                    
                                    <div className="surface-overlay shadow-2 hidden absolute w-full left-0 top-100 z-3">
                                        <div className="flex flex-wrap justify-content-between p-4 lg:p-6">
                                            <ul className="list-none px-3 lg:px-0 py-0 m-0 text-700 mb-5">
                                                <li className="font-bold mb-5 text-xl cursor-pointer text-900">{nav.nombreFamilia}</li>
                                                <li className="mb-3 cursor-pointer hover:text-400">Esculturas</li>
                                                <li className="mb-3 cursor-pointer hover:text-400">Cuadros</li>
                                            </ul>
                    
                                        </div>
                                    </div>
                                </li>
                
                        ))
                    )
                        */ }
                    
                </ul>
            </div>
            <div className='flex justify-content-end flex-auto z-0 md:hidden lg:hidden'>
                <ul className="list-none p-0 m-0 flex">
                    {
                        userLoading ? (
                            ''
                        ):(
                            userBer && userFire ? (
                                <div className="flex align-items-center justify-content-end flex-auto z-0">
                                    <div className="p-input-icon-left w-full md:w-6 lg:w-5 p-input-filled">
                                        <i className="pi pi-search"></i>
                                        <InputText type="text" className="w-full" placeholder="Buscar Producto"/>       
                                    </div>
                                </div>
                            ):(
                                <>
                                    <li className="flex ">
                                    <Link  to={'/login'} className="hidden no-underline cursor-pointer md:inline-flex align-items-center justify-content-center border-bottom-2 border-transparent hover:border-800 text-700 mr-3" >
                                        <span className='ml-1 font-medium'>Iniciar Sesión</span>
                                    </Link>
                                    </li> 
                                    <li className="flex ">
                                        <Link to={'/register'} className="no-underline cursor-pointer inline-flex align-items-center justify-content-center border-bottom-2 border-transparent hover:border-800 text-700 mr-3" >
                                            <span className='ml-1 font-medium'>Registrarse</span>
                                        </Link>
                                    </li> 
                                </>
                            )
                        )
                    }
                </ul>
            </div>
        </div>

        
        {
            userBer && userFire ? (
                ''
            ):(
                banerRegis ? (
                    <div className="bg-bluegray-900 text-gray-100 px-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
                        {/*<div className="font-bold mr-8">🔥 Hot Deals!</div> */}
                        <div className="flexs align-items-center mr-8">
                            <span className="line-height-3">No pierdas las ofertas regístrate.</span>
                            <Link to={'/register'} className="underline text-white font-bold ml-1 cursor-pointer">AHORA!</Link>
                        </div>
                        <a onClick={()=>{setBanerRegis(false)}} className="flex align-items-center no-underline justify-content-center border-circle text-gray hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150" style={{ width: '2rem', height: '2rem' }}>
                            <i className="pi pi-times"></i>
                        </a>
                    </div>
                ):(
                    ''
                )
            )

        }

        </>
    )
}

export default NavbarVisitors