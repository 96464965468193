/* eslint-disable no-unused-vars */
import React, {useContext, useState, useEffect, useRef} from 'react'

import { classNames } from 'primereact/utils';
import { Steps } from 'primereact/steps';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';

import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';


import axios from 'axios';
import { useFormik } from 'formik';

import { tipoEnvioAccion, departamentosAccion } from '../../../../Redux/patos/FormDuck';
import { PaymentContext } from '../../../../context/PaymentProvider';

const SelectInfoUsuario = ({datosPersonalesA, loadingDP}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUsuarioId, setTipoEnvio, setUserMap, setNacional, setLocal, productosFinal } = useContext(PaymentContext);

  const [datosPersonales, setDatosPersonales] = useState(datosPersonalesA)
  const [seleccionado, setSeleccionado] = useState(0);
  const [seleccionadoData, setSeleccionadoData] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [city, setCity] = useState(null);
  const [depart, setDepart] = useState(null);
  const [data, setData] = useState([])
  const [dpEstado, setDpEstado] = useState(0);

  const toast = useRef(null);
  
  /*const itemSteps = [
    {label: 'Formulario'},
    {label: 'Pago'},
    {label: 'Confirmación'}
  ]; */

  const itemSteps = [
    {label: 'Carrito'},
    {label: 'Formulario y Pago'},
    {label: 'Confirmación'}
  ];

  //console.log(loadingDP)
  //console.log(datosPersonales)
  const storeTipoEnvio = useSelector(store => store.storeTipoEnvio)
  const {arrayDepartamentos, loadingDepartamentos} = useSelector(store => store.storeDepartamentos)


  useEffect(() => {
    const generarData = () =>{

        dispatch(tipoEnvioAccion())
        dispatch(departamentosAccion())

        
    }
    generarData()

  },[dispatch])

  useEffect(() =>{
    console.log(productosFinal)
    if (productosFinal.length > 0) {
    }else{
      navigate('/paginacarrito')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const formik = useFormik({
    initialValues: {
      nombres: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      celular: null,     
      ci: null,
      correo: '',

      user_id: '',
     // dpEstado: false,
      ciudad:'',
    },
    validationSchema: Yup.object({
      
      nombres: dpEstado === '1' ? Yup.string().min(3, 'Mínimo tres caracteres.').required('El campo es Requerido'):Yup.string(),
      
      apellidoPaterno: dpEstado === '1' ?  Yup.string().min(3, 'Mínimo tres caracteres.').required('El campo es Requerido'):Yup.string(),

      celular: dpEstado === '1' ?  Yup.string().nullable().required('Ingrese su Número de Celular.'):Yup.string().nullable(),

      ci: dpEstado === '1' ?  Yup.string().nullable().required('Ingrese su Docuemnto de Identidad.'):Yup.string().nullable(),

      ciudad: city === 2 ? Yup.string().min(3, 'Mínimo tres caracteres.').required('El campo es Requerido') : Yup.string(),

    }),
    onSubmit: (data) => {

      console.log('entraForm')

      if (city === 1) {
        setUsuarioId(datosPersonales[0].user_id);
        setTipoEnvio(city);
        setUserMap(seleccionadoData);
        setNacional('')
        setLocal('')
        navigate('/payment');
      }else if (city === 2){
        let datita = {
          'departamento' : depart,
          'ciudad': data.ciudad
        }
        //console.log(datita)
        setUsuarioId(datosPersonales[0].user_id);
        setTipoEnvio(city);
        setUserMap('');
        setNacional(datita)
        setLocal('')
        navigate('/payment');
      }else if (city === 3){
        setUsuarioId(datosPersonales[0].user_id);
        setTipoEnvio(city);
        setUserMap('');
        setNacional('')
        setLocal(1)
        navigate('/payment');
      }else{
        GuardarDatosPersonales(data);
      }

    }
  });  

  useEffect(()=>{
    setData(arrayDepartamentos)
  },[arrayDepartamentos])

  const ActualizarDatosPersonales = async()=>{
    try {
      const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/datosconsulta/'+datosPersonales[0].user_id);
      //console.log(res)
      setDatosPersonales(res.data)
    } catch (error) {
      
    }
  }
  const GuardarDatosPersonales = async (data) =>{
    setLoadingBtn(true)
    try {
      const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/datosdomicilioformu/`+data.user_id, data);
      //console.log(resSave)

      if (resSave.status === 200) {     
        toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
        setLoadingBtn(false)
        ActualizarDatosPersonales()
        handleCloseDialog()
       
      }else {
          setLoadingBtn(false)
          toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
      }

    } catch (error) {
      setLoadingBtn(false)
      handleCloseDialog()
      toast.current.show({ severity: 'error', summary: 'ERR 501!', detail: 'Error al Intentar Guardar ', life: 3000 });
    }
    
    
    
  }

  const handleSelected = (val) =>{
    //console.log(val)
    setSeleccionado(val.id)
    setSeleccionadoData(val)
  }

  const handleOpenDialog = () =>{
    setDialog(true)
    formik.values.nombres =  datosPersonales[0].nombres;
    formik.values.apellidoPaterno =  datosPersonales[0].apellido_p;
    formik.values.apellidoMaterno =  datosPersonales[0].apellido_m;
    formik.values.celular =  datosPersonales[0].celular;     
    formik.values.ci =  datosPersonales[0].ci;
    formik.values.correo =  datosPersonales[0].correo;
    formik.values.user_id = datosPersonales[0].user_id; 
    setDpEstado(1);
  }
  const handleCloseDialog = () =>{
    formik.values.nombres =  '';
    formik.values.apellidoPaterno =  '';
    formik.values.apellidoMaterno =  '';
    formik.values.celular =  '';     
    formik.values.ci =  '';
    formik.values.correo =  '';
    formik.values.user_id = '';
    setDpEstado(0);

    setDialog(false)
  }

  const handleSubmit = async () =>{
        
    if (city === 1) {
        console.log('entraaHandle')
        formik.submitForm();
    }

    if (city === 2) {
        //console.log('entraaHandle')
        formik.submitForm();
    }

    if (city === 3) {
        formik.submitForm();
    }
}

  const onDepaChange = (e) => {
    //console.log(e.value)
    setDepart(e.value);
  }

  //console.log(productosFinal)

  const EnvioNacionalFragment = (
    <React.Fragment>

      <div className='flex w-full'>
        <div className="ml-0 lg:ml-5 p-2 w-full">
            <div className="mb-3">
              <span className="text-900 text-xl block ml-2">Datos de Entrega Envío Nacional</span>
            </div>
            
            <div  className='flex flex-column border-round surface-border border-1 p-4 transition-duration-150'>
              <div className="grid flex-column lg:flex-row">
                <div className="col-6 field mb-3">
                    <label htmlFor="departamento" className="text-900 font-medium mb-3">*Departamento</label>
                    {
                        loadingDepartamentos ? (
                            <p>Cargando...</p>
                        ):(
                            <Dropdown id='departamento' value={depart} optionValue='id' options={data} onChange={onDepaChange} optionLabel="departamento" placeholder="Selecciona un elemento" />
                        )
                    }
                </div>
                
                <div className='col-6 field'>
                    <label htmlFor="ciudad" className="text-900 font-medium mb-3">*Ciudad</label>
                    <InputText id="ciudad" type="text" value={formik.values.ciudad} onChange={formik.handleChange}  placeholder='ej. Ciudad de Sucre, Camiri, etc..' className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.ciudad && formik.touched.ciudad })}/>
                    { formik.errors.ciudad && formik.touched.ciudad ? (
                            <p className='p-error p-0 m-0'>{formik.errors.ciudad}</p>
                        ) : null }
                </div>

                <div className='col-12 lg:col-12 field mt-2 '> 
                    {
                        depart !== null && formik.values.ciudad !== '' ?(
                            <Button type='button' loading={loadingBtn} onClick={handleSubmit} label='Proceder a Pagar' className='surface-900'></Button>
                        ):(
                            ''
                        )
                    }
                </div>
              </div>
              
            </div>

        </div>
      </div>
    </React.Fragment>
  )

  const RecojoEnLocalFragment = (
    <React.Fragment>
        <div className='col-12 lg:col-12 field mt-2 '>
            <Button type='button' loading={loadingBtn} onClick={handleSubmit} label='Proceder a Pagar' className='surface-900'></Button>
        </div>
    </React.Fragment>
  )

  const EnvioDomicilioFragment = (
    <React.Fragment>
      <div className='flex w-full'>
        <div className="ml-0 lg:ml-5 p-2 w-full">
            <div className="mb-3">
                <span className="text-900 text-xl block ml-2">Mis Ubicaciones</span>
            </div>
            
            <div className="grid flex-column lg:flex-row">
              {
                datosPersonales[0].mapas_cliente.length > 0 ? (

                  datosPersonales[0].mapas_cliente.map((ubi, index) => (

                    <div key={index} className="col p-1 ">
                      <div className={classNames('flex  flex-column  border-round border-1  p-4 cursor-pointer hover:border-400 transition-duration-150',{ 'border-900':  (seleccionado === ubi.id), 'surface-border': (seleccionado !== ubi.id)})}>
                          <div className='h-8rem'>
                            <div className="flex justify-content-between mb-3">
                                <span className="text-900 text-xl font-medium">{ubi.direccion}</span>
                                <span className="text-600 font-medium ml-4" onClick={() => console.log('hola')}><i className="pi pi-pencil mr-2"></i>Editar</span>
                            </div>
                            <span className="inline-block text-600 mb-3">{'Zona: '+ubi.zona}</span>
                            {
                              ubi.referencia === null ? null:
                              <span className="inline-block text-600 mb-3">{'Referencia: '+ubi.referencia}</span>
                            }
                          </div>
                          <div className='flex justify-content-end align-content-end '> 
                            <span className="flex align-items-center justify-content-center text-600" onClick={() => handleSelected(ubi)}><i className={classNames('font-bold mr-2 transition-duration-150',{ 'pi pi-check-circle':  (seleccionado === ubi.id)})}></i>{(seleccionado === ubi.id) ? 'Deseleccionar' : 'Seleccionar'}</span>
                          </div>
                      </div>
                    </div>
                  ))
                ):null
              }
                <div className="col-12 lg:col-3 p-1 h-13rem">
                    <div className="flex h-full flex-column justify-content-center align-items-center py-5 lg:py-0 border-round border-1 surface-border surface-100 cursor-pointer hover:border-400 transition-duration-150">
                        <span className="text-600 border-circle border-2 w-2rem h-2rem flex align-items-center justify-content-center"><i className="pi pi-fw pi-plus"></i></span>
                        <span className="text-600 mt-3">Añadir Nueva Dirección</span>
                    </div>
                </div>
              <div className='col-12 mt-2 p-0'> 
                  {
                      seleccionado !== 0 ?(
                          <Button type='button' loading={loadingBtn} onClick={handleSubmit} label='Proceder a Pagar' className='surface-900'></Button>
                      ):(
                          ''
                      )
                  }
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <>
    <Toast ref={toast} />
      <div className=" px-4 py-8 md:px-6 lg:px-8">
        <div className='mb-2'>
            <Steps model={itemSteps} activeIndex={1} /> 
        </div>
        <div className="grid -mr-3 -ml-3">
          <div className="col-12 lg:col-8 p-4">
            <div className="surface-card grid formgrid p-fluid border-round shadow-3 p-4">
              
              <div className="flex w-full">
                {/*<div className="flex flex-column align-items-center ml-3" style={{width:"2rem"}}>
                    <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{minWidth:"3rem", minHeight: "3rem"}}>
                        <i className="pi pi-info"></i>
                    </span>
                    <div className="h-full border-dashed border-1 border-green-500" style={{minHeight: "12rem"}}></div>
                  </div> */}

                <div className="ml-0 lg:ml-5 p-2 w-full">
                    <div className="mb-3">
                        <span className="text-900 font-semibold text-2xl block ml-2">Datos Personales</span>
                    </div>
                    <div className="grid flex-column lg:flex-row">
                        <div className="col p-1">
                            <div  className='flex flex-column border-round surface-border border-1 p-4  hover:border-400 transition-duration-150'>
                                <div className="flex justify-content-between mb-3">
                                    <span className="text-900 text-xl font-normal">{datosPersonales[0].nombres+' '+datosPersonales[0].apellido_p+' '+datosPersonales[0].apellido_m}</span>
                                    <span className="text-600 font-medium ml-4 cursor-pointer" onClick={() => handleOpenDialog()}><i className="pi pi-pencil mr-2"></i>Editar</span>
                                </div>
                                <span className="inline-block text-600 mb-3"> <span className='text-900'>Documento: </span>{datosPersonales[0].ci}</span>
                                <span className="inline-block text-600 mb-3"><i className="pi pi-envelope mr-2"></i>{datosPersonales[0].correo}</span>
                                <span className="inline-block text-600 mb-3"><i className="pi pi-mobile mr-2"></i>{datosPersonales[0].celular}</span>
                                
                            </div>
                        </div>

                    </div>
                </div>
              </div>

              <div className='flex w-full'>
                <div className='ml-0 lg:ml-5 p-2 w-full'>
                    <div className="mb-3">
                        <span className="text-900 font-semibold text-2xl block ml-2">Datos de Facturación</span>
                    </div>
                    <div className="grid flex-column lg:flex-row">
                      <div className="col p-1">
                        <div className='flex flex-column border-round surface-border border-1 p-4  hover:border-400 transition-duration-150'>
                          <p className='p-0 m-0'>Su compra se facturará al nombre y número de documento con el que usted está realizando su pedido.</p>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <div className='flex w-full'>
                {/*<div className="flex flex-column align-items-center ml-3" style={{width:"2rem"}}>
                    <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{minWidth:"3rem", minHeight: "3rem"}}>
                        <i className="pi pi-image"></i>
                    </span>
                    <div className="h-full border-dashed border-1 surface-border" style={{minHeight: "12rem"}}></div>
                </div>*/ }
                <div className='ml-0 lg:ml-5 p-2 w-full'>
                  <div className="mb-3">
                      <span className="text-900 font-semibold text-2xl block ml-2">Tipo de Envío</span>
                  </div>
                  <div className="grid flex-column lg:flex-row">
                    <div className="col p-1">
                      <div className='flex flex-column align-content-center border-round surface-border border-1 p-4  hover:border-400 transition-duration-150'>
                          {
                              storeTipoEnvio.loadingTipoEnvio ? (
                                  <p>Cargando...</p>
                              ):(
                                  storeTipoEnvio.arrayTipoEnvio.map((tipoEnvio, index)=>(
                                      <div key={index} className="field-radiobutton my-2">
                                          <RadioButton inputId="city1" name="city" value={tipoEnvio.id} onChange={(e) => setCity(e.value)} checked={city === tipoEnvio.id} />
                                          <label htmlFor="city1">{tipoEnvio.tipo_envio}</label>
                                      </div>
                                  ))
                              )
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                {
                  city === 1 ? ( EnvioDomicilioFragment ):null
                }
                {
                  city === 2 ? ( EnvioNacionalFragment ):null
                }
                {
                  city === 3 ? ( RecojoEnLocalFragment ):null
                }

            </div>
          </div>

          <div className='col-12 md:col-4 lg:col-4 p-4'>
              <div className="surface-card grid formgrid p-fluid border-round shadow-3 p-4">
                  <h1>Carrito <span className='font-bold'> <i className="pi pi-shopping-cart " style={{'fontSize': '20px'}}></i></span></h1>
                    {

                      productosFinal.length > 0 ?(
                        productosFinal.map((prod,index) => (
                          <div key={index} className='col-12 p-0 m-0'>
                            <div className="flex flex-column lg:flex-row flex-wrap lg:align-items-center border-bottom-1 surface-border">
                              {
                                  prod.producto_promo[0] ? (
                                      <img src={`${prod.producto_promo[0].imagenPP}`} alt={prod.nombre} onError={(e) => e.target.src=`${prod.imagenPrincipal}`} className="w-8rem h-8rem flex-shrink-0 mb-3 mt-3" />
                                  ):(
                                      <img src={`${prod.imagenPrincipal}`} alt={prod.nombre} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-8rem h-8rem flex-shrink-0 mb-3 mt-3" />
                                  )
                              }
                              <div className="flex-auto lg:ml-3">
                                  <div className="flex align-items-center justify-content-between mb-3">
                                      <span className="text-900 font-medium">{prod.nombre}</span>
                                      <span className="text-900 font-bold">BOB. {prod.precio}</span>
                                  </div>
                                  <div className="text-600 text-sm mb-3">{prod.codigo} | {prod.marca}</div>
                              </div>
                            </div>
                          </div>
                        ))
                      ):null
                      
                    }
              </div>
          </div>
        </div>
      </div>

      <Dialog visible={dialog} style={{ width: '450px' }} header="Editar Datos Personales" modal className="p-fluid" onHide={handleCloseDialog}>
        <form onSubmit={formik.handleSubmit} className="">
          <div className="field">
            <label htmlFor="nombres" className="text-900 font-medium mb-3">*Nombres</label>
            <InputText id="nombres" value={formik.values.nombres} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.nombres && formik.touched.nombres })}/>
            { formik.errors.nombres && formik.touched.nombres ? (
                    <p className='p-error p-0 m-0'>{formik.errors.nombres}</p>
                ) : null }
          </div>
          <div className='field'>
            <label htmlFor="apellidoPaterno" className="text-900 font-medium mb-3">*Apellido Paterno</label>
            <InputText id="apellidoPaterno" value={formik.values.apellidoPaterno} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.apellidoPaterno && formik.touched.apellidoPaterno })}/>
            { formik.errors.apellidoPaterno && formik.touched.apellidoPaterno ? (
                    <p className='p-error p-0 m-0'>{formik.errors.apellidoPaterno}</p>
                ) : null }
          </div>
          <div className='field'>
            <label htmlFor="apellidoMaterno" className="text-900 font-medium mb-3">Apellido Materno</label>
            <InputText id="apellidoMaterno" value={formik.values.apellidoMaterno} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className="p-inputtext w-full mb-3" />
          </div>
          <div className='field'>
            <label htmlFor="celular" className="text-900 font-medium mb-3">*Celular</label>
            <InputNumber id="celular" name="celular" mode="decimal" value={formik.values.celular} onBlur={formik.handleBlur}  onValueChange={formik.handleChange} useGrouping={false} type="text"  className={classNames(' w-full',{ 'p-invalid':  formik.errors.celular && formik.touched.celular })}/>
            { formik.errors.celular && formik.touched.celular ? (
                    <p className='p-error p-0 m-0'>{formik.errors.celular}</p>
                ) : null }
          </div>
          <div className='field'>
            <label htmlFor="ci" className="text-900 font-medium mb-3">*Número de Identidad</label>
            <InputNumber id="ci" name="ci" type="text" mode="decimal" value={formik.values.ci} onBlur={formik.handleBlur} onValueChange={formik.handleChange} useGrouping={false} className={classNames(' w-full',{ 'p-invalid':  formik.errors.ci && formik.touched.ci })} />
            { formik.errors.ci && formik.touched.ci ? (
                    <p className='p-error p-0 m-0'>{formik.errors.ci}</p>
                ) : null }
          </div>
          <div className='field'>
            <label htmlFor="email" className="text-900 font-medium mb-3">*Email</label>
            <InputText id="email" value={formik.values.correo} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className="p-inputtext w-full mb-3" />             
          </div>
          <div className='field'>
            <Button type='submit' loading={loadingBtn} label='Guardar Datos' className='surface-900'></Button>
          </div>
        </form>
      </Dialog>
    </>
  )
}

export default SelectInfoUsuario