/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import axios from "axios";
import { json } from "react-router-dom";


//CONSTANTES

const dataInicial = {
    array : [],
    loading : '',
};



//TYPES

const OBTENER_PRODUCTOSINDEX_EXITO = 'OBTENER_PRODUCTOSINDEX_EXITO'
const OBTENER_PRODUCTOSINDEX_REQUEST = 'OBTENER_PRODUCTOSINDEX_REQUEST'




//REDUCERS

export function productosIndexReducer(state = dataInicial, action){
    switch (action.type) {
        case OBTENER_PRODUCTOSINDEX_REQUEST:
            return { loading: true, array:[] };
        case OBTENER_PRODUCTOSINDEX_EXITO:
            return {...state, array: action.payload, loading: false};       
        default:
            return state
    }
}





//ACCIONES

export const obtenerProductosIndexAccion = () => async (dispatch, getState) => {
    
    if (sessionStorage.getItem('productosIndex')) {
        dispatch({
            type: OBTENER_PRODUCTOSINDEX_EXITO,
            payload: JSON.parse(sessionStorage.getItem('productosIndex'))
        })
    }

    try {
        dispatch({type:OBTENER_PRODUCTOSINDEX_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/storeindex')
        dispatch({
            type: OBTENER_PRODUCTOSINDEX_EXITO,
            payload: res.data
        })

        sessionStorage.setItem('productosIndex', JSON.stringify(res.data));
        
    } catch (error) {
        console.log(error)
    }
}