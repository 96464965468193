/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { classNames, ConnectedOverlayScrollHandler } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ProductService } from './service/ProductService';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';


import { useFormik } from 'formik';

import './categoriasStyle.css';


import axios from "axios";

const Familias = () => {

    let emptyProduct = {
        id: null,
        nombreFamilia: '',
        image: null,
        descripcion: '',
        bFamilia: null,
        categoria_id: '',
    };

    let empyCategoria = {
        id: null,
        nombreCategoria: '',
    };

    const [products, setProducts] = useState(null);
    const [categorias, setCategorias] = useState(null);
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [productDialogEdit, setProductDialogEdit] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    //const [loading1, setLoading1] = useState(false);

    const [imgURL, setImgURL] = useState("");
    const [loading, setLoading] = useState(false);

    const fileUploadRef = useRef(null);

    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [filters1, setFilters1] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);
    //const productService  = new ProductService();

    useEffect(() => {
        setLoading(true)
        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/familias')
            const resCate = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/categorias')

            setProducts(res.data)
            setCategorias(resCate.data)
        }
        generarData()

        setLoading(false)
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues : {
            id:'',
            nombreFamilia: '',
            image: '',
            descripcion: '',
            bFamilia: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.nombreFamilia) {
                errors.nombreCategoria = 'Este campo es requerido.';
            }

            if (!data.descripcion) {
                errors.descripcion = 'Este campo es requerido.';
            }

            if (!data.bFamilia) {
                errors.bCategoria = 'Este campo es requerido.';
            }

            return errors;
        },
        onSubmit: (data) => {
            try {
                setBoton(true)
                if (formik.values.id) {
                    //console.log('Entra al edit')
                    data.categoria_id = selectedCategoria;
                    guardarEditProducto(data)
                   
                    limpieza()
                    setImgURL("")
                }else{
                    data.image = imgURL;
                    data.categoria_id = selectedCategoria;
                    guardarProducto(data)  
                    limpieza()
                    setImgURL("")
                }
            } catch (error) {
                setBoton(false)
                limpieza()
                setImgURL("")
            }

        }
    });


    const openNew = () => {
        //setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        formik.resetForm()
        setSelectedCategoria("");
        setProductDialog(false);
    }

    const hideDialogEdit = () => {
        formik.resetForm()
        formik.values.id = ""
        formik.values.nombreFamilia = ""
        formik.values.descripcion = ""
        formik.values.bFamilia = ""
        formik.values.image = ""
        setSelectedCategoria('')
        setProductDialogEdit(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const editProduct = (product) => {
        
        setProduct({...product});
    
        //setSelectedCategoria(product.categoria_id)
        formik.values.id = product.id
        formik.values.nombreFamilia = product.nombreFamilia
        if (product.descripcion === null) {
            formik.values.descripcion = ''
        }else{
            formik.values.descripcion = product.descripcion
        }       
        formik.values.bFamilia = product.bFamilia
        if (product.image === null) {
            formik.values.image = ''
        }else{
            formik.values.image = product.image
        }

        //formik.values.categoria_id = product.categoria_id
        
        //console.log(selectedCategoria)
        setProductDialogEdit(true);
        //console.log(product)
    }

    /* Eliminar un soloProducto*/
    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
        
    }

    const deleteProduct = async () => {
        setLoading(true)
        
        if (product.id) {
            //console.log(product)
            const resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/save/eliminarfamilia/${product.id}`)
    
            //console.log(resSave)
            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                setProduct(null);
                setDeleteProductDialog(false);

            }else if (resSave.status === 400) {
                setDeleteProductDialog(false);
                setProduct(null);
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
            }
        }

        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/familias')

            setProducts(res.data)
        }

        generarData()


        setLoading(false)
    }
    /**********************************  */

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const deleteSelectedProducts = () => {
        let _products = products.filter(val => !selectedProducts.includes(val));  // Aqui se eliminan los productos
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Operación Exitosa', detail: 'Categorias Eliminadas', life: 3000 });
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = {...product};
        _product[`${name}`] = val;

        setProduct(_product);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nueva Familia" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                {/*<Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const imageBodyTemplate = (rowData) => {
        return <img src={`${rowData.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="w-5rem" />
    }
    const todos = (rowData) => {
        //console.log(datita)
        setSelectedCategoria(rowData.categoria_id)
        //console.log(selectedCategoria)
        editProduct(rowData)
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex'>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-1" onClick={() => todos(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
                </div>
            </React.Fragment>
        );
    }


    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    

    const header = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Administrador de Familias</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Buscar..." />
            </span>
        </div>
    );

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );
    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

    const btnRefInput = useRef();

    
    const [boton, setBoton] = useState(false)

    const datosIniciales = {
        nombreCategoria: '',
        image: '',
        descripcion: '',
        bCategoria: '',
    }
    
    //console.log(product.nombreCategoria)
    
    

    const guardarProducto = async (data) => {
        setLoading(true)
        
        if (data.nombreFamilia.trim()) {
            console.log(data)
            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/save/familia', data)
    
            
            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                formik.resetForm()
                setBoton(false)
                setSelectedCategoria(null)
                setProductDialog(false)   
            }else if (resSave.status === 400) {
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                setBoton(false)
            }
        }

        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/familias')

            setProducts(res.data)
        }

        generarData()


        setLoading(false)
    }

    const guardarEditProducto = async (data) => {
        setLoading(true)
        
        if (data.id) {
            //console.log(data)
            const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/save/editfamilia/${data.id}`, data)
    
            //console.log(resSave)
            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                formik.resetForm()
                setSelectedCategoria(null)
                setBoton(false)
                hideDialogEdit()
            }else if (resSave.response.status === 400) {
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                setBoton(false)
                hideDialogEdit()
            }
        }

        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/familias')

            setProducts(res.data)
        }

        generarData()


        setLoading(false)
    }


    //console.log(formData)

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                
                {cancelButton}
                {uploadButton}
                
            </div>
        );
    }
     
    const myUploader = async (event) => {
        // convert file to base64 encoded

        //console.log('ingresa')
        const file = event.files[0];
        //console.log(file)

        const data = new FormData();
        data.append('file', file);
        data.append('upload_preset', 'bersatimg')
        data.append('cloud_name', 'ddnbi0nfz')
    
        const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
            method: 'post',
            body: data,

        }) //blob:url
        //setImgURL(blob)
        const fileImg = await res.json();

        //console.log(fileImg);
        setImgURL(fileImg.secure_url)
        formik.values.image = fileImg.secure_url
    }
   
    /*const subir = async () =>{
        btnRefInput.current.upload() 
    }*/
    const limpieza = () =>{
        btnRefInput.current.clear();
        //console.log(btnRefInput)
    }

    const onCategoriaChange = (e) => {
        setSelectedCategoria(e.value);
        //console.log(e.value)
    }
  return (
    <>
        <div className="datatable-crud-demo w-full  h-full ">
            <Toast ref={toast} />

            <div className="card " >
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Familias"
                    filters={filters1} header={header} scrollable scrollHeight="flex" >
                        
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                    <Column field="id" header="ID" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="nombreFamilia" header="Familia" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="image" header="Imagen" body={imageBodyTemplate}></Column>
                    <Column field="descripcion" header="Descripción" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="bFamilia" header="Abr" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field='nombreCategoria' header="Categoria" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={productDialog} style={{ width: '450px' }}  header="Nueva Familia" modal className="p-fluid" onHide={hideDialog}>
                {/*product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" /> */}
                <form onSubmit={formik.handleSubmit} className="p-fluid">

                    <div className="field">
                        <label htmlFor="nombreFamilia" className={classNames({ 'p-invalid': isFormFieldValid('nombreFamilia') })} >Nombre Familia</label>
                        <InputText id="nombreFamilia" value={formik.values.nombreFamilia} onChange={formik.handleChange}  autoFocus className={classNames({ 'p-invalid': isFormFieldValid('nombreFamilia') })} />
                        {getFormErrorMessage('nombreFamilia')}
                    </div>

                    <div className="field">
                        <label htmlFor="descripcion" className={classNames({ 'p-invalid': isFormFieldValid('descripcion') })}>Descripción</label>
                        <InputTextarea id="descripcion" value={formik.values.descripcion} onChange={formik.handleChange}  rows={3} cols={20} className={classNames({ 'p-invalid': isFormFieldValid('descripcion') })}/>
                        {getFormErrorMessage('descripcion')}
                    </div>

                    <div className="field">
                        <label htmlFor="bFamilia" className={classNames({ 'p-invalid': isFormFieldValid('bFamilia') })}>Simplificado</label>
                        <InputText id="bFamilia" value={formik.values.bFamilia} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('bFamilia') })} />
                        {getFormErrorMessage('bFamilia')}
                    </div>
                    <div className='field'>
                        <label htmlFor="categoria_id" className={classNames({ 'p-invalid': isFormFieldValid('categoria_id') })}>Categoria</label>
                        <Dropdown id="categoria_id"  value={selectedCategoria} optionValue="id" options={categorias} onChange={onCategoriaChange} optionLabel="nombreCategoria" placeholder="Selecciona una Categoria..." />
                        {getFormErrorMessage('categoria_id')}
                    </div>

                    <div className="field">
                        <label htmlFor="image" className={classNames({ 'p-invalid': isFormFieldValid('image') })}>URL de la Imagen</label>
                        <InputText id="image" value={imgURL} disabled  onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('image') })} />
                        {getFormErrorMessage('image')}
                    </div>

                    <Divider className='p-3' />

                   <span className='text-color-secondary text-xs my-1'>*Primero debe subir la imagen y luego guardar los datos...</span> 
                    <div className='field my-1'>
                        <FileUpload ref={btnRefInput} name="demo[]" headerTemplate={headerTemplate} url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' chooseLabel='Escoger' cancelLabel='Cancelar'  accept="image/*" maxFileSize={1000000}
                        customUpload='true' uploadHandler={myUploader} 
                        emptyTemplate={<p className="m-0">Arrastre y suelte los archivos aquí para cargarlos.</p>} 
                        />
                    </div>
                    
                    

                    <div className='flex'>
                        
                    
                    
                        {
                             boton ? (
                                <Button label="Cancelar" type="button" loading={loading} icon="pi pi-times" disabled className="p-button-rounded p-button-danger" onClick={hideDialog} />
                             )
                             :
                             (
                                <Button label="Cancelar" type="button" loading={loading} icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={hideDialog} />
                             )
                        }
                        {
                            boton ? (  
                                <Button label="Guardar Datos" type="button" loading={loading} icon="pi pi-check" disabled  className="p-button-rounded p-button-success" />
                            )
                            :
                            (
                                imgURL ? (
                                    <Button label="Guardar Datos" type="submit" loading={loading} icon="pi pi-check" className="p-button-rounded p-button-success" />
                                )
                                :
                                (
                                    ""
                                )
                            )
                        }
                        
                    </div>
                    
                </form>


            </Dialog>

            <Dialog visible={productDialogEdit} style={{ width: '450px' }}  header="Editar Familia" modal className="p-fluid" onHide={hideDialogEdit}>
                {/*product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" /> */}
                <form onSubmit={formik.handleSubmit} className="p-fluid">

                    <div className="field">
                        <label htmlFor="nombreFamilia" className={classNames({ 'p-invalid': isFormFieldValid('nombreFamilia') })} >Nombre Familia</label>
                        <InputText id="nombreFamilia" value={formik.values.nombreFamilia} onChange={formik.handleChange}  autoFocus className={classNames({ 'p-invalid': isFormFieldValid('nombreFamilia') })} />
                        {getFormErrorMessage('nombreFamilia')}
                    </div>

                    <div className="field">
                        <label htmlFor="descripcion" className={classNames({ 'p-invalid': isFormFieldValid('descripcion') })}>Descripción</label>
                        <InputTextarea id="descripcion" value={formik.values.descripcion } onChange={formik.handleChange}  rows={3} cols={20} className={classNames({ 'p-invalid': isFormFieldValid('descripcion') })}/>
                        {getFormErrorMessage('descripcion')}
                    </div>

                    <div className="field">
                        <label htmlFor="bFamilia" className={classNames({ 'p-invalid': isFormFieldValid('bFamilia') })}>Simplificado</label>
                        <InputText id="bFamilia" value={formik.values.bFamilia } onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('bFamilia') })} />
                        {getFormErrorMessage('bFamilia')}
                    </div>

                    <div className='field'>
                        <label htmlFor="categoria_id" className={classNames({ 'p-invalid': isFormFieldValid('categoria_id') })}>Categoria</label>
                        <Dropdown id="categoria_id"  value={selectedCategoria} optionValue="id" options={categorias} onChange={onCategoriaChange} optionLabel="nombreCategoria" placeholder="Selecciona una Categoria..." />
                        {getFormErrorMessage('categoria_id')}
                    </div>

                    <div className="field">
                        <label htmlFor="image" className={classNames({ 'p-invalid': isFormFieldValid('image') })}>URL de la Imagen</label>
                        <InputText id="image" value={formik.values.image} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('image') })} />
                        {getFormErrorMessage('image')}
                    </div>

                    <Divider className='p-3' />

                   <span className='text-color-secondary text-xs my-1'>*Primero debe subir la imagen y luego guardar los datos...</span> 
                    <div className='field my-1'>
                        <FileUpload ref={btnRefInput} name="demo[]" headerTemplate={headerTemplate} url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' chooseLabel='Escoger' cancelLabel='Cancelar' multiple accept="image/*" maxFileSize={1000000}
                        customUpload='true' uploadHandler={myUploader} 
                        emptyTemplate={<p className="m-0">Arrastre y suelte los archivos aquí para cargarlos.</p>} 
                        />
                    </div>
                    
                    

                    <div className='flex'>
                        
                    
                    
                        {
                             boton ? (
                                <Button label="Cancelar" type="button" icon="pi pi-times" disabled className="p-button-rounded p-button-danger" onClick={hideDialogEdit} />
                             )
                             :
                             (
                                <Button label="Cancelar" type="button" icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={hideDialogEdit} />
                             )
                        }
                        {
                            boton ? (  
                                <Button label="Guardar Datos"  icon="pi pi-check" loading={loading} disabled  className="p-button-rounded p-button-success" />
                            )
                            :
                            (
                                <Button label="Guardar Datos" type="submit" loading={loading}  icon="pi pi-check" className="p-button-rounded p-button-success" />
                            )
                        }
                        
                    </div>
                    
                </form>


            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {product && <span>Estas seguro de eliminar la familia <b>{product.nombreFamilia}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {product && <span>Estas seguro de eliminar las categorias seleccionadas?</span>}
                </div>
            </Dialog>

        </div>
    </>
  )
}

export default Familias