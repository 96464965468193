
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Navigate } from "react-router-dom";
import { Outlet, useLocation } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import { verificarToken } from '../Redux/patos/userDuck'; 

const RequireAuth = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    
    const userToken = useSelector((store) => store.userToken);
    //console.log(userToken)
    const [token, setToken] = useState(null)
    const [error1, setError1] = useState(false)
    const [exito, setExito] = useState(false)
    const [con, setCon] = useState(false)

    //console.log(userToken)



    /*useEffect(()=>{
        console.log('entraaaa a Token')
        try {
            if (atob(localStorage.getItem('_uid')) === 'ée') {
                console.log('1')
                setError1(true)
                setToken(null)
                
            }else if(atob(localStorage.getItem('_uid'))) {
                setToken(atob(localStorage.getItem('_uid')))
                //console.log(token)
            }else{
                console.log('2')
                localStorage.removeItem('_uid')
                setError1(true)
                //return <Navigate to="/admin/login" />
            }



            if (userToken.loadingToken === false) {
        
                if (userToken.userToken) {
                    
                    if (userToken.userToken.estado === 'true') {
                        //console.log('entró')
                        setExito(true)
                        
                    }else{
                        if (userToken.userToken.estado === 'false') {
                            console.log('4')
                            setExito(false)
                            localStorage.removeItem('_uid')
                            setError1(true)
                        }
                        
                    }
                   
                }else if(userToken.error){
                    if (userToken.error.estado) {
                        
                        console.log('5')
                        localStorage.removeItem('_uid')
                        return <Navigate to="/admin/login" />;
                    }
                    //localStorage.removeItem('_uid')
                    //return <Navigate to="/admin/login" />;
                }
            }
            
        } catch (error) {
            console.log('3')
            localStorage.removeItem('_uid')
            setError1(true)
            //return <Navigate to="/admin/login" />
        }
        
    }, [children, userToken.error, userToken.loadingToken, userToken.userToken])
    
   useEffect(() =>{

        const generarData = () =>{
            dispatch(verificarToken(token))
        }
        if (token !== null) {
            console.log('entra...')
            generarData();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, token])

    */

    useEffect(()=>{
        try {
            if (atob(localStorage.getItem('_uid')) === 'ée') {
                console.log('1')
                setError1(true)
                setToken(null)
                
            }else if(atob(localStorage.getItem('_uid'))) {
                setToken(atob(localStorage.getItem('_uid')))
                //console.log(token)
            }else{
                console.log('2')
                localStorage.removeItem('_uid')
                setError1(true)
                //return <Navigate to="/admin/login" />
            }

        } catch (error) {
            console.log('3')
            localStorage.removeItem('_uid')
            setError1(true)
            //return <Navigate to="/admin/login" />
        }
    },[])

    useEffect(()=>{
        
        const generarData = () =>{
            dispatch(verificarToken(token))
        }
        if (token !== null) {
            //console.log('entra dispatch...')
            generarData();
            setCon(true)
        }
    },[dispatch, token])


    useEffect(()=>{
        if(con){
            //console.log(userToken)
            if (userToken.userToken) {
                if (userToken.userToken.estado === 'true') {
                    setExito(true)
                }else if (userToken.userToken.estado === 'false') {
                    setExito(false)
                    localStorage.removeItem('_uid')
                    setError1(true)
                }
            }
        }
    },[con, userToken])

    if (location.pathname === '/protected'){
        return <Navigate to="/" />;
    }
    if (error1) {
        return <Navigate to="/admin/login" />
    }

    if (exito) {
        return children;
    }

    

    
    
}

export default RequireAuth