/* eslint-disable default-case */
/* eslint-disable no-unused-vars */


import axios from "axios";


//CONSTANTES

const dataInicialCompras = {
    arrayCompras : [],
    loadingCompras : true,
    error: ''
};



const dataInicialVentaPC = {
    arrayVentaPC : [],
    loadingVentaPC : true,
    errorVPC: ''
};

const dataInicialVentaPC2= {
    arrayVentaPC2 : [],
    loadingVentaPC2 : true,
    errorVPC2: ''
};
const dataInicialVentaPC3 = {
    arrayVentaPC3 : [],
    loadingVentaPC3 : true,
    errorVPC3: ''
};


//TYPES

const OBTENER_COMPRASUSER_EXITO = 'OBTENER_COMPRASUSER_EXITO'
const OBTENER_COMPRASUSER_REQUEST = 'OBTENER_COMPRASUSER_REQUEST'
const OBTENER_COMPRASUSER_ERROR = 'OBTENER_COMPRASUSER_ERROR'



const OBTENER_VENTAPC_EXITO = 'OBTENER_VENTAPC_EXITO'
const OBTENER_VENTAPC_REQUEST = 'OBTENER_VENTAPC_REQUEST'
const OBTENER_VENTAPC_ERROR = 'OBTENER_VENTAPC_ERROR'

const OBTENER_VENTAPC2_EXITO = 'OBTENER_VENTAPC2_EXITO'
const OBTENER_VENTAPC2_REQUEST = 'OBTENER_VENTAPC2_REQUEST'
const OBTENER_VENTAPC2_ERROR = 'OBTENER_VENTAPC2_ERROR'

const OBTENER_VENTAPC3_EXITO = 'OBTENER_VENTAPC3_EXITO'
const OBTENER_VENTAPC3_REQUEST = 'OBTENER_VENTAPC3_REQUEST'
const OBTENER_VENTAPC3_ERROR = 'OBTENER_VENTAPC3_ERROR'



//REDUCERS

export function comprasUserIndexReducer (state = dataInicialCompras, action){
    switch (action.type) {
        case OBTENER_COMPRASUSER_REQUEST:
            return { loadingCompras: true, arrayCompras:[] };
        case OBTENER_COMPRASUSER_EXITO:
            return {...state, arrayCompras: action.payload, loadingCompras: false}; 
        case OBTENER_COMPRASUSER_ERROR:
            return {error: action.payload, loadingCompras: false};       
        default:
            return state
    }
}

export function ventasPCReducer (state = dataInicialVentaPC, action){
    switch (action.type) {
        case OBTENER_VENTAPC_REQUEST:
            return { loadingVentaPC: true, arrayVentaPC:[] };
        case OBTENER_VENTAPC_EXITO:
            return {...state, arrayVentaPC: action.payload, loadingVentaPC: false}; 
        case OBTENER_VENTAPC_ERROR:
            return {errorVPC: action.payload, loadingVentaPC: false};       
        default:
            return state
    }
}
export function ventasPC2Reducer (state = dataInicialVentaPC2, action){
    switch (action.type) {
        case OBTENER_VENTAPC2_REQUEST:
            return { loadingVentaPC2: true, arrayVentaPC2:[] };
        case OBTENER_VENTAPC2_EXITO:
            return {...state, arrayVentaPC2: action.payload, loadingVentaPC2: false}; 
        case OBTENER_VENTAPC2_ERROR:
            return {errorVPC2: action.payload, loadingVentaPC2: false};       
        default:
            return state
    }
}
export function ventasPC3Reducer (state = dataInicialVentaPC3, action){
    switch (action.type) {
        case OBTENER_VENTAPC3_REQUEST:
            return { loadingVentaPC3: true, arrayVentaPC3:[] };
        case OBTENER_VENTAPC3_EXITO:
            return {...state, arrayVentaPC3: action.payload, loadingVentaPC3: false}; 
        case OBTENER_VENTAPC3_ERROR:
            return {errorVPC3: action.payload, loadingVentaPC3: false};       
        default:
            return state
    }
}



//ACCIONES


export const comprasUserIndexAccion = (id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_COMPRASUSER_REQUEST})

        /*console.log(token)
        console.log(data)*/
        //const tok = 'Bearer ' + token;
        //console.log(tok)
        /*const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //const res = await axios(config)

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/miscomprasuser/'+id)
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_COMPRASUSER_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}

export const ventasPCIndexAccion = () => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_VENTAPC_REQUEST})

        /*console.log(token)
        console.log(data)*/
        //const tok = 'Bearer ' + token;
        //console.log(tok)
        /*const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //const res = await axios(config)

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/pagosporconfirmar')
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_VENTAPC_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}
export const ventasPCI2ndexAccion = () => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_VENTAPC2_REQUEST})

        /*console.log(token)
        console.log(data)*/
        //const tok = 'Bearer ' + token;
        //console.log(tok)
        /*const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //const res = await axios(config)

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/pagosporconfirmar2')
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_VENTAPC2_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}
export const ventasPCI3ndexAccion = () => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_VENTAPC3_REQUEST})

        /*console.log(token)
        console.log(data)*/
        //const tok = 'Bearer ' + token;
        //console.log(tok)
        /*const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //const res = await axios(config)

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/pagosporconfirmar3')
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_VENTAPC3_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}