/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import {useContext, useState, useEffect, useRef, useImperativeHandle} from 'react'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import * as Yup from 'yup';


import { GMap } from 'primereact/gmap';

//import { loadGoogleMaps, removeGoogleMaps } from './GoogleMaps';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import { Link } from 'react-router-dom';


import './EnvioDomicilio.css';
const EnvioDomicilio = ({ formik }) => {

 const infoWindow = useRef(null);

    const [selectedPosition, setSelectedPosition] = useState(null);
    const [overlays, setOverlays] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [draggableMarker, setDraggableMarker] = useState(false);
    const [markerTitle, setMarkerTitle] = useState('');
    const [googleMapsReady, setGoogleMapsReady] = useState(false);
    const [position, setPosition] = useState(null)

    const formRef = useRef();

   /* const formik = useFormik({
        initialValues:{

            direccion: '',
            zona: '',
            referencia: '',
            user_id:'',
            latitud:'',
            longitud:''

        },
        validationSchema: Yup.object({

            direccion: Yup.string()
                .min(3, 'Mínimo tres caracteres.')
                .required('El campo es Requerido'),
            zona: Yup.string()
                .min(3, 'Mínimo tres caracteres.')
                .required('El campo es Requerido'),
            latitud: Yup.string()
                .required('Añade una ubicación en el mapa.'),
            longitud: Yup.string()
                .required('Añade una ubicación en el mapa.'),
        }),
        onSubmit: async (data)=>{
            //parentCallback(estado);
            parentCallback(1);
            console.log(data)
        }
    })*/


    /*useEffect(() => {
        loadGoogleMaps(() => {
            setGoogleMapsReady(true);
        });

        return () => {
            removeGoogleMaps();
        }
    },[])*/

    useEffect(() => {
        formik.values.formA.direccion = '';
        formik.values.formA.zona = '';
        formik.values.formA.referencia = '';
        formik.values.formA.latitud = '';
        formik.values.formA.longitud = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onMapClick = (event) => {
        setDialogVisible(true);
        setSelectedPosition(event.latlng)
    }

    const options = {
        center: {lat: -17.78387457963673, lng: -63.18185806274414},
        zoom: 12
    };


    const onHide = (event) => {
        setDialogVisible(false);
    }

    const addMarker = () => {
        if (overlays.length > 0) {
            console.log('Error Solo Una Ubicación...')
            setDialogVisible(false);
        }else{
            let newMarker = new google.maps.Marker({
                                position: {
                                    lat: selectedPosition.lat(),
                                    lng: selectedPosition.lng()
                                },
                                title: markerTitle,
                                draggable: false
                            });
    
            setOverlays([...overlays, newMarker]);
            setDialogVisible(false);
            setDraggableMarker(false);
            setMarkerTitle('');
        }
    }

    const footer = <div>
        <Button label="Añadir" className='surface-900' icon="pi pi-check" onClick={()=> ''} />
        <Button label="Cancelar" icon="pi pi-times" onClick={onHide} />
    </div>;


    const handleDeleteMaps = () =>{
        setOverlays([])
    }


    
    
    /*useImperativeHandle(refId, () => ({
        Submit: async () => {

            await formik.submitForm();
            
            
        }
      }));*/

      
    function LocationMarker() {
        
        const map = useMapEvents({
          dblclick(e) {
            //console.log(e.latlng.lat)
            setPosition(e.latlng)
            //hab(e.latlng)
            formik.values.formA.latitud = e.latlng.lat;
            formik.values.formA.longitud = e.latlng.lng;
          },
          locationfound(e) {
          },
        })
      
        return position === null ? null : (
          <Marker position={position} draggable={true}>
            <Popup>Ubicación Marcada</Popup>
          </Marker>
        )
      }

    //console.log(formik)
  return (
    <>
        <div className="col-12 mt-4 lg:mt-0 mb-4">
            <div className="text-900 font-medium text-xl">Datos de Entrega</div>
        </div>
        <div className="col-12 field mb-3">
            <label htmlFor="direccion" className="text-900 font-medium mb-3">*Dirección</label>
            <InputText id="direccion" type="text" name='formA.direccion' value={formik.values.formA.direccion} onBlur={formik.handleBlur} onChange={formik.handleChange} className={classNames('p-inputtext w-full',{ 'p-invalid':   formik.errors.formA && formik.touched.formA ? (formik.errors.formA.direccion && formik.touched.formA.direccion):null })} />
                {   formik.errors.formA && formik.touched.formA ? (
                        formik.errors.formA.direccion && formik.touched.formA.direccion ? (
                            <p className='p-error p-0 m-0'>{formik.errors.formA.direccion}</p>
                        ):null
                    ):null
                }
        </div>
        <div className="col-12 lg:col-6 field mb-3">
            <label htmlFor="zona" className="text-900 font-medium mb-3">*Zona</label>
            <InputText id="zona" name='formA.zona' type="text" value={formik.values.formA.zona}  onBlur={formik.handleBlur}  onChange={formik.handleChange} className={classNames('p-inputtext w-full',{ 'p-invalid':   formik.errors.formA && formik.touched.formA ? (formik.errors.formA.zona && formik.touched.formA.zona):null })}/>
                {   formik.errors.formA && formik.touched.formA? (
                        formik.errors.formA.zona && formik.touched.formA.zona? (
                            <p className='p-error p-0 m-0'>{formik.errors.formA.zona}</p>
                        ):null
                    ):null
                }
        </div>
        <div className="col-12 lg:col-6 field mb-2">
            <label htmlFor="referencia" className="text-900 font-medium mb-3">Referencia</label>
            <InputText id="referencia" name='formA.referencia' type="text" value={formik.values.formA.referencia} onChange={formik.handleChange} className="p-inputtext w-full mb-3" />
        </div>

        <div className="col-12 mb-6">
            <label htmlFor="referencia" className="text-900 font-medium mb-2 p-invalid"><i className="pi pi-info-circle "></i> Marca en el mapa la ubicación.</label>
            {
                /*googleMapsReady && (
                    <div className="card">
                        <GMap overlays={overlays} options={options} style={{width: '100%', minHeight: '320px'}} 
                            onMapClick={onMapClick} />
                    </div>
                )*/
            }
            <div className='mt-2'>
                <MapContainer center={options.center} maxZoom={18} zoom={options.zoom} scrollWheelZoom={true} style={{width: '100%', minHeight: '320px'}} className='p-invalid' >
                        <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker />
                </MapContainer>
            </div>

            { formik.errors.latitud && formik.errors.longitud ? (
                    <p className='p-error p-0 m-0'>{formik.errors.latitud}</p>
                ) : null }

        </div>
        {/*<div className="col-10 lg:col-10 field mb-0">
            <label htmlFor="ubicacion" className="text-900 font-medium mb-3">Ubicaión Etiqueta</label>
            <InputText id="ubicacion" type="text" value={overlays[0] ? overlays[0].title : ''} className="p-inputtext w-full mb-3" disabled/>
        </div>
        <div className='col-2 lg:col-2 field mb-0 text-center'>
            <label className="text-900 font-medium mb-3 ">Eliminar</label>
            <Button icon="pi pi-trash" type='button' onClick={handleDeleteMaps} className='p-button-outlined p-button-danger w-full mb-3' />
        </div>
        <div className="col-12 lg:col-6 field mb-0">
            <label htmlFor="lat" className="text-900 font-medium mb-3">Latitud</label>
            <InputText id="lat" type="text" value={position ? position.lat : ''} className="p-inputtext w-full mb-3"  readOnly/>
        </div>
        <div className="col-12 lg:col-6 field mb-0">
            <label htmlFor="lng" className="text-900 font-medium mb-3">Longitud</label>
            <InputText id="lng" type="text" value={position ? position.lng : ''} className="p-inputtext w-full mb-3"  readOnly/>
        </div>*/}


        <Dialog header="New Location" visible={dialogVisible} width="300px" modal footer={footer} onHide={onHide}>
            <div className="grid p-fluid">
                <div className="col-2" style={{paddingTop:'.75em'}}><label htmlFor="title">Ubicación Etiqueta</label></div>
                <div className="col-10"><InputText type="text" id="title" value={markerTitle} onChange={(e) => setMarkerTitle(e.target.value)} /></div>

                <div className="col-2" style={{paddingTop:'.75em'}}>Latitud</div>
                <div className="col-10"><InputText readOnly value={selectedPosition ? selectedPosition.lat : ''} /></div>

                <div className="col-2" style={{paddingTop:'.75em'}}>Longitud</div>
                <div className="col-10"><InputText readOnly value={selectedPosition ? selectedPosition.lng : ''} /></div>

                
            </div>
        </Dialog>
    </>
  )
}

export default EnvioDomicilio