/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { FileUpload } from 'primereact/fileupload';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { DataScroller } from 'primereact/datascroller';
import { Rating } from 'primereact/rating';
import { PickList } from 'primereact/picklist';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';


import axios from "axios";

const PromocionBersati = () => {

    const [products4, setProducts4] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingProd, setLoadingProd] = useState(true);
    const [productDialog, setProductDialog] = useState(false);
    const [productDialogEdit, setProductDialogEdit] = useState(false);
    const [productDialogGrupo, setProductDialogGrupo] = useState(false);
    const [productDialogEditGrupo, setProductDialogEditGrupo] = useState(false);
    const [productDialogEditPP, setProductDialogEditPP] = useState(false);

    const [addProductos, setAddProductos] = useState(false);
    const [line, setLine] = useState(false);
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [carousel, setCarousel] = useState('');
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [selectedProduct1, setSelectedProduct1] = useState(null);
    const [products, setProducts] = useState([]);
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [productosPromocion, setProductosPromocion] = useState([]);
    const [envioGratis, setEnvioGratis] = useState(null);

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();

    const toast = useRef(null);
    const pick = useRef(null);
    const btnRefInput = useRef();

    const estadoItems = [
        {label: 'Activo', value: 1},
        {label: 'Oculto', value: 0},
    ];

    const paymentOptions = [
        {name: 'Activo', estado: 1},
        {name: 'Oculto', estado: 0},
      ];


    const formik = useFormik({
        initialValues: {
            id: '',
            nombrePromocion: '',
            descripcionPromo: '',
            imagenPromo: '',
            estado:'',
            fechaInicio:'',
            fechaFin:'',
            error: 0,
            new: 0,
            edit: 0,
            grupo:0,
            grupoEdit:0,
            ppEdit:0,

            promocion_id:'',
            grupoPromo_id:'',
            nompro:'',
            nombreGrupo:'',
            descripcionGrupoPromo:'',
            imagenGrupoPromo:'',
            estadoG:'',
            descuentoText:'',
            descuentoNumber:'',

            imagenPP:'',
            envioGratis:'',
            prod_prom_id:'',
            nombrePP:'',
            codigoPP:''


        },onSubmit: (data) => {

            //console.log(data)
            if (data && (data.new === 1)) {
                guadarPromocion(data)
            }else if(data && (data.edit === 1)){
                guardarEditPromocion(data)
            }else if(data && (data.grupo === 1)){
                guardarGrupoPromo(data)
            }else if(data && (data.grupoEdit === 1)){
                guardarEditGrupoPromo(data)
            }else if(data && (data.ppEdit === 1)){
                data.envioGratis = envioGratis;
                //console.log(data)
                guardarEditProductoPromo(data)
            }
            
        }
    })

    useEffect(() => {
        setLoading(true)
            const generarData = async () =>{
                
                const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/promo/promocionbersati')
        
                setProducts4(res.data)
                setLoading(false)
            }
        generarData()
        
      
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const generarDatita = async () =>{
        setLoading(true)

            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/promo/promocionbersati')
                
            setProducts4(res.data)

            
        setLoading(false)
        
    }

    const guadarPromocion = (data) =>{
        setLoadingBtn(true)
        data.fechaInicio = data.fechaInicio.toLocaleDateString('en-CA');
        data.fechaFin = data.fechaFin.toLocaleDateString('en-CA');
        const guardar = async (data) =>{
            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/promo/promocionbersatistore`, data);


            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Promoción Creada con éxito.', life: 3000 });
                
                formik.resetForm();
              
                setLoadingBtn(false)
                hideDialog()
            }else if(resSave.status === 202){
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Promoción NO Creada.', life: 3000 });
                setLoadingBtn(false)
                   
            }

            generarDatita()
        }

        guardar(data)
    }

    const guardarEditPromocion = (data) =>{
        setLoadingBtn(true)
        //console.log(data.fechaInicio.toLocaleDateString('en-CA'))

        const guardar = async (data) =>{

            let id = data.id;
            data.fechaInicio = data.fechaInicio.toLocaleDateString('en-CA');
            data.fechaFin = data.fechaFin.toLocaleDateString('en-CA');
            const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/promo/promocionbersatiedit/`+id, data);

            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Promoción Editada con éxito.', life: 3000 });
                
                formik.values.fechaInicio = '';
                formik.values.fechaFin = '';
                //formik.resetForm();
              
                setLoadingBtn(false)
                hideDialogEdit()
            }else if(resSave.status === 202){
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Promoción NO Editada.', life: 3000 });
                setLoadingBtn(false)
                   
            }

            generarDatita()
        }

        guardar(data)
        //setLoadingBtn(false)
    }

    const guardarGrupoPromo = (data) => {
        setLoadingBtn(true)
        //console.log(data.fechaInicio.toLocaleDateString('en-CA'))

        const guardar = async (data) =>{

            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/promo/grupopromocionbersati`, data);

            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                
                formik.values.fechaInicio = '';
                formik.values.fechaFin = '';
                //formik.resetForm();
              
                setLoadingBtn(false)
                hideDialogGrupo()
            }else if(resSave.status === 202){
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                setLoadingBtn(false)
                   
            }

            generarDatita()
        }

        guardar(data)
        //setLoadingBtn(false)
    }

    const guardarEditGrupoPromo = (data)=>{
        setLoadingBtn(true)
        //console.log(data.fechaInicio.toLocaleDateString('en-CA'))

        const guardar = async (data) =>{
            let id = data.grupoPromo_id;
            const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/promo/grupopromocionbersatiedit/`+id, data);

            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                
                formik.values.fechaInicio = '';
                formik.values.fechaFin = '';
                //formik.resetForm();
              
                setLoadingBtn(false)
                hideDialogEditGrupo()
            }else if(resSave.status === 202){
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                setLoadingBtn(false)
                   
            }

            generarDatita()
        }

        guardar(data)
    }

    




    /**********************************HIDE DIALOGS */

    const hideDialog = () => {
        formik.resetForm();
            formik.values.new = 0;
            formik.values.edit = 0;
            formik.values.grupo = 0;
        setProductDialog(false);
      }

    const hideDialogEdit = () => {
        //formik.resetForm();
        formik.values.id = '';
        formik.values.nombrePromocion = '';
        formik.values.descripcionPromo = '';
        formik.values.imagenPromo = '';
        formik.values.fechaInicio = '';
        formik.values.fechaFin = '';
            formik.values.new = 0;
            formik.values.edit = 0;
            formik.values.grupo = 0;
        setProductDialogEdit(false);
    } 

    const hideDialogGrupo = () => {
        //formik.resetForm();
        formik.values.promocion_id='';
        formik.values.nombreGrupo='';
        formik.values.descripcionGrupoPromo='';
        formik.values.imagenGrupoPromo='';
        formik.values.estadoG='';
        formik.values.descuentoText='';
        formik.values.descuentoNumber='';
        formik.values.nompro='';

            formik.values.new = 0;
            formik.values.edit = 0;
            formik.values.grupo = 0;
        setProductDialogGrupo(false);
    }

    const hideDialogEditGrupo = () => {

        formik.values.grupoPromo_id= '';
        formik.values.nombreGrupo= '';
        formik.values.descripcionGrupoPromo= '';
        formik.values.imagenGrupoPromo= '';
        formik.values.descuentoText= ''; 
        formik.values.grupoEdit = 0; 
        //formik.values.descuentoNumber= rowData;
        setProductDialogEditGrupo(false);
    }

    const hideDialogAddProducto = () => {
        setAddProductos(false)
        setLine(false)
        setSource([])
        setTarget([])
    }

    const EditEstado = async (val, rowData) =>{

        let id = rowData.id;
        let data = {
        estado : val
        }
        const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/promo/promocionbersatiestado/${id}`, data);

        if (resSave.status === 200) {      
        toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Estado editado con éxito.', life: 3000 });

        }else {
            toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Estado NO editado.', life: 3000 });
        }


        generarDatita()

    }

    const EditEstadoGrupo = async (val, rowData) =>{

        let id = rowData.id;
        let data = {
        estado : val
        }
        const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/promo/grupopromocionbersatiestado/${id}`, data);

        if (resSave.status === 200) {      
        toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Estado editado con éxito.', life: 3000 });

        }else {
            toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Estado NO editado.', life: 3000 });
        }


        generarDatita()

    }

    const estadoBodyTemplate = (rowData) => {
        return <SelectButton className='z-0' value={rowData.estado} optionLabel="name" optionValue="estado" unselectable onChange={(e) => EditEstado(e.value, rowData)} options={paymentOptions}  />;
    }
    const estadoBodyTemplateGrupo = (rowData) => {
        return <SelectButton className='z-0' value={rowData.estado} optionLabel="name" optionValue="estado" unselectable onChange={(e) => EditEstadoGrupo(e.value, rowData)} options={paymentOptions}  />;
    }
    const imageBodyTemplate = (rowData) => {
        return <img src={`${rowData.imagenPromo}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.imagen} className="product-image" />
    }
    const imageBodyTemplateGrupo = (rowData) => {
        return <img src={`${rowData.imagenGrupoPromo}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.imagen} className="product-image" />
    }


    const openDialog = () => {
            formik.values.imagenPromo = '';
            formik.values.new = 1;
            formik.values.edit = 0;
            formik.values.grupo = 0;
           
        setProductDialog(true);
    }

    const openDialogEdit = (rowData) => {
        //console.log(rowData.grupos.length)
            formik.values.id = rowData.id;
            formik.values.nombrePromocion = rowData.nombrePromocion;
            formik.values.descripcionPromo = rowData.descripcionPromo;
            formik.values.imagenPromo = rowData.imagenPromo;
            if (rowData.fechaInicio === '' || rowData.fechaInicio === null) {
                formik.values.fechaInicio = ''
                formik.values.fechaFin = ''
            }else{

                formik.values.fechaInicio = new Date(rowData.fechaInicio+'T00:00:00');
                formik.values.fechaFin = new Date(rowData.fechaFin+'T00:00:00');
            }
            formik.values.new = 0;
            formik.values.edit = 1;
            formik.values.grupo = 0;
        setProductDialogEdit(true);
    }

    const openDialogGrupo = (rowData) => {

        formik.values.promocion_id = rowData.id;
        formik.values.nompro = rowData.nombrePromocion;
        formik.values.new = 0;
        formik.values.edit = 0;
        formik.values.grupo = 1;
        setProductDialogGrupo(true)
    }

    const openDialogEditGrupo = (rowData) => {
        //console.log(rowData)
            formik.values.grupoPromo_id= rowData.id;
            formik.values.nombreGrupo= rowData.nombreGrupo;
            formik.values.descripcionGrupoPromo= rowData.descripcionGrupoPromo;
            formik.values.imagenGrupoPromo= rowData.imagenGrupoPromo;
            formik.values.descuentoText= rowData.descuentoText;
            formik.values.grupoEdit = 1;
            //formik.values.descuentoNumber= rowData;
            setProductDialogEditGrupo(true);
    }

    const generarDataProducto = async () =>{
        //console.log('????')
        const resData = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')
        setSource(resData.data)
    }

    const openDialogAddProducto = (rowData) => {
        setLine(rowData)
        generarDataProducto()
        setAddProductos(true)
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                
                <Button label="Nueva Promoción" onClick={openDialog} icon="pi pi-plus" className="p-button-success mr-2" />
                

            </React.Fragment>
        )
    }

    const limpieza = () =>{
        btnRefInput.current.clear();
        //console.log(btnRefInput)
    }

    const myUploader = async (event) => {


        setBlockedPanel(true)
        const tam = event.files.length;

        //console.log(tam)
        if (tam > 1) {
            //console.log('entra,,,,,')
            toast.current.show({ severity: 'error', summary: 'Demasiadas Imágenes!', detail: 'Un máx. de 1 imágen.', life: 3000 });
        }else if (tam === 0) {
            toast.current.show({ severity: 'warn', summary: 'Sin Imágenes!', detail: 'Si o si ingrese 1 imágen.', life: 3000 });
        }else{
            const file = event.files[0];

            const data = new FormData();
                data.append('file', file);
                data.append('upload_preset', 'bersatimg')
                data.append('cloud_name', 'ddnbi0nfz')   
                
            const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
                method: 'post',
                body: data,

            })

            const fileImg = await res.json();

            formik.values.imagenPromo = fileImg.secure_url
            toast.current.show({ severity: 'success', summary: 'Éxito en la Acción!', detail: 'Imágen subidas al servidor', life: 3000 });
            limpieza()
        }

        
        setBlockedPanel(false)
        
      }

      const myUploaderGrupo = async (event) => {


        setBlockedPanel(true)
        const tam = event.files.length;

        //console.log(tam)
        if (tam > 1) {
            //console.log('entra,,,,,')
            toast.current.show({ severity: 'error', summary: 'Demasiadas Imágenes!', detail: 'Un máx. de 1 imágen.', life: 3000 });
        }else if (tam === 0) {
            toast.current.show({ severity: 'warn', summary: 'Sin Imágenes!', detail: 'Si o si ingrese 1 imágen.', life: 3000 });
        }else{
            const file = event.files[0];

            const data = new FormData();
                data.append('file', file);
                data.append('upload_preset', 'bersatimg')
                data.append('cloud_name', 'ddnbi0nfz')   
                
            const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
                method: 'post',
                body: data,

            })

            const fileImg = await res.json();

            formik.values.imagenGrupoPromo = fileImg.secure_url
            toast.current.show({ severity: 'success', summary: 'Éxito en la Acción!', detail: 'Imágen subidas al servidor', life: 3000 });
            limpieza()
        }

        
        setBlockedPanel(false)
        
      }

      const myUploaderPP = async (event) => {


        setBlockedPanel(true)
        const tam = event.files.length;

        //console.log(tam)
        if (tam > 1) {
            //console.log('entra,,,,,')
            toast.current.show({ severity: 'error', summary: 'Demasiadas Imágenes!', detail: 'Un máx. de 1 imágen.', life: 3000 });
        }else if (tam === 0) {
            toast.current.show({ severity: 'warn', summary: 'Sin Imágenes!', detail: 'Si o si ingrese 1 imágen.', life: 3000 });
        }else{
            const file = event.files[0];

            const data = new FormData();
                data.append('file', file);
                data.append('upload_preset', 'bersatimg')
                data.append('cloud_name', 'ddnbi0nfz')   
                
            const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
                method: 'post',
                body: data,

            })

            const fileImg = await res.json();

            formik.values.imagenPP = fileImg.secure_url
            toast.current.show({ severity: 'success', summary: 'Éxito en la Acción!', detail: 'Imágen subidas al servidor', life: 3000 });
            limpieza()
        }

        
        setBlockedPanel(false)
        
      }

    const deleteProduct = async () => {
        setLoadingBtn(true)
        let resSave = '';
        if (carousel.id) {
          console.log(carousel)

            if (carousel.promocion_id) {
                resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/promo/grupopromocionbersatidelete/${carousel.id}`)
            }else{

                resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/promo/promocionbersatidelete/${carousel.id}`)
            }
    
          //console.log(resSave)
            if (resSave.status === 200) {      
              toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
              setCarousel('');
              setLoadingBtn(false)
              setDeleteProductDialog(false);
    
            }else if (resSave.status === 202) {
                setDeleteProductDialog(false);
                setLoadingBtn(false)
                setCarousel('');
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
            }
          generarDatita()
        }
      }

    const confirmDeleteProduct = (data) => {
        setCarousel(data);
        setDeleteProductDialog(true);
        
      }

      const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex'>
                    <Button icon="pi pi-plus" placeholder="Left" tooltip="Añadir Grupo" tooltipOptions={{position: 'left'}} onClick={() => openDialogGrupo(rowData)} className="p-button-rounded p-button-info ml-1"  />
                    <Button icon="pi pi-pencil" placeholder="Left" tooltip="Editar Promoción" tooltipOptions={{position: 'left'}} onClick={() => openDialogEdit(rowData)} className="p-button-rounded p-button-warning ml-1"  />
                    <Button icon="pi pi-trash" placeholder="Left" tooltip="Eliminar Promoción" tooltipOptions={{position: 'left'}} onClick={() => confirmDeleteProduct(rowData)} className="p-button-rounded p-button-danger ml-1"  />
                </div>
            </React.Fragment>
        );
      }

      const actionBodyTemplateGrupo = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex'>
                    <Button icon="pi pi-plus-circle" placeholder="Left" tooltip="Añadir Productos" tooltipOptions={{position: 'left'}} onClick={() => openDialogAddProducto(rowData)} className="p-button-rounded p-button-info ml-1"  />
                    <Button icon="pi pi-pencil" placeholder="Left" tooltip="Editar Grupo Promoción" tooltipOptions={{position: 'left'}} onClick={() => openDialogEditGrupo(rowData)} className="p-button-rounded p-button-warning ml-1"  />
                    <Button icon="pi pi-trash" placeholder="Left" tooltip="Eliminar Grupo Promoción" tooltipOptions={{position: 'left'}} onClick={() => confirmDeleteProduct(rowData)} className="p-button-rounded p-button-danger ml-1"  />
                </div>
            </React.Fragment>
        );
      }

      const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setCarousel('')
      }
      const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
      );

      /************ Data Productos Promoción *************/

      
        const generarDataProductoPromocionO = async (var1, var2) =>{
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/promo/productopromocionindex/'+var1+'/'+var2)
            setProductosPromocion(res.data)
        }

        const guardarEditProductoPromo = (data)=>{
            setLoadingBtn(true)
            
            const var1 = data.grupoPromo_id;
            const var2 = data.promocion_id;

            const guardar = async (data) =>{
                //console.log(data)

                let id = data.prod_prom_id;
                
                
                const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/promo/productopromocionedit/`+id, data);

                if (resSave.status === 200) {      
                    toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                    
                    //formik.resetForm();
                    generarDataProductoPromocionO(var1, var2)
                    setLoadingBtn(false)
                    hideDialogEditPP()
                }else if(resSave.status === 202){
                    toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                    setLoadingBtn(false)
                    
                }

                
            }

            guardar(data)
            //
        }


      const onRowSelect = async (rowData) =>{
        if (rowData.data) {
            //console.log(rowData.data.id)
            if (rowData.data.id && rowData.data.promocion_id) {
                const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/promo/productopromocionindex/'+rowData.data.id+'/'+rowData.data.promocion_id)

                setProductosPromocion(res.data)
                //console.log(res)
            }
        }
      }
        const generarDataProductoPromocion = async (rowData) =>{
            //console.log(rowData)
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/promo/productopromocionindex/'+rowData.grupoPromocion_id+'/'+rowData.promocion_id)

            setProductosPromocion(res.data)
        }

        const EditEstadoProductoPromocion = async (val, dataEs) =>{

            let id = dataEs.pp_id;
            let data = {
                estado : val
            }
            const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/promo/productopromocionestado/${id}`, data);

            if (resSave.status === 200) {      
            toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Estado editado con éxito.', life: 3000 });

            }else {
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Estado NO editado.', life: 3000 });
            }


            generarDataProductoPromocion(dataEs)

        }

        const DeleteProductoPromocion = async (data) =>{
            if (data.pp_id) {
              //console.log(data)  
                const var1 = data.grupoPromocion_id;
                const var2 = data.promocion_id;
                const resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/promo/productopromociondelete/${data.pp_id}`);
                //console.log(resSave)
                if (resSave.status === 200) {      
                    toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 4000});
                    generarDataProductoPromocionO(var1, var2)
                }else {
                    toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 4000});
                }


            }
        }

        const OpenDialogEditPP = (data) =>{
            //console.log(data)
            setProductDialogEditPP(true)
            formik.values.promocion_id = data.promocion_id;
            formik.values.grupoPromo_id = data.grupoPromocion_id;
            formik.values.descuentoNumber = data.descuentoNumber;
            formik.values.ppEdit = 1;
            formik.values.nombrePP = data.nombre;
            formik.values.codigoPP = data.codigo;
            if (data.imagenPP === null) {
                formik.values.imagenPP = '';
            }else{
                formik.values.imagenPP = data.imagenPP;
            }
            formik.values.prod_prom_id = data.pp_id;
            if (data.envioGratis === 1) {
                setEnvioGratis(true)
            }else{
                setEnvioGratis(false)
            }

        }

        
        const hideDialogEditPP = () =>{
            setProductDialogEditPP(false)
            formik.values.descuentoNumber = '';
            formik.values.promocion_id = '';
            formik.values.grupoPromo_id = '';
            formik.values.imagenPP = '';
            formik.values.prod_prom_id = '';
            formik.values.ppEdit = 0;
            formik.values.envioGratis = '';
            formik.values.nombrePP = '';
            formik.values.codigoPP = '';

            setEnvioGratis(null)
        }

        

        const chageValuePP = (valor) =>{
            formik.values.descuentoNumber = valor
        }

      
    
      const itemTemplate = (data) => {
        return (
            <div className="product-item">
                {
                    data.imagenPP ? (
                        <img src={`${data.imagenPP}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.nombre} />     
                    ):(
                        <img src={`${data.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.nombre} />
                    )
                }
                
                <div className=''>

                    <div className="product-name">{data.nombre} - {data.codigo}</div>

                    <div className='product-item p-0 mt-2'>

                        <div className="product-detail">
                            <SelectButton className='z-0 mt-2' value={data.estado} optionLabel="name" onChange={(e) => EditEstadoProductoPromocion(e.value, data)} optionValue="estado" unselectable  options={paymentOptions}  />

                            <div>
                                <Button label="Editar" onClick={()=>OpenDialogEditPP(data)} className="p-button-raised p-button-sm mt-3 p-button-secondary p-button-text" />
                                <Button label="Eliminar" onClick={()=>DeleteProductoPromocion(data)} className="p-button-raised p-button-sm mt-3 p-button-danger p-button-text" />
                            </div>
                        </div>
                        <div className="product-action">
                            <div className="product-price  font-semibold">Precio Oficial: <span className='font-normal'>BOB. {data.precio}</span></div>
                            <div className="product-price">Descuento: {data.descuentoNumber} %</div>
                            {
                                data.descuentoNumber > 0 ? (
                                    <span className="product-price font-semibold">Final: <span className='font-normal'> BOB. {(data.precio - (data.precio * (data.descuentoNumber / 100))).toFixed(2)}</span></span>
                                ):(
                                    <span className="product-price font-semibold">Final: <span className='font-normal'>BOB. {data.precio}</span></span>
                                )
                            }

                            <div className="product-price 
                             font-semibold">Envio Gratis: <span className='font-normal'>{data.envioGratis === 1? 'Si': 'No'}</span></div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }


    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">   
                <h3 className='p-0 m-2'>Grupo de Productos</h3>
                <DataTable value={data.grupos} onRowDoubleClick={onRowSelect} selectionMode="single" /*onRowSelect={onRowSelect} */ selection={selectedProduct1} onSelectionChange={e => setSelectedProduct1(e.value)} responsiveLayout="scroll" className=''>
                    
                    <Column field="nombreGrupo" header="Nombre Grupo" sortable></Column>
                    <Column field="descripcionGrupoPromo" header="Descripción Grupo" sortable></Column>
                    <Column field="descuentoText" header="Descuento Texto"  sortable></Column>
                    <Column header='Estado' className='flex justify-content-center' style={{ minWidth: '16rem' }} body={estadoBodyTemplateGrupo} />
                    <Column header='Imagen' className='flex justify-content-center' style={{ minWidth: '18rem' }} body={imageBodyTemplateGrupo} />
                    <Column header='Acciones' body={actionBodyTemplateGrupo} exportable={false} style={{ maxWidth: '12rem' }}></Column>

                </DataTable>
            </div>
        );
    }

    const allowExpansion = (rowData) => {
        
        return rowData.grupos.length > 0;
    };

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    }

    const saveProductos = async() => {
        setLoadingBtn(true)
        if (target.length > 0) {
            let productos = []
            for (let index = 0; index < target.length; index++) {
                let descuento = ''
                if (!target[index].descuento) {
                    descuento = 0;
                }else{
                    descuento = target[index].descuento
                }
                let VerData = {
                        
                    descuentoNumber : descuento,
                    producto_id : target[index].id,
                    envioGratis : target[index].envioGratis,
                    promocion_id : line.promocion_id,
                    grupoPromocion_id : line.id,
                        
                }

                productos.push(VerData)
            }
            const data = {
                'productos' : productos
            }


            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/promo/productopromocionstore`, data)



            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                setLoadingBtn(false)
                setTarget([])

            }else if(resSave.status === 202){
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                setLoadingBtn(false)
                   
            }
            //console.log(resSave)
        }
    }

    const itemTemplatePickS = (item) => {
        return (
            
            <div className="product-item">
             
                <div className="image-container">
                    <img src={`images/product/${item.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} />
                </div>
                <div className="product-list-detail">
                    <h3 className="mb-2">{item.nombre}</h3>
                    <i className="pi pi-tag product-category-icon"></i>
                    <span className="product-category" onError={(e) => e.target.label='...'}>{item.codigo}</span>
                    <div>
                        <span className="product-category">{item.nombreCategoria} - {item.nombreFamilia} - {item.nombreLinea} - {item.nombreGrupoProductos}</span>
                    </div>
                    
                </div>
                <div className="product-list-action">
                    <h6 className="mb-2 marca">{item.marca}</h6>
                    {
                        item.estado ? (
                            <span className={`product-badge status-instock`}>ACTIVO</span>
                        )
                        :
                        (
                            <span className={`product-badge status-outofstock`}>OCULTO</span>
                        )
                    }
                    
                </div>
            </div>
        );
    }

   
    const [descuento, setDescuento] = useState(0)
    //const [checked, setChecked] = useState(false);
    const itemTemplatePickT = (item) => {

        //item.descuento = 0
        
        const descuentoValor = (valor) =>{
            item.descuento = valor
            let calculo = (item.precio - (item.precio * (item.descuento / 100))).toFixed(2)
            setDescuento(calculo)
        }

   

        return (
            
            <div className="product-item">
                <div className="image-container">
                    <img src={`images/product/${item.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} />
                </div>
                <div className="product-list-detail">
                    <h3 className="mb-2">{item.nombre}</h3>
                    <div>
                        <span className={`product-category ${item.descuento ? 'line-through':''}`}>Precio: BOB. {item.precio}</span>
                    </div>
                    <div>
                        <label htmlFor="percent">Precio c/Descuento: BOB. {item.descuento ? descuento : ''}</label>
                        <InputNumber inputId="percent" className='w-8rem z-2' placeholder='Descuento' value={item.descuento} onValueChange={(e) => descuentoValor(e.target.value)} prefix="%" />
                        

                    </div>
                    <div className='mt-2'>
                        <label>Envio Gratis?</label> <Checkbox className='z-2' onChange={e => item.envioGratis = e.checked} checked={item.envioGratis ? item.envioGratis: item.envioGratis = false}></Checkbox>
                    </div>
                </div>
                <div className="product-list-action">
                    <div className='flex align-content-center mb-2'>
                        <i className="pi pi-tag product-category-icon"></i>
                        <span className="product-category" onError={(e) => e.target.label='...'}>{item.codigo}</span>
                    </div>
                    
                    {
                        item.estado ? (
                            <span className={`product-badge status-instock`}>ACTIVO</span>
                        )
                        :
                        (
                            <span className={`product-badge status-outofstock`}>OCULTO</span>
                        )
                    }
                    
                </div>
            </div>
        );
    }

    //console.log(formik.values)
  return (
    <>
        <Toast ref={toast} />
        <div className='w-full overflow-scroll speeddial-linear-demo '>
            
            <div className="surface-section px-2 py-5 md:px-3 lg:px-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">PROMOCIÓN ECOMMERCE</span>
                </div>
                
            </div>
            <div className='grid w-full px-2 md:px-3 lg:px-5 m-0'>

                <div className='col-12 md:col-8 lg:col-8 p-0 pr-1 border-right-1 surface-border'>
                    <div className="card w-full datatable-crud-demo " >
                        <Toolbar className="mb-1 text-sm p-2" left={leftToolbarTemplate}></Toolbar>

                        <DataTable value={products4} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loading} filterDisplay="row" emptyMessage='Sin datos...'
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} items" size="small"  showGridlines  dataKey="id"
                        editMode="cell"  className="editable-cells-table z-1 " responsiveLayout="scroll" scrollHeight="flex" scrollable  
                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}  rowExpansionTemplate={rowExpansionTemplate}>

                            <Column expander={allowExpansion} filterMenuStyle={{ width: '3rem' }} style={{ maxWidth: '4rem' }} />
                            <Column field='nombrePromocion' header='Nombre Promoción' className=' overflow-scroll' style={{ minWidth: '13rem' }}  />
                            <Column field='descripcionPromo' header='Descripción Promoción' className=' overflow-scroll' style={{ minWidth: '13rem' }}  />
                            <Column header='Imagen' className='flex justify-content-center' style={{ minWidth: '18rem' }} body={imageBodyTemplate} />
                            <Column header='Estado' className='flex justify-content-center' style={{ minWidth: '16rem' }} body={estadoBodyTemplate} />
                            <Column field='fechaInicio' header='Fecha Inicio' className=' overflow-scroll' sortable style={{ minWidth: '13rem' }}  />
                            <Column field='fechaFin' header='Fecha Fin' className=' overflow-scroll' sortable style={{ minWidth: '13rem' }}  />
                            <Column header='Acciones' body={actionBodyTemplate} exportable={false} style={{ maxWidth: '12rem' }}></Column>
                        </DataTable>


                        <Dialog visible={productDialog} style={{ width: '450px' }} header="Nueva Promoción" modal className="p-fluid"  onHide={hideDialog}>
                            <form onSubmit={formik.handleSubmit} className="p-fluid">
                                <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                                    <div className="field">
                                        <label htmlFor="nombrePromocion">Nombre de la Promoción</label>
                                        <InputText id="nombrePromocion" value={formik.values.nombrePromocion} onChange={formik.handleChange}  required />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="descripcionPromo">Descripción de la Promoción</label>
                                        <InputText id="descripcionPromo" value={formik.values.descripcionPromo} onChange={formik.handleChange}   />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="fechaInicio">Fecha Inicio de la Promoción</label>
                                        {/*<InputText id="fechaInicio" value={formik.values.fechaInicio} onChange={formik.handleChange}  required />    */}
                                        <Calendar id="fechaInicio" value={formik.values.fechaInicio} onChange={formik.handleChange} dateFormat="yy-mm-dd" />           
                                    </div>
                                    <div className="field">
                                        <label htmlFor="fechaFin">Fecha Fin de la Promoción</label>
                                    {/*} <InputText id="fechaFin" value={formik.values.fechaFin} onChange={formik.handleChange} required  />  */} 
                                        <Calendar id="fechaFin" value={formik.values.fechaFin} onChange={formik.handleChange} dateFormat="yy-mm-dd" />                
                                    </div>
                                    <div className="field">
                                        <label>Subida de Imagen</label>
                                        <FileUpload className='mt-2' ref={btnRefInput} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploader}  maxFileSize={1000000}
                                            emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />    
                                    </div>
                                    <div className="field">
                                        <label htmlFor="imagenPromo">URL de la imagen</label>
                                        <InputText id="imagenPromo" value={formik.values.imagenPromo} onChange={formik.handleChange} required readOnly />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="estado">Estado</label>
                                        <Dropdown inputId="estado" name="estado" 
                                        optionValue="value" value={formik.values.estado} optionLabel="label" 
                                        options={estadoItems} onChange={formik.handleChange} />              
                                    </div>
                                    {
                                        formik.values.imagenPromo ? (
                                            <Button type="submit" loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                                        )
                                        :
                                        (
                                            ''
                                        )
                                    }
                                </BlockUI> 

                            </form>
                        </Dialog>


                        <Dialog visible={productDialogEdit} style={{ width: '450px' }} header="Editar Promoción" modal className="p-fluid"  onHide={hideDialogEdit}>
                            <form onSubmit={formik.handleSubmit} className="p-fluid">
                                <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                                    <div className='field w-full'>
                                        <img className='w-full' src={formik.values.imagenPromo} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={formik.values.nombreGrupo} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="nombrePromocion">Nombre de la Promoción</label>
                                        <InputText id="nombrePromocion" value={formik.values.nombrePromocion} onChange={formik.handleChange}  required />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="descripcionPromo">Descripción de la Promoción</label>
                                        <InputText id="descripcionPromo" value={formik.values.descripcionPromo} onChange={formik.handleChange}   />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="fechaInicio">Fecha Inicio de la Promoción</label>
                                        {/*<InputText id="fechaInicio" value={formik.values.fechaInicio} onChange={formik.handleChange}  required />    */}
                                        <Calendar id="fechaInicio" value={formik.values.fechaInicio}  onChange={formik.handleChange} dateFormat="yy-mm-dd" />           
                                    </div>
                                    <div className="field">
                                        <label htmlFor="fechaFin">Fecha Fin de la Promoción</label>
                                    {/*} <InputText id="fechaFin" value={formik.values.fechaFin} onChange={formik.handleChange} required  />  */} 
                                        <Calendar id="fechaFin" value={formik.values.fechaFin} onChange={formik.handleChange} dateFormat="yy-mm-dd" />                
                                    </div>
                                    <div className="field">
                                        <label>Subida de Imagen</label>
                                        <FileUpload className='mt-2' ref={btnRefInput} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploader}  maxFileSize={1000000}
                                            emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />    
                                    </div>
                                    <div className="field">
                                        <label htmlFor="imagenPromo">URL de la imagen</label>
                                        <InputText id="imagenPromo" value={formik.values.imagenPromo} onChange={formik.handleChange} required readOnly />               
                                    </div>

                                    <Button type="submit" loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                                </BlockUI> 

                            </form>
                        </Dialog>

                        <Dialog visible={productDialogGrupo} style={{ width: '450px' }} header="Nuevo Grupo Promoción" modal className="p-fluid"  onHide={hideDialogGrupo}>
                            <form onSubmit={formik.handleSubmit} className="p-fluid">
                                <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                                    <h3 className='pb-4 font-bold'>Promo: <span className='font-medium font-italic'>{formik.values.nompro}</span> </h3>
                                    <div className="field">
                                        <label htmlFor="nombreGrupo">Nombre del Grupo Promoción</label>
                                        <InputText id="nombreGrupo" value={formik.values.nombreGrupo} onChange={formik.handleChange}  required />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="descripcionGrupoPromo">Descripción del Grupo Promoción</label>
                                        <InputText id="descripcionGrupoPromo" value={formik.values.descripcionGrupoPromo} onChange={formik.handleChange}   />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="descuentoText">Descuento en Texto del Grupo Promoción</label>
                                        <InputText id="descuentoText" value={formik.values.descuentoText} onChange={formik.handleChange} placeholder='Ej. Descuentos del 15%, 20% y 30%'  required />       
                                    </div>
                                    <div className="field">
                                        <label>Subida de Imagen</label>
                                        <FileUpload className='mt-2' ref={btnRefInput} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploaderGrupo}  maxFileSize={1000000}
                                            emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />    
                                    </div>
                                    <div className="field">
                                        <label htmlFor="imagenGrupoPromo">URL de la imagen</label>
                                        <InputText id="imagenGrupoPromo" value={formik.values.imagenGrupoPromo} onChange={formik.handleChange} required readOnly />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="estadoG">Estado</label>
                                        <Dropdown inputId="estadoG" name="estadoG" 
                                        optionValue="value" value={formik.values.estadoG} optionLabel="label" 
                                        options={estadoItems} onChange={formik.handleChange} />              
                                    </div>

                                    {
                                        formik.values.imagenGrupoPromo ? (
                                            <Button type="submit" loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                                        )
                                        :
                                        (
                                            ''
                                        )
                                    }
                                </BlockUI> 

                            </form>
                        </Dialog>


                        <Dialog visible={productDialogEditGrupo} style={{ width: '450px' }} header="Editar Grupo Promoción" modal className="p-fluid"  onHide={hideDialogEditGrupo}>
                            <form onSubmit={formik.handleSubmit} className="p-fluid">
                                <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                                    <div className='field w-full'>
                                        <img className='w-full' src={formik.values.imagenGrupoPromo} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={formik.values.nombreGrupo} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="nombreGrupo">Nombre del Grupo Promoción</label>
                                        <InputText id="nombreGrupo" value={formik.values.nombreGrupo} onChange={formik.handleChange}  required />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="descripcionGrupoPromo">Descripción del Grupo Promoción</label>
                                        <InputText id="descripcionGrupoPromo" value={formik.values.descripcionGrupoPromo} onChange={formik.handleChange}   />               
                                    </div>
                                    <div className="field">
                                        <label htmlFor="descuentoText">Descuento en Texto del Grupo Promoción</label>
                                        <InputText id="descuentoText" value={formik.values.descuentoText} onChange={formik.handleChange} placeholder='Ej. Descuentos del 15%, 20% y 30%'  required />       
                                    </div>
                                    <div className="field">
                                        <label>Subida de Imagen</label>
                                        <FileUpload className='mt-2' ref={btnRefInput} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploaderGrupo}  maxFileSize={1000000}
                                            emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />    
                                    </div>
                                    <div className="field">
                                        <label htmlFor="imagenGrupoPromo">URL de la imagen</label>
                                        <InputText id="imagenGrupoPromo" value={formik.values.imagenGrupoPromo} onChange={formik.handleChange} required readOnly />               
                                    </div>

                                    <Button type="submit" loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                                </BlockUI> 

                            </form>
                        </Dialog>

                        <Dialog visible={addProductos} style={{ width: '1000px' }} header="Editar Grupo Promoción" modal className="p-fluid"  onHide={hideDialogAddProducto}>
                                <div className='field'>
                                    <div className="picklist-demo">
                                        <div className="card">
                                            
                                            <PickList ref={pick} source={source} target={target}  sourceItemTemplate={itemTemplatePickS} targetItemTemplate={itemTemplatePickT} 
                                            sourceHeader="Productos." targetHeader="Seleccionados."
                                            sourceStyle={{ minHeight: '400px' }} targetStyle={{ minHeight: '400px' }} onChange={onChange}
                                            filterBy={'codigo'}  sourceFilterPlaceholder="Buscar por Código" targetFilterPlaceholder="Buscar por Código" />
                                        </div>
                                    </div>
                                </div>
                                <div className='field'>

                                    {
                                        target.length > 0 ?(
                                            <Button type="button" onClick={saveProductos} loading={loadingBtn} label='Guardar Productos' className='p-button-raised p-button-success px-6'/>
                                        ):(
                                            ''
                                        )
                                    }
                                </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                                <span>Estas seguro de eliminar ?</span>
                            </div>
                        </Dialog>
                    </div>
                </div>


                <div className='col-12 md:col-4 lg:col-4 p-0 pl-1 border-left-1 surface-border'>
                    <div className="datascroller-demo">
                        <div className="card">
                            <DataScroller value={productosPromocion} itemTemplate={itemTemplate} emptyMessage='Esperando Selección...' rows={5} inline scrollHeight="500px" header="Productos" />
                        </div>
                    </div>


                    <Dialog visible={productDialogEditPP} style={{ width: '450px' }} header="Editar Producto Promoción " modal className="p-fluid"  onHide={hideDialogEditPP}>
                        <form onSubmit={formik.handleSubmit} className="p-fluid">
                            <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                                <div className='field'>
                                    <span className='text-2xl text-800'>{formik.values.nombrePP} - {formik.values.codigoPP}</span>
                                </div>
                                <div className='field w-full'>
                                    {
                                        formik.values.imagenPP === '' || formik.values.imagenPP === null || formik.values.imagenPP === undefined? (
                                            <img className='w-12rem' src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'  alt={'Sin Imagen'} />
                                        ):(
                                            <img className='w-12rem' src={formik.values.imagenPP} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={formik.values.imagenPP} />
                                            //<label>Subida de Imagen</label>
                                        )
                                    }
                                </div>
                                <div className="field">
                                    <label htmlFor="descuentoNumber">Descuento</label>
                                    
                                    <InputNumber inputId="descuentoNumber"  placeholder='Descuento' value={formik.values.descuentoNumber} onValueChange={(e)=>chageValuePP(e.target.value)} prefix="%" />               
                                </div>
                                <div className='mt-2'>
                                    <label>Envio Gratis?</label> <Checkbox className='z-2' onChange={e => setEnvioGratis(e.checked)} checked={envioGratis}></Checkbox>
                                </div>  
                                <Divider />
                                <div className="field mt-2">
                                    <label>Subida de Imagen</label>
                                    <FileUpload className='mt-2' ref={btnRefInput} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploaderPP}  maxFileSize={1000000}
                                        emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />    
                                </div>
                                <div className="field">
                                    <label htmlFor="imagenPP">URL de la imagen</label>
                                    <InputText id="imagenPP" value={formik.values.imagenPP} onChange={formik.handleChange} readOnly />               
                                </div>

                                <Button type="submit" loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                            </BlockUI> 

                        </form>
                    </Dialog>
                </div>
            </div>
        </div>  
    </>
  )
}

export default PromocionBersati