/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { StyleClass } from 'primereact/styleclass';
import { Badge } from 'primereact/badge';
import { SelectButton } from 'primereact/selectbutton';
import { ScrollPanel } from 'primereact/scrollpanel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';

import axios from "axios";

import bersati from '../../assets/img/Bersati3.png'

import './dashadminStyle.css'
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";

const DashAdmin = () => {
    const navigate = useNavigate();

    const toggleBtnRef = useRef(null);
    const toggleBtnRefSidebar = useRef(null);
    const Btn1 = useRef(null);
    const Btn2 = useRef(null);
    const Btn3 = useRef(null);
    const Btn4 = useRef(null);
    const Btn5 = useRef(null);
    const Btn6 = useRef(null);
    const userToken = useSelector((store) => store.userToken);

    const [userDatita, setUserDatita] = useState(null);

    const [menuOption, setMenuOption] = useState('Ecommerce');
    if (menuOption === null) {
        setMenuOption('Ecommerce')
    }
    const options = ['Ecommerce', 'Configuración'];

    /*useEffect(()=>{
        if (userToken.loadingToken === false) {
            console.log('entraa')
            //console.log(userToken.userToken.data.tokenable.name)
            setUserDatita(userToken.userToken.data.tokenable)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userToken.loadingToken])*/
    
   

    const cerrarSesion = async () =>{
        let token = ''
        try {
            token = atob(localStorage.getItem('_uid'));
        } catch (error) {
            localStorage.removeItem('_uid');
            navigate('/admin/login');
        }
        
        const tok = 'Bearer ' + token;

        var config = {
            method: 'post',
            url: process.env.REACT_APP_UNSPLASH_URL+'/logout',
            headers: { 
              'Authorization': tok
            }
          };
        
          //console.log(config)
          try {
            const res = await axios(config).catch((error)=>{
                localStorage.removeItem('_uid');
                navigate('/admin/login');
            })
            if (res.status) {
                const estado = res.status
                if (estado === 200) {
                    
                    localStorage.removeItem('_uid');
                    navigate('/');
                }else if(estado === 202){
                    localStorage.removeItem('_uid');
                    navigate('/admin/login');
                }
            }
          } catch (error) {
            localStorage.removeItem('_uid');
                navigate('/admin/login');
          }
       
        
        
      
    }
    //console.log(userToken)
    
  return (
    <>

        <div className="min-h-screen min-w-screen flex surface-ground">
            <div id="app-sidebar-13" className="surface-section z-3 h-screen md:sticky lg:sticky fixed hidden  flex-shrink-0 absolute  left-0 top-0 z-1 border-right-1 surface-border select-none" style={{width: "250px"}}>
                <div className="flex flex-column h-full">
                    <div className="flex align-items-center justify-content-center px-3 py-5 border-bottom-1 surface-border" style={{height:"60px"}}>
                        <Link to={'/protected/admin'} className='no-underline'>
                            <img alt="logios" src={bersati}  height="30" />
                        </Link>
                    </div>
                    <div className="flex justify-content-center py-5">
                        {/*<p-selectButton [options]="menuOptions" [(ngModel)]="menuOption" optionLabel="name" optionValue="value"></p-selectButton> */}
                        <SelectButton value={menuOption} unselectable='true' className='' options={options} onChange={(e) => setMenuOption(e.value)} />
                    </div>
                    <div className="overflow-y-auto px-2 flex-1">
                        {
                            menuOption === 'Ecommerce' ? (
                                <ul className="list-none p-0 m-0" >
                                    <li>
                                        <Link to={'/protected/admin'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors " style={{borderRadius: "12px"}}>
                                            <i className="pi pi-home mr-2 text-indigo-600"></i>
                                            <span className="font-medium">Dashboard</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <StyleClass nodeRef={Btn1} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                            <a ref={Btn1} className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                <i className="pi pi-tags mr-2 text-cyan-600"></i>
                                                <span className="font-medium">Gestión Categorias</span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                            </a>
                                        </StyleClass>
                                            <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li>
                                                    {/*<StyleClass nodeRef={Btn2} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                                        <a ref={Btn2} className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                            <i className="pi pi-chart-line mr-2 text-purple-500"></i>
                                                            <span className="font-medium">Categorias</span>
                                                            <i className="pi pi-chevron-down ml-auto"></i>
                                                        </a>
                                                    </StyleClass>
                                                    <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                        <li>
                                                            <a className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                                <i className="pi pi-table text-purple-500 mr-2"></i>
                                                                <span className="font-medium">View</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                                <i className="pi pi-search mr-2 text-purple-500"></i>
                                                                <span className="font-medium">Search</span>
                                                            </a>
                                                        </li>
                                                    </ul>*/}
                                                    <Link to={'/protected/admin/categorias'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-tag mr-2 text-cyan-600"></i>
                                                        <span className="font-medium">Categorias</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/protected/admin/familias'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-tag mr-2 text-cyan-600"></i>
                                                        <span className="font-medium">Familias</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/protected/admin/lineas'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-tag mr-2 text-cyan-600"></i>
                                                        <span className="font-medium">Lineas</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/protected/admin/gp'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-tag mr-2 text-cyan-600"></i>
                                                        <span className="font-medium">Grupo de Productos</span>
                                                    </Link>
                                                </li>
                                             

                                            </ul>
                                    </li>
                                    <li>
                                        <StyleClass nodeRef={Btn2} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                            <a ref={Btn2} className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                <FontAwesomeIcon icon={faBoxesStacked} className=" mr-2 text-yellow-600" ></FontAwesomeIcon >
                                                <span className="font-medium">Gestión de Productos</span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                            </a>
                                        </StyleClass>

                                            <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                    <li>
                                                        <Link to={'/protected/admin/nuevoproducto'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                            <i className="pi pi-box mr-2 text-yellow-600"></i>
                                                            <span className="font-medium">Nuevo Producto</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'/protected/admin/listaproducto'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                            <i className="pi pi-box mr-2 text-yellow-600"></i>
                                                            <span className="font-medium">Lista de Productos</span>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to={'/protected/admin/editarcategoriaproducto'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <FontAwesomeIcon icon={faBarsStaggered} className="mr-2 text-yellow-600 text-lg" ></FontAwesomeIcon >
                                                            <span className="font-medium">CFLGP Productos</span>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to={'/protected/admin/precioproducto'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                            <i className="pi pi-dollar mr-2 text-yellow-600"></i>
                                                            <span className="font-medium">Precios Productos</span>
                                                        </Link>
                                                    </li>
                                                    
                                                    <li>
                                                        <Link to={'/protected/admin/estadoproducto'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                            <i className="pi pi-eye mr-2 text-yellow-600"></i>
                                                            <span className="font-medium">Estado Productos</span>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to={'/protected/admin/editarcaracteristicasproducto'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                            
                                                            <FontAwesomeIcon icon={faClipboardList} className="mr-2 text-yellow-600 text-xl" ></FontAwesomeIcon >
                                                            <span className="font-medium">Características Producto</span>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to={'/protected/admin/editartimgproducto'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                            <i className="pi pi-image mr-2 text-yellow-600"></i>
                                                            <span className="font-medium">Imágen Producto</span>
                                                        </Link>
                                                    </li>
                                                    
                                                    <li>
                                                        <Link to={'/protected/admin/'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                            <i className="pi pi-box mr-2 text-yellow-600"></i>
                                                            <span className="font-medium">CrosSelling</span>
                                                        </Link>
                                                    </li>
                                                

                                            </ul>
                                    </li>
                                    <li>
                                        <StyleClass nodeRef={Btn3} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                            <a ref={Btn3} className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                <i className="pi pi-briefcase mr-2 text-teal-500"></i>
                                                <span className="font-medium">Gestión Ecommerce</span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                            </a>
                                        </StyleClass>
                                            <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li>
                                                    <Link to={'/protected/admin/carousel'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-clone mr-2 text-teal-500"></i>
                                                        <span className="font-medium">Carousel</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/protected/admin/eleccioneditor'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-verified mr-2 text-teal-500"></i>
                                                        <span className="font-medium">Elección del Editor</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/protected/admin/'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-thumbs-up mr-2 text-teal-500"></i>
                                                        <span className="font-medium">Recomendado</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                    </li>
                                    <li>
                                        <StyleClass nodeRef={Btn5} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                            <a ref={Btn5} className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                <i className="pi pi-shopping-bag mr-2 text-red-500"></i>
                                                <span className="font-medium">Gestión Ventas Bersati</span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                            </a>
                                        </StyleClass>
                                            <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li>
                                                    <Link to={'/protected/admin/ventaconfirmar'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-clone mr-2 text-red-500"></i>
                                                        <span className="font-medium">Confirmar Venta</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                    </li>
                                    <li>
                                        <StyleClass nodeRef={Btn6} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                            <a ref={Btn6} className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                <i className="pi pi-truck mr-2 text-red-500"></i>
                                                <span className="font-medium">Gestión Despachos Bersati</span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                            </a>
                                        </StyleClass>
                                            <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li>
                                                    <Link to={'/protected/admin/despachosbersati'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-directions mr-2 text-red-500"></i>
                                                        <span className="font-medium">Por Despachar</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                    </li>
                                    
                                    <li>
                                        <StyleClass nodeRef={Btn4} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                            <a ref={Btn4} className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                <i className="pi pi-bell mr-2 text-teal-500"></i>
                                                <span className="font-medium">Gestión Promociones</span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                            </a>
                                        </StyleClass>
                                            <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li>
                                                    <Link to={'/protected/admin/promociones'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                                        <i className="pi pi-megaphone mr-2 text-teal-500"></i>
                                                        <span className="font-medium">Promociones</span>
                                                    </Link>
                                                </li>
                                                
                                            </ul>
                                    </li>
                                    <li>
                                        <Link to={'/'} target='_blank' className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                            <i className="pi pi-shopping-bag mr-2 text-red-600"></i>
                                            <span className="font-medium">Ver Ecommerce</span>
                                        </Link>
                                    </li>
                                </ul>
                            )
                            :
                            (
                                ""
                            )
                        }{
                            menuOption === 'Configuración' ? (
                            <ul className="list-none p-0 m-0" >
                                <li>
                                    <Link to={'/protected/admin/system/productos'} className="no-underline flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                        <i className="pi pi-server mr-2"></i>
                                        <span className="font-medium">Productos Selkis</span>
                                    </Link>
                                </li>
                                <li>
                                    <a className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                        <i className="pi pi-slack mr-2"></i>
                                        <span className="font-medium">Slack</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:surface-hover transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                                        <i className="pi pi-github mr-2"></i>
                                        <span className="font-medium">GitHub</span>
                                    </a>
                                </li>
                            </ul>
                            )
                            :
                            (
                                ""
                            )
                        }

                    </div>
                    <div className="border-top-1 surface-border p-3 flex justify-content-center column-gap-5">
                        
                        <a className="cursor-pointer text-purple-500 inline-flex align-items-center justify-content-center hover:surface-100 transition-colors transition-duration-150 w-3rem h-3rem border-circle">
                            <i className="pi pi-user text-2xl"></i>
                        </a>
                       
                        <a onClick={cerrarSesion} className="cursor-pointer text-color-secondary inline-flex align-items-center justify-content-center hover:surface-100 transition-colors transition-duration-150 w-3rem h-3rem border-circle">
                            <i className="pi pi-sign-out text-2xl"></i>
                        </a>
                        
                    </div>
                </div>
            </div>
            {/*<ScrollPanel  className="h-screen w-full p-0 m-0 custombar1 ">*/}
            <div className="h-screen w-screen flex flex-column relative flex-auto surface-ground">
                <div className="surface-card flex flex-column flex-auto h-full w-screen h-screen">
                    <div className="flex justify-content-between align-items-center relative lg:static column-gap-5 shadow-2 mb-2 border-round-bottom-lg" style={{ height: 'calc(100vh - 90vh)' }}>
                        <div className="flex align-items-center mt-1 ml-3">
                            <StyleClass nodeRef={toggleBtnRefSidebar} selector="#app-sidebar-13" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick="true" >
                                <Button id='botoncio' ref={toggleBtnRefSidebar} className="botoncito p-link cursor-pointer  inline-flex align-items-center justify-content-center text-700 mr-3" >
                                    <i className="pi pi-bars text-4xl"></i>
                                </Button>
                            </StyleClass>
                            {
                                userToken.loadingToken ? '' : <div className="font-medium">Bienvenido, <span className="font-bold">{userToken.userToken.data.tokenable.name}</span> 👋</div>
                            }
                            
                        </div>
                        <div className='mt-1'>
                            <StyleClass nodeRef={toggleBtnRef} selector="@next" enterClassName="hidden" enterActiveClassName="fadein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick="true" >
                                <a ref={toggleBtnRef} className="cursor-pointer block lg:hidden text-700 mr-3" >
                                    <i className="pi pi-ellipsis-v text-2xl"></i>
                                </a>
                            </StyleClass>
                            <ul className="list-none p-3 lg:p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row lg:border-round-none border-round-xl
                                        surface-overlay border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                                <li>
                                    <span className="p-input-icon-left lg:mr-3 mb-3 lg:mb-0">
                                        <i className="pi pi-search"></i>
                                        <InputText type="text" className="border-round-3xl w-8rem sm:w-full" placeholder="Buscar..." />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className=" w-full h-full surface-border  flex-auto border-round-xl flex-auto  overflow-scroll " style={{ height: 'calc(100vh - 10vh)' }}>
                        
                        <Outlet className='w-full'></Outlet>

                    </div>
                </div>
            </div>
            {/*</ScrollPanel>*/}
            
        </div>
        
    </>
  )
}

export default DashAdmin