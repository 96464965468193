/* eslint-disable no-unused-vars */
import { useEffect, useContext, useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { UserContext } from '../../../../context/UserProvider';
import { dataConsultaAccion } from '../../../../Redux/patos/FormDuck';

import axios from 'axios'
import InfoUsuario from './InfoUsuario';
import SelectInfoUsuario from './SelectInfoUsuario';


const OpcionInfoPage = () => {

    const navegate = useNavigate();
    //const [cargando, setCargando] = useState(true) ;
    const dispatch = useDispatch();

    const {userLoading, userBer, userFire, signOutUser } = useContext(UserContext);
    const {arrayDC, loadingDC} = useSelector(store => store.storeDataConsulta)

    useEffect(() => {

        const VerificarTokenN = () =>{
            let token = ''
              try {
                token = atob(localStorage.getItem('_vita'));
                //console.log(token)
                return token;
              } catch (error) {
                  localStorage.removeItem('_vita');
                  localStorage.removeItem('_dolce');
                  navegate('/login');
                  signOutUser()
                return 401;
              }
            
        }

        if (userLoading) {
            
        }else{
            if (userBer && userFire) {
              const id = userBer.id;
                const generarData = () =>{

                    dispatch(dataConsultaAccion(id))
                }
                generarData()
            }else{
                navegate('/')
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, userLoading, userBer, userFire])
  
  return (
    loadingDC ? (
      <p>Cargando...</p>
    ):(
      arrayDC.length > 0 ? (
        <SelectInfoUsuario datosPersonalesA={arrayDC} loadingDP={loadingDC} ></SelectInfoUsuario>
      ):(
        <InfoUsuario></InfoUsuario>
      )
    )
  )
}

export default OpcionInfoPage