/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import axios from "axios";



//CONSTANTES

const dataInicialDespacho1 = {
    arrayDes1 : [],
    loadingDes1 : true,
    errorDes1: ''
};

const dataInicialDespacho2 = {
    arrayDes2 : [],
    loadingDes2 : true,
    errorDes: ''
};

const dataInicialDespacho3 = {
    arrayDes3 : [],
    loadingDes3 : true,
    errorDes3: ''
};

const dataInicialDD = {
    arrayDD : [],
    loadingDD : true,
    errorDD: ''
};




//TYPES

const OBTENER_DESPACHOINDEX1_EXITO = 'OBTENER_DESPACHOINDEX1_EXITO'
const OBTENER_DESPACHOINDEX1_REQUEST = 'OBTENER_DESPACHOINDEX1_REQUEST'
const OBTENER_DESPACHOINDEX1_ERROR = 'OBTENER_DESPACHOINDEX1_ERROR'

const OBTENER_DESPACHOINDEX2_EXITO = 'OBTENER_DESPACHOINDEX2_EXITO'
const OBTENER_DESPACHOINDEX2_REQUEST = 'OBTENER_DESPACHOINDEX2_REQUEST'
const OBTENER_DESPACHOINDEX2_ERROR = 'OBTENER_DESPACHOINDEX2_ERROR'

const OBTENER_DESPACHOINDEX3_EXITO = 'OBTENER_DESPACHOINDEX3_EXITO'
const OBTENER_DESPACHOINDEX3_REQUEST = 'OBTENER_DESPACHOINDEX3_REQUEST'
const OBTENER_DESPACHOINDEX3_ERROR = 'OBTENER_DESPACHOINDEX3_ERROR'

const OBTENER_DESPACHODD_EXITO = 'OBTENER_DESPACHODD_EXITO'
const OBTENER_DESPACHODD_REQUEST = 'OBTENER_DESPACHODD_REQUEST'
const OBTENER_DESPACHODD_ERROR = 'OBTENER_DESPACHODD_ERROR'




//REDUCERS

export function despachoIndexReducer1 (state = dataInicialDespacho1, action){
    switch (action.type) {
        case OBTENER_DESPACHOINDEX1_REQUEST:
            return { loadingDes1: true, arrayDes1:[] };
        case OBTENER_DESPACHOINDEX1_EXITO:
            return {...state, arrayDes1: action.payload, loadingDes1: false}; 
        case OBTENER_DESPACHOINDEX1_ERROR:
            return {errorDes1: action.payload, loadingDes1: false};       
        default:
            return state
    }
}

export function despachoIndexReducer2 (state = dataInicialDespacho2, action){
    switch (action.type) {
        case OBTENER_DESPACHOINDEX2_REQUEST:
            return { loadingDes2: true, arrayDes2:[] };
        case OBTENER_DESPACHOINDEX2_EXITO:
            return {...state, arrayDes2: action.payload, loadingDes2: false}; 
        case OBTENER_DESPACHOINDEX2_ERROR:
            return {errorDes3: action.payload, loadingDes2: false};       
        default:
            return state
    }
}

export function despachoIndexReducer3 (state = dataInicialDespacho3, action){
    switch (action.type) {
        case OBTENER_DESPACHOINDEX3_REQUEST:
            return { loadingDes3: true, arrayDes3:[] };
        case OBTENER_DESPACHOINDEX3_EXITO:
            return {...state, arrayDes3: action.payload, loadingDes3: false}; 
        case OBTENER_DESPACHOINDEX3_ERROR:
            return {errorDes3: action.payload, loadingDes3: false};       
        default:
            return state
    }
}

export function despachoDDIndexReducer (state = dataInicialDD, action){
    switch (action.type) {
        case OBTENER_DESPACHODD_REQUEST:
            return { loadingDD: true, arrayDD:[] };
        case OBTENER_DESPACHODD_EXITO:
            return {...state, arrayDD: action.payload, loadingDD: false}; 
        case OBTENER_DESPACHODD_ERROR:
            return {errorDD: action.payload, loadingDD: false};       
        default:
            return state
    }
}


//ACCIONES

export const despachoIndexAccion1 = (token, id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_DESPACHOINDEX1_REQUEST})

        /*const tok = 'Bearer ' + token;
        //console.log(tok)
        const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carrito/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/


        const res = await axios(process.env.REACT_APP_UNSPLASH_URL+'/despachobersati1')
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_DESPACHOINDEX1_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}

export const despachoIndexAccion2 = (token, id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_DESPACHOINDEX2_REQUEST})

        /*const tok = 'Bearer ' + token;
        //console.log(tok)
        const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carrito/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/


        const res = await axios(process.env.REACT_APP_UNSPLASH_URL+'/despachobersati2')
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_DESPACHOINDEX2_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}

export const despachoIndexAccion3 = (token, id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_DESPACHOINDEX3_REQUEST})

        /*const tok = 'Bearer ' + token;
        //console.log(tok)
        const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carrito/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/


        const res = await axios(process.env.REACT_APP_UNSPLASH_URL+'/despachobersati3')
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_DESPACHOINDEX3_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}

export const despachoIndexDDAccion = (id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_DESPACHODD_REQUEST})

        /*const tok = 'Bearer ' + token;
        //console.log(tok)
        const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carrito/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //console.log(id)
        const res = await axios(process.env.REACT_APP_UNSPLASH_URL+'/despachobersatidd/'+id)
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_DESPACHODD_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}