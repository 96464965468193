/* eslint-disable no-unused-vars */
import { Route, Routes } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux';

import PrincipalVisitors from './componentes/visitors/PrincipalVisitors'
import InicioVisitors from './componentes/visitors/pages/InicioVisitors'
import TiendaVisitors from './componentes/visitors/pages/tienda/TiendaVisitors';
import StoreIndex from './componentes/visitors/pages/tienda/tiendaPages/StoreIndex'
import StoreFamiliasP from './componentes/visitors/pages/tienda/tiendaPages/StoreFamiliasP';
import StoreCategoriasP from './componentes/visitors/pages/tienda/tiendaPages/StoreCategoriasP';
import StoreLineasP from './componentes/visitors/pages/tienda/tiendaPages/StoreLineasP';
import StoreGP from './componentes/visitors/pages/tienda/tiendaPages/StoreGP';


import PrimeReact from 'primereact/api';

import generateStore from './Redux/store'



import PrincipalAdmin from './componentes/admin/PrincipalAdmin';
import LoginAdmin from './componentes/admin/pages/LoginAdmin';
import DashAdmin from './componentes/admin/DashAdmin';
import InicioAdmin from './componentes/admin/pages/InicioAdmin';
import Categorias from './componentes/admin/pages/CRUD/Categorias';


import './App.css';
import Familias from './componentes/admin/pages/CRUD/Familias';
import Lineas from './componentes/admin/pages/CRUD/Lineas';
import GP from './componentes/admin/pages/CRUD/GP';
import ProductoDetalle from './componentes/visitors/pages/tienda/tiendaPages/ProductoDetalle';
import NuevoProducto from './componentes/admin/pages/CRUD/Productos/NuevoProducto';
import EstadoProducto from './componentes/admin/pages/CRUD/Productos/EstadoProducto';
import ListaProducto from './componentes/admin/pages/CRUD/Productos/ListaProducto';
import EditProductoText from './componentes/admin/pages/CRUD/Productos/EditProductoText';
import EditImgProducto from './componentes/admin/pages/CRUD/Productos/EditImgProducto';
import GetProducto from './componentes/admin/pages/CRUD/System/GetProducto';
import EditPrecioProducto from './componentes/admin/pages/CRUD/Productos/EditPrecioProducto';
import EditCategoriaProducto from './componentes/admin/pages/CRUD/Productos/EditCategoriaProducto';
import NoBersati from './componentes/NoBersati';
import RequireAuth from './componentes/RequireAuth';
import Carousel from './componentes/admin/pages/CRUD/Ecommerce/Carousel';
import EleccionEditor from './componentes/admin/pages/CRUD/Ecommerce/EleccionEditor';
import EjemploComponent from './componentes/admin/pages/CRUD/Ecommerce/ejemploComponent';
import LoginVisitors from './componentes/visitors/pages/LoginVisitors';
import RegisterVisitors from './componentes/visitors/pages/RegisterVisitors';
import CarritoPage from './componentes/visitors/pages/carrito/CarritoPage';
import InfoUsuario from './componentes/visitors/pages/carrito/InfoUsuario';
import PromocionBersati from './componentes/admin/pages/CRUD/Ecommerce/Promociones/PromocionBersati';
import EnvioDomicilio from './componentes/visitors/pages/carrito/opcionesEnvio/EnvioDomicilio';
import OpcionInfoPage from './componentes/visitors/pages/carrito/OpcionInfoPage';
import PaymentPage from './componentes/visitors/pages/carrito/PaymentPage';
import ConfirmationPage from './componentes/visitors/pages/carrito/ConfirmationPage';
import EditArmadoProducto from './componentes/admin/pages/CRUD/Productos/EditArmadoProducto';
import MisCompras from './componentes/visitors/pages/carrito/MisCompras';
import VentasBersati from './componentes/admin/pages/CRUD/Ventas/VentasBersati';
import EntregaPedidosBersati from './componentes/admin/pages/CRUD/Ventas/EntregaPedidosBersati';
import EntregaDetalleBersati from './componentes/admin/pages/CRUD/Ventas/EntregaDetalleBersati';
function App() {

  PrimeReact.ripple = true;

  const store = generateStore();
  const scale =  12;
  document.documentElement.style.fontSize = scale + 'px';


  

  return (
    <>
      <Provider store={store} >  
        <Routes>
            <Route path="/" element={ <PrincipalVisitors /> }>
              <Route index exact element={<InicioVisitors />} />
              <Route path="/tienda" exact element={<TiendaVisitors />}>
                <Route index exact element={<StoreIndex  />}/>
                <Route path="/tienda/:cate" exact element={<StoreCategoriasP  />}/>
                <Route path="/tienda/:cate/:fam" exact element={<StoreFamiliasP  />}/>
                <Route path='/tienda/:cate/:fam/:lin' element={<StoreLineasP />}/>
                <Route path='/tienda/:cate/:fam/:lin/:gp' element={<StoreGP />}/>
                <Route path='/tienda/productodetalle/:id' element={<ProductoDetalle />}/>
                
                {/*<Route index exact element={<AllCategories />}/>
                <Route path='/tienda/:cate' element={<AllCategories  />}/>
                <Route path='/tienda/:cate/:fam' element={<AllCategories />}/>
                <Route path='/tienda/:cate/:fam/:lin' element={<AllCategories />}/>
                <Route path='/tienda/:cate/:fam/:lin/:gp' element={<AllCategories />}/>*/}
              </Route>
              <Route path="/login" element={<LoginVisitors />} />
              <Route path="/register" element={<RegisterVisitors />} />
              <Route path="/paginacarrito" element={<CarritoPage />} />
              <Route path="/checkoutform" element={<OpcionInfoPage/>} />
              <Route path="/payment" element={<PaymentPage/>} />
              <Route path="/confirmation/:ord" element={<ConfirmationPage/>} />
              <Route path="/miscompras" element={<MisCompras/>} />
              {/*<Route path="/prueba" element={<EnvioDomicilio />} />*/}

              <Route path="*" element={<NoBersati />} /> 
            </Route>
            <Route 
              path="/protected/*"
              element={
                <RequireAuth>
                  <Routes>  
                    <Route path="admin" exact element={<DashAdmin  />}>
                      <Route index exact element={<InicioAdmin  />} />
                      <Route path='/admin/categorias' exact element={<Categorias  />} />
                      <Route path='/admin/familias' exact element={<Familias  />} />
                      <Route path='/admin/lineas' exact element={<Lineas  />} />
                      <Route path='/admin/gp' exact element={<GP  />} />


                      <Route path='/admin/nuevoproducto' exact element={<NuevoProducto  />} />
                      <Route path='/admin/listaproducto' exact element={<ListaProducto  />} />
                      <Route path='/admin/estadoproducto' exact element={<EstadoProducto  />} />
                      <Route path='/admin/precioproducto' exact element={<EditPrecioProducto/>} />
                      <Route path='/admin/editarcaracteristicasproducto' exact element={<EditProductoText  />} />
                      <Route path='/admin/editartimgproducto' exact element={<EditImgProducto  />} /> 
                      <Route path='/admin/editarcategoriaproducto' exact element={<EditCategoriaProducto  />} /> 
                      <Route path='/admin/editararmadoproducto' exact element={<EditArmadoProducto  />} />
                      
                      <Route path='/admin/carousel' exact element={<Carousel  />} />
                      <Route path='/admin/eleccioneditor' exact element={<EleccionEditor  />} />
                      
                      <Route path='/admin/promociones' exact element={<PromocionBersati  />} />

                      <Route path='/admin/ventaconfirmar' exact element={<VentasBersati  />} />
                      <Route path='/admin/despachosbersati' exact element={<EntregaPedidosBersati  />} />
                      <Route path='/admin/despachosbersatidetalle/:id' exact element={<EntregaDetalleBersati  />} />
                      
                      <Route path='/admin/system/productos' exact element={<GetProducto  />} />
                    </Route>
                    <Route path="*" element={<NoBersati />} />
                  </Routes>
              </RequireAuth>
              }
            />
            <Route path="/admin/login" exact element={< LoginAdmin />} />
        </Routes>
      </Provider>
    </>
  );
}

export default App;


/*
<Route path="/admin/dashboard" exact element={<DashAdmin  />}>
                      <Route index exact element={<InicioAdmin  />} />
                      <Route path='/admin/dashboard/categorias' exact element={<Categorias  />} />
                      <Route path='/admin/dashboard/familias' exact element={<Familias  />} />
                      <Route path='/admin/dashboard/lineas' exact element={<Lineas  />} />
                      <Route path='/admin/dashboard/gp' exact element={<GP  />} />


                      <Route path='/admin/dashboard/nuevoproducto' exact element={<NuevoProducto  />} />
                      <Route path='/admin/dashboard/listaproducto' exact element={<ListaProducto  />} />
                      <Route path='/admin/dashboard/estadoproducto' exact element={<EstadoProducto  />} />
                      <Route path='/admin/dashboard/precioproducto' exact element={<EditPrecioProducto/>} />
                      <Route path='/admin/dashboard/editarcaracteristicasproducto' exact element={<EditProductoText  />} />
                      <Route path='/admin/dashboard/editartimgproducto' exact element={<EditImgProducto  />} />
                      <Route path='/admin/dashboard/editarcategoriaproducto' exact element={<EditCategoriaProducto  />} />


                      <Route path='/admin/dashboard/system/productos' exact element={<GetProducto  />} />
                    </Route>
                    */