/* eslint-disable default-case */
/* eslint-disable no-unused-vars */


import axios from "axios";


//CONSTANTES

const dataInicialID = {
    arrayID : [],
    loadingID : '',
};

const dataInicialIDP = {
    arrayIDP : [],
    loadingIDP: '',
};




//TYPES

const OBTENER_ITEMDETAILS_EXITO = 'OBTENER_ITEMDETAILS_EXITO'
const OBTENER_ITEMDETAILS_REQUEST = 'OBTENER_ITEMDETAILS_REQUEST'
const OBTENER_ITEMDETAILS_ERROR = 'OBTENER_ITEMDETAILS_ERROR'

const OBTENER_ITEMDETAILSPROD_EXITO = 'OBTENER_ITEMDETAILSPROD_EXITO'
const OBTENER_ITEMDETAILSPROD_REQUEST = 'OBTENER_ITEMDETAILSPROD_REQUEST'
const OBTENER_ITEMDETAILSPROD_ERROR = 'OBTENER_ITEMDETAILSPROD_ERROR'



//REDUCERS

export function carouselReducer (state = dataInicialID, action){
    switch (action.type) {
        case OBTENER_ITEMDETAILS_REQUEST:
            return { loadingID: true, arrayID:[] };
        case OBTENER_ITEMDETAILS_EXITO:
            return {...state, arrayID: action.payload, loadingID: false};       
        default:
            return state
    }
}

export function itemDetailsProdReducer (state = dataInicialIDP, action){
    switch (action.type) {
        case OBTENER_ITEMDETAILSPROD_REQUEST:
            return { loadingIDP: true, arrayIDP:[] };
        case OBTENER_ITEMDETAILSPROD_EXITO:
            return {...state, arrayIDP: action.payload, loadingIDP: false};       
        default:
            return state
    }
}



//ACCIONES

export const carouselIndexEcoAccion = () => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_ITEMDETAILS_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/eco/carouselindexeco')
        //console.log(res)
        dispatch({
            type: OBTENER_ITEMDETAILS_EXITO,
            payload: res.data
        })
        
    } catch (error) {
        console.log(error)
    }
}

export const itemDetailsProdAccion = (id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_ITEMDETAILSPROD_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/eco/itemdetailsindex/'+id)
        //console.log(res)
        dispatch({
            type: OBTENER_ITEMDETAILSPROD_EXITO,
            payload: res
        })
        
    } catch (error) {
        console.log(error)
    }
}