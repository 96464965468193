/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import { Button } from 'primereact/button';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'

import { despachoIndexDDAccion } from '../../../../../Redux/patos/DespachosDuck';

const EntregaDetalleBersati = () => {

    const dispatch = useDispatch();
    const toast = useRef(null);
    const navegate = useNavigate();


    const { id } = useParams();


    const {arrayDD, loadingDD} = useSelector(store => store.storeDespachoDD)

    useEffect(() => {

        dispatch(despachoIndexDDAccion(id))        
        
    },[dispatch, id])

    const fechaDes = (data) =>{
        const opciones = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        let fechita = new Date(data);
        return(
            <React.Fragment>
                {fechita.toLocaleDateString('es-Es', opciones)}
            </React.Fragment>
        )
    }

    const tipoPago = (data) =>{
        if (data === 101) {
            return (
                <React.Fragment>
                    <span className="text-900 mb-1">Pago ContraEntrega</span>
                </React.Fragment>
            )
        }
        if (data === 102) {
            return (
                <React.Fragment>
                    <span className="text-900 mb-1">Pago por QR</span>
                </React.Fragment>
            )
        }
        if (data === 103) {
            return (
                <React.Fragment>
                    <span className="text-900 mb-1">Pago a la Hora de Recoger (Retiro en Local)</span>
                </React.Fragment>
            )
        }
    }

    const pagoConfir = (data) =>{
        if (data === 1) {
            return(
                <React.Fragment>
                    {'Si'}
                </React.Fragment>
            )
        }
        if (data === 0) {
            return(
                <React.Fragment>
                    {'No'}
                </React.Fragment>
            )
        }     
    }

    const options = {
        center: {lat: -17.78387457963673, lng: -63.18185806274414},
        zoom: 12
    };
    const position = [51.505, -0.09]

    //console.log(arrayDD)

    return (
        <>
            <div className='w-full overflow-scroll speeddial-linear-demo '>
                <div className="surface-section px-2 py-5 md:px-3 lg:px-5">

                    <div className="flex justify-content-between align-items-center border-bottom-1 surface-border">
                        <div>
                            <span className="block text-2xl md:text-3xl font-medium text-900 mb-4"><span className='font-bold'>Detalle Pedido Nro.</span> {id}</span>
                        </div>
                        <div>
                            <span className="block text-2xl md:text-3xl font-medium text-900 mb-4 text-xl cursor-pointer" onClick={() => navegate(-1)} >Volver</span>
                        </div>
                    </div>
                    
                    {
                        loadingDD ? (
                            <p>Cargando...</p>
                        )
                        :
                        (

                            arrayDD[0].tipo_envio === 1 ? (
                                arrayDD.length > 0 ? (
                                    <div className='grid'>
                                        <div className='col-12 md:col-6 xl:col-6 p-3'>
                                            <div className="surface-card p-4 shadow-2 border-round">
                                                <div className="font-medium text-3xl text-900 mb-3">Datos Personales</div>
                                                <ul className="list-none p-0 m-0">
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Nombres</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].nombres}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Apellidos</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].apellido_p} {arrayDD[0].apellido_m}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Celular</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].celular}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Correo</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].correo}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Carnet de Identidad</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].ci}</div>
                                                    </li>
                                                </ul>
                                            </div>
        
                                            <div className="surface-card p-4 shadow-2 border-round mt-2">
                                                <div className="font-medium text-3xl text-900 mb-3">Información del Pedido</div>
                                                <ul className="list-none p-0 m-0">
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Fecha del Pedido</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{fechaDes(arrayDD[0].created_at)}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Tipo de Pago</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{tipoPago(arrayDD[0].tipo_pago)}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Tipo de Envio</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].tipo_envioTms}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Pago Confirmado</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{pagoConfir(arrayDD[0].pago_confirmado)}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Total Envio</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">BOB. {arrayDD[0].total_envio}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Total</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">BOB. {arrayDD[0].total_total}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-12 md:col-6 xl:col-6 p-3'>
                                            <div className="surface-card shadow-2 border-round p-4">
                                                <div className="flex justify-content-between align-items-center mb-5">
                                                    <span className="text-xl text-900 font-medium">Productos</span>
                                                    <div>
                                                        <span className="text-xl text-900 font-medium">Cantidad: {arrayDD[0].cantidad_prod} item(s)</span>
                                                    </div>
                                                </div>
        
                                                <ul className="list-none p-0 m-0">
                                                    {
                                                        arrayDD[0].productos_venta_bersati.length > 0 ? (
                                                            arrayDD[0].productos_venta_bersati.map((prod, index) => (
                                                                <li key={index} className="flex flex-column sm:flex-row sm:justify-content-between sm:align-items-center mb-5">
                                                                    <div className="flex align-items-center">
                                                                        <span className="block mr-3 surface-200 border-radius: 10px inline-flex justify-content-center align-items-center" style={{width:"58px", height: "58px", borderRadius: "10px"}}>
                                                                            <img src={`${prod.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} style={{width: "58px", height: "58px", borderRadius: "10px"}}   alt={prod.nombre} />
                                                                        </span>
                                                                        <div>
                                                                            <div className="text-900 font-medium text-xl mb-2">{prod.nombre} - {prod.codigo}</div>
                                                                            <div className="flex align-items-center">
                                                                                <span className="text-600">{prod.marca}</span>
                                                                                <span className="inline-flex p-1 bg-green-100 text-green-600 font-medium text-sm border-round ml-3">BOB. {prod.precio_vendido}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="sm:text-right mt-3 md:mt-0">
                                                                        <div className="text-sm text-600 mt-2">{prod.cantidad_prod} producto(s)</div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        ):null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
        
                                        <div className='col-12 md:col-12 xl:col-12 p-3'>
                                            <div className="surface-card shadow-2 border-round p-4">
                                                <div className="flex justify-content-between align-items-center mb-5">
                                                    <span className="text-xl text-900 font-medium">Ubicación</span>
                                                    <div>
                                                        <a className="text-xl text-900 font-medium" target="_blank" href={'http://maps.google.com/maps?z=12&t=m&q=loc:'+arrayDD[0].latitud+'+'+arrayDD[0].longitud} rel="noreferrer">Ver en MAPS</a>
                                                    </div>
                                                </div>
                                                <MapContainer center={[arrayDD[0].latitud, arrayDD[0].longitud]} maxZoom={18} zoom={options.zoom} scrollWheelZoom={true} style={{width: '100%', minHeight: '420px'}} className='p-invalid' >
                                                        <TileLayer
                                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        />
                                                        <Marker position={[arrayDD[0].latitud, arrayDD[0].longitud]}>
                                                            <Popup>
                                                                {arrayDD[0].direccion}
                                                            </Popup>
                                                        </Marker>
                                                </MapContainer>
                                            </div>
                                            <div className='surface-card shadow-2 border-round p-3 mt-1'>
                                                <div className='flex justify-content-end'>
                                                    <Button label='Confirmar Entrega' className="p-button-raised p-button-success p-button-lg"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ):null
                            ):(
                                arrayDD[0].tipo_envio === 2 ? (
                                    <div className='grid'>
                                        <div className='col-12 md:col-6 xl:col-6 p-3'>
                                            <div className="surface-card p-4 shadow-2 border-round">
                                                <div className="font-medium text-3xl text-900 mb-3">Datos Personales</div>
                                                <ul className="list-none p-0 m-0">
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Nombres</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].nombres}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Apellidos</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].apellido_p} {arrayDD[0].apellido_m}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Celular</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].celular}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Correo</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].correo}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Carnet de Identidad</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].ci}</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="surface-card p-4 shadow-2 border-round mt-2">
                                                <div className="font-medium text-3xl text-900 mb-3">Información del Pedido</div>
                                                <ul className="list-none p-0 m-0">
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Fecha del Pedido</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{fechaDes(arrayDD[0].created_at)}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Tipo de Pago</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{tipoPago(arrayDD[0].tipo_pago)}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Tipo de Envio</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].tipo_envioTms}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Pago Confirmado</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{pagoConfir(arrayDD[0].pago_confirmado)}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Total Envio</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">BOB. {arrayDD[0].total_envio}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Total</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">BOB. {arrayDD[0].total_total}</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="surface-card p-4 shadow-2 border-round mt-2">
                                                <div className="font-medium text-3xl text-900 mb-3">Información del Envío</div>
                                                <ul className="list-none p-0 m-0">
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Departamento</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].departamentoText}</div>
                                                    </li>
                                                    <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                        <div className="text-500 w-6 md:w-2 font-medium">Ciudad Detalle</div>
                                                        <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].ciudad}</div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className='col-12 md:col-6 xl:col-6 p-3'>
                                            <div className="surface-card shadow-2 border-round p-4">
                                                <div className="flex justify-content-between align-items-center mb-5">
                                                    <span className="text-xl text-900 font-medium">Productos</span>
                                                    <div>
                                                        <span className="text-xl text-900 font-medium">Cantidad: {arrayDD[0].cantidad_prod} item(s)</span>
                                                    </div>
                                                </div>
        
                                                <ul className="list-none p-0 m-0">
                                                    {
                                                        arrayDD[0].productos_venta_bersati.length > 0 ? (
                                                            arrayDD[0].productos_venta_bersati.map((prod, index) => (
                                                                <li key={index} className="flex flex-column sm:flex-row sm:justify-content-between sm:align-items-center mb-5">
                                                                    <div className="flex align-items-center">
                                                                        <span className="block mr-3 surface-200 border-radius: 10px inline-flex justify-content-center align-items-center" style={{width:"58px", height: "58px", borderRadius: "10px"}}>
                                                                            <img src={`${prod.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} style={{width: "58px", height: "58px", borderRadius: "10px"}}   alt={prod.nombre} />
                                                                        </span>
                                                                        <div>
                                                                            <div className="text-900 font-medium text-xl mb-2">{prod.nombre} - {prod.codigo}</div>
                                                                            <div className="flex align-items-center">
                                                                                <span className="text-600">{prod.marca}</span>
                                                                                <span className="inline-flex p-1 bg-green-100 text-green-600 font-medium text-sm border-round ml-3">BOB. {prod.precio_vendido}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="sm:text-right mt-3 md:mt-0">
                                                                        <div className="text-sm text-600 mt-2">{prod.cantidad_prod} producto(s)</div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        ):null
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='col-12 md:col-12 xl:col-12 p-3'>
                                            <div className='surface-card shadow-2 border-round p-3 mt-1'>
                                                <div className='flex justify-content-end'>
                                                    <Button label='Confirmar Despacho' className="p-button-raised p-button-success p-button-lg"/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ):(
                                    arrayDD[0].tipo_envio === 3 ? (
                                        <div className='grid'>

                                            <div className='col-12 md:col-6 xl:col-6 p-3'>

                                                <div className="surface-card p-4 shadow-2 border-round">
                                                    <div className="font-medium text-3xl text-900 mb-3">Datos Personales</div>
                                                    <ul className="list-none p-0 m-0">
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Nombres</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].nombres}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Apellidos</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].apellido_p} {arrayDD[0].apellido_m}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Celular</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].celular}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Correo</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].correo}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Carnet de Identidad</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].ci}</div>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="surface-card p-4 shadow-2 border-round mt-2">
                                                    <div className="font-medium text-3xl text-900 mb-3">Información del Pedido</div>
                                                    <ul className="list-none p-0 m-0">
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Fecha del Pedido</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{fechaDes(arrayDD[0].created_at)}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Tipo de Pago</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{tipoPago(arrayDD[0].tipo_pago)}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Tipo de Envio</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{arrayDD[0].tipo_envioTms}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Pago Confirmado</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{pagoConfir(arrayDD[0].pago_confirmado)}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Total Envio</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">BOB. {arrayDD[0].total_envio}</div>
                                                        </li>
                                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                            <div className="text-500 w-6 md:w-2 font-medium">Total</div>
                                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">BOB. {arrayDD[0].total_total}</div>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>

                                            <div className='col-12 md:col-6 xl:col-6 p-3'>
                                                <div className="surface-card shadow-2 border-round p-4">
                                                    <div className="flex justify-content-between align-items-center mb-5">
                                                        <span className="text-xl text-900 font-medium">Productos</span>
                                                        <div>
                                                            <span className="text-xl text-900 font-medium">Cantidad: {arrayDD[0].cantidad_prod} item(s)</span>
                                                        </div>
                                                    </div>
        
                                                    <ul className="list-none p-0 m-0">
                                                        {
                                                            arrayDD[0].productos_venta_bersati.length > 0 ? (
                                                                arrayDD[0].productos_venta_bersati.map((prod, index) => (
                                                                    <li key={index} className="flex flex-column sm:flex-row sm:justify-content-between sm:align-items-center mb-5">
                                                                        <div className="flex align-items-center">
                                                                            <span className="block mr-3 surface-200 border-radius: 10px inline-flex justify-content-center align-items-center" style={{width:"58px", height: "58px", borderRadius: "10px"}}>
                                                                                <img src={`${prod.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} style={{width: "58px", height: "58px", borderRadius: "10px"}}   alt={prod.nombre} />
                                                                            </span>
                                                                            <div>
                                                                                <div className="text-900 font-medium text-xl mb-2">{prod.nombre} - {prod.codigo}</div>
                                                                                <div className="flex align-items-center">
                                                                                    <span className="text-600">{prod.marca}</span>
                                                                                    <span className="inline-flex p-1 bg-green-100 text-green-600 font-medium text-sm border-round ml-3">BOB. {prod.precio_vendido}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="sm:text-right mt-3 md:mt-0">
                                                                            <div className="text-sm text-600 mt-2">{prod.cantidad_prod} producto(s)</div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            ):null
                                                        }
                                                    </ul>

                                                </div>
                                            </div>

                                            <div className='col-12 md:col-12 xl:col-12 p-3'>
                                                <div className='surface-card shadow-2 border-round p-3 mt-1'>
                                                    <div className='flex justify-content-end'>
                                                        <Button label='Confirmar Entrega' className="p-button-raised p-button-success p-button-lg"/>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ):null
                                )
                            )
                        )
                    }

                </div>
            </div>
        </>
    )
}

export default EntregaDetalleBersati